import React, {useEffect} from "react";
import {Button} from "@material-ui/core";
import {Link, useNavigate} from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Arrow from "../img/ArrowRight.svg";
import kyco from "../img/kyc_img_img.svg";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import useState from "react-usestateref";
import {env} from "../core/service/envconfig";
function Home() {
  const options = ["one", "two", "three"];

  const initialFormValue = {
    address_proof: "",
    id_proof: "",
    photo_proof: "",
    pan_number: "",
    AccountHolderName: "",
    AccountNumber: "",
    IFSCCode: "",
    BankName: "",
    BranchName: "",
    BranchAddress: "",
    gpay_number: "",
    paytm_number: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [profileDatas, setprofileData] = useState("");
  const [kycDatas, setkycData] = useState("");
  const [proof1_status, setproof1_status] = useState(0);
  const [proof2_status, setproof2_status] = useState(0);
  const [proof3_status, setproof3_status] = useState(0);
  const [addressProof, setaddressProof, addressProofref] = useState(false);
  const [idProof, setidProof, idProofref] = useState(false);
  const [photoProof, setphotoProof, photoProofref] = useState(false);
  const [panNumber, setpanNumber, panNumberref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [address_doc, setaddress_doc] = useState("");
  const [id_doc, setid_doc] = useState("");
  const [photo_doc, setphoto_doc] = useState("");
  const [pan_no, setpan_no] = useState("");
  const [kycstatus, setkycstatus] = useState(0);
  const [upload_view, setupload_view] = useState(true);
  const [detail_view, setdetail_view] = useState(false);
  const [fileloading, setfileloading] = useState(false);
  const [loading, setloading] = useState(false);
  const [
    AccountHolderNameErr,
    SetAccountHolderNameErr,
    AccountHolderNameErrref,
  ] = useState(false);
  const [AccountNumberErr, SetAccountNumberErr, AccountNumberErrref] = useState(
    false
  );
  const [IFSCCodeErr, SetIFSCCodeErr, IFSCCodeErrref] = useState(false);
  const [BankNameErr, SetBankNameErr, BankNameErrref] = useState(false);
  const [BranchNameErr, SetBranchNameErr, BranchNameErrref] = useState(false);
  const [BranchAddressErr, SetBranchAddressErr, BranchAddressErrref] = useState(
    false
  );

  const [gpayNumber, setgpayNumber, gpayNumberref] = useState(false);
  const [paytmNumber, setpaytmNumber, paytmNumberref] = useState(false);
  const [load1, setload1] = useState(false);
  const [load2, setload2] = useState(false);
  const [load3, setload3] = useState(false);

  const {
    address_proof,
    id_proof,
    photo_proof,
    pan_number,
    AccountHolderName,
    AccountNumber,
    IFSCCode,
    BankName,
    BranchName,
    BranchAddress,
    gpay_number,
    paytm_number,
  } = formValue;
  const navigate = useNavigate();
  const [sideLoader,setSiteLoader]=useState(true);

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getKyc();
    
  }, [0]);

  const getKyc = async () => {
    try {
      setSiteLoader(true)
      var data = {
        apiUrl: apiService.getKYC,
      };
      var resp = await getMethod(data);
      // console.log("get kyc resp===", resp);
      // console.log(
      //   "Object.keys(resp.datas).length",
      //   Object.keys(resp.datas).length
      // );
      setSiteLoader(false)
      if (resp.status) {
        if (Object.keys(resp.datas.kycDetails).length > 0) {
          setkycData(resp.datas.kycDetails);
          setkycstatus(resp.datas.kycDetails.kycStatus);
          setaddress_doc(resp.datas.kycDetails.proof1);
          setid_doc(resp.datas.kycDetails.proof2);
          setphoto_doc(resp.datas.kycDetails.proof3);
          formValue["pan_number"] = resp.datas.kycDetails.pan;
          formValue["AccountHolderName"] =
            resp.datas.kycDetails.accountHolderName;
          formValue[
            "AccountNumber"
          ] = resp.datas.kycDetails.accountNumber.toString();
          formValue["IFSCCode"] = resp.datas.kycDetails.iFSCCode;
          formValue["BankName"] = resp.datas.kycDetails.bankName;
          formValue["BranchName"] = resp.datas.kycDetails.branchName;
          formValue["BranchAddress"] = resp.datas.kycDetails.branchAddress;
          setproof1_status(resp.datas.kycDetails.prrof1status);
          setproof2_status(resp.datas.kycDetails.prrof2status);
          setproof3_status(resp.datas.kycDetails.prrof3status);
          formValue["gpay_number"] = resp.datas.kycDetails.gpay;
          formValue["paytm_number"] = resp.datas.kycDetails.paytm;
        }
      }
    } catch (error) {}
  };

  const imageUpload = (type, val) => {
    if (type == "address_proof") {
      setload1(true);
    } else if (type == "id_proof") {
      setload2(true);
    } else if (type == "photo_proof") {
      setload3(true);
    }

    console.log("type===", type);
    console.log("val===", val);
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    // console.log("fileExtension===", fileExtension);
    // console.log("fileSize===", fileSize);
    // console.log("fileName===", fileName);
    if (
      fileExtension != "png" &&
      fileExtension != "jpg" &&
      fileExtension != "jpeg"
    ) {
      toast.error("File does not support. You must use .png or .jpg or .jpeg ");
      setload1(false);
      setload2(false);
      setload3(false);

      return false;
    } else if (fileSize > 1000000) {
      toast.error("Please upload a file smaller than 1 MB");
      setload1(false);
      setload2(false);
      setload3(false);

      return false;
    } else {
      const data = new FormData();
      data.append("file", val);
      data.append("upload_preset", "mz5oapvg");
      data.append("cloud_name", "dxknk0rio");
      // console.log("formdata===", data);
      fetch(
        "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
        {
          method: "post",
          body: data,
        }
      )
        .then((resp) => resp.json())
        .then((data) => {
          // console.log("cloudinary upload===", data);
          if (type == "address_proof") {
            setaddressProof(false);
            setaddress_doc(data.secure_url);
            setload1(false);
          } else if (type == "id_proof") {
            setidProof(false);
            setid_doc(data.secure_url);
            setload2(false);
          } else if (type == "photo_proof") {
            setphotoProof(false);
            setphoto_doc(data.secure_url);
            setload3(false);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  const handleChange = async (e) => {
    // console.log("e====", e);
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    // console.log("formData====", formData);
    setFormValue(formData);
  };

  const validate = async (values) => {
    // console.log("kyc values===", values);
    const errors = {};
    if (values.pan_number == "") {
      errors.pan_number = "Pan Card Details is required!";
      setpanNumber(true);
    }
    if (address_doc == "") {
      errors.address_proof = "Address Proof is required!";
      setaddressProof(true);
    }

    if (id_doc == "") {
      errors.id_proof = "ID Proof is required!";
      setidProof(true);
    }

    if (photo_doc == "") {
      errors.photo_proof = "Photo proof is required!";
      setphotoProof(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const submitID = async () => {
    try {
      validate(formValue);
      formValue.address_proof = address_doc;
      formValue.id_proof = id_doc;
      formValue.photo_proof = photo_doc;
      formValue.pan_number = pan_number;
      if (
        formValue.address_proof != "" &&
        formValue.id_proof != "" &&
        formValue.photo_proof != ""
      ) {
        setloading(true);
        var data = {
          apiUrl: apiService.savekyc_id,
          payload: formValue,
        };

        var resp = await postMethod(data);
        if (resp.status) {
          setloading(false);
          getKyc();
          toast.success(resp.Message);
        } else {
          toast.error(resp.Message);
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="">
        <main className="main-content tradepage-bg login_body_ bg-cover">
          <Header />
          {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
                            
          <div class="container">
            <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
              <div className="container profile_section">
                <div className="row  justify-center">
                  <div className="col-lg-10">
                    <div className="kyc_card">
                      <div className="row">
                        {/* <div className="col-lg-4">
                          <div className="ky_c_contnet">
                            <img src={kyco} className="" />
                            <p>
                              Upload an identity document (Passport, Voters ID,
                              Aadhar Card). and a copy of your PAN card.
                            </p>
                            <p>
                              When cropping your document, please ensure the 4
                              edges are clearly visible.
                            </p>
                          </div>
                        </div> */}
                        <div className="col-lg-12 p-5 kyctitle">
                          <h4 className="h1 text-center ">
                            BitbuyerX Verify (KYC)
                          </h4>
                          <div className="contet_kyc_section">
                            {/* <p>Personal Verification:</p>
                            <p>
                              Processed promptly with automated verification
                            </p> */}
                            <div className="d-flex flex-row justify-content-between mt-4">
                              <div className="flex_active_">
                                {/* <div className="select_section active">
                                <p>Upload Documents</p>
                              </div> */}
                                {/* <div className="select_section">
                              <p>Step 2: Confirm Your Details</p>
                            </div> */}
                              </div>
                              <div>
                                <p className="h5 my-auto">
                                  KYC Status:{" "}
                                  {kycstatus == 0 ? (
                                    <span className="status_rejected ">
                                      Not Uploaded
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {kycstatus == 1 ? (
                                    <span className="status_done">
                                      Verified
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {kycstatus == 2 ? (
                                    <span className="status_pending">
                                      Pending
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {kycstatus == 3 ? (
                                    <span className="status_rejected">
                                      Rejected
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="row justify-content-around mb-3">
                              <div className="col-lg-4 text-center kyccol my-4">
                                <h5 className="h5 Bold ">
                                  Goverment Issued ID
                                </h5>
                                <div className="kycdetails">
                                  <p className="mb-3">
                                    The customer is required to upload one of
                                    the following types of documents: Passport,
                                    Driver's License, National ID Card,
                                    Residence Permit, Voter ID.
                                  </p>
                                </div>
                                <div className="input_section_kyc">
                                  {address_doc == "" && load1 != true ? (
                                    <>
                                      <img
                                        src={require("../img/Id.png")}
                                        className="mb-3"
                                      />
                                      <img
                                        src={require("../img/upload.png")}
                                        className="upload"
                                        width="10%"
                                      />
                                    </>
                                  ) : load1 == true ? (
                                    <i class="fa-solid fa-spinner fa-spin"></i>
                                  ) : (
                                    <img src={address_doc} className="" />
                                  )}
                                  {proof1_status == 0 || proof1_status == 3 ? (
                                    <input
                                      type="file"
                                      name="image"
                                      onChange={(e) =>
                                        imageUpload(
                                          "address_proof",
                                          e.target.files[0]
                                        )
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {addressProofref.current == true ? (
                                    <p className="text-danger warningicon">
                                      {" "}
                                      {/* {validationnErr.address_proof}{" "} */}
                                      <i class="bi bi-exclamation-diamond"></i>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4 text-center kyccol my-4">
                                <h5 className="h5 Bold ">Proof of Address</h5>
                                <div className="kycdetails">
                                  <p className="mb-3">
                                    The customer is required to upload one of
                                    the following types of documents: Utility
                                    Bills,Bank Statements,Official Government
                                    Documents , Insurance Documentst,Tax
                                    Statements
                                  </p>
                                </div>
                                <div className="input_section_kyc">
                                  {id_doc == "" && load2 != true ? (
                                    <>
                                      <img
                                        src={require("../img/address.png")}
                                        className=""
                                      />
                                      <img
                                        src={require("../img/upload.png")}
                                        className="upload"
                                        width="10%"
                                      />
                                    </>
                                  ) : load2 == true ? (
                                    <i class="fa-solid fa-spinner fa-spin"></i>
                                  ) : (
                                    <img src={id_doc} className="" />
                                  )}
                                  {proof2_status == 0 || proof2_status == 3 ? (
                                    <input
                                      type="file"
                                      name="image"
                                      onChange={(e) =>
                                        imageUpload(
                                          "id_proof",
                                          e.target.files[0]
                                        )
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {idProofref.current == true ? (
                                    <p className="text-danger warningicon">
                                      {" "}
                                      <i class="bi bi-exclamation-diamond"></i>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4 text-center kyccol my-4">
                                <h5 className="h5 Bold">Selfie Verification</h5>
                                <div className="kycdetails">
                                  <p className="mb-3">
                                    Users are requested to submit a formal
                                    selfie while holding any valid
                                    identification document.
                                  </p>
                                </div>
                                <div className="input_section_kyc">
                                  {photo_doc == "" && load3 != true ? (
                                    <>
                                      <img
                                        src={require("../img/profile.png")}
                                        className=""
                                      />
                                      <img
                                        src={require("../img/upload.png")}
                                        className="upload"
                                        width="10%"
                                      />
                                    </>
                                  ) : load3 == true ? (
                                    <i class="fa-solid fa-spinner fa-spin"></i>
                                  ) : (
                                    <img src={photo_doc} className="" />
                                  )}

                                  {proof3_status == 0 || proof3_status == 3 ? (
                                    <input
                                      type="file"
                                      name="image"
                                      onChange={(e) =>
                                        imageUpload(
                                          "photo_proof",
                                          e.target.files[0]
                                        )
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {photoProofref.current == true ? (
                                    <p className="text-danger warningicon">
                                      {" "}
                                      <i class="bi bi-exclamation-diamond"></i>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="detaile_foem_kyc">
                              <div>
                                <div>
                                  <div className="row">
                                    {kycstatus == 0 || kycstatus == 3 ? (
                                      <span className="col-lg-12">
                                        {/* <div className="row">
                                          <div className="col-lg-6">
                                            <div className="form-group">
                                              <label>Account Holder Name</label>
                                              <input
                                                className="form-control"
                                                placeholder="Account Holder Name"
                                                name="AccountHolderName"
                                                value={AccountHolderName}
                                                onChange={handleChange}
                                              />
                                              {AccountHolderNameErrref.current ==
                                              true ? (
                                                <p className="text-danger">
                                                  {" "}
                                                  {
                                                    validationnErr.AccountHolderName
                                                  }{" "}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div> */}

                                        {/* <div className="col-lg-6">
                                            <div className="form-group">
                                              <label>Account Number</label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Account Number"
                                                name="AccountNumber"
                                                value={AccountNumber}
                                                onChange={handleChange}
                                              />
                                              {AccountNumberErrref.current ==
                                              true ? (
                                                <p className="text-danger">
                                                  {" "}
                                                  {
                                                    validationnErr.AccountNumber
                                                  }{" "}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>

                                          <div className="col-lg-6">
                                            <div className="form-group">
                                              <label>IFSC Code</label>
                                              <input
                                                className="form-control"
                                                placeholder="IFSC Code"
                                                name="IFSCCode"
                                                value={IFSCCode}
                                                onChange={handleChange}
                                              />

                                              {IFSCCodeErrref.current ==
                                              true ? (
                                                <p className="text-danger">
                                                  {" "}
                                                  {validationnErr.IFSCCode}{" "}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>

                                          <div className="col-lg-6">
                                            <div className="form-group">
                                              <label>Bank Name</label>
                                              <input
                                                className="form-control"
                                                placeholder="Bank Name"
                                                name="BankName"
                                                value={BankName}
                                                onChange={handleChange}
                                              />
                                              {BankNameErrref.current ==
                                              true ? (
                                                <p className="text-danger">
                                                  {" "}
                                                  {validationnErr.BankName}{" "}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>

                                          <div className="col-lg-6">
                                            <div className="form-group">
                                              <label>Branch Name</label>
                                              <input
                                                className="form-control"
                                                placeholder="Branch Name"
                                                name="BranchName"
                                                value={BranchName}
                                                onChange={handleChange}
                                              />
                                              {BranchNameErrref.current ==
                                              true ? (
                                                <p className="text-danger">
                                                  {" "}
                                                  {
                                                    validationnErr.BranchName
                                                  }{" "}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>

                                          <div className="col-lg-6">
                                            <div className="form-group">
                                              <label>Branch Address</label>
                                              <input
                                                className="form-control"
                                                placeholder="Branch Address"
                                                name="BranchAddress"
                                                value={BranchAddress}
                                                onChange={handleChange}
                                              />
                                              {BranchAddressErrref.current ==
                                              true ? (
                                                <p className="text-danger">
                                                  {" "}
                                                  {
                                                    validationnErr.BranchAddress
                                                  }{" "}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div> */}
                                        {/* <div className="col-lg-6">
                                            <div class="form-group">
                                              <label>PAN Card details</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="inputEmail4"
                                                placeholder="Pan Card"
                                                name="pan_number"
                                                value={pan_number}
                                                onChange={handleChange}
                                              />
                                              {panNumberref.current == true ? (
                                                <p className="text-danger">
                                                  {" "}
                                                  {
                                                    validationnErr.pan_number
                                                  }{" "}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div> */}
                                        {/* <div className="col-lg-6">
                                            <div class="form-group">
                                              <label>Gpay</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="inputEmail4"
                                                placeholder="Gpay"
                                                name="gpay_number"
                                                value={gpay_number}
                                                onChange={handleChange}
                                              />
                                              {gpayNumberref.current == true ? (
                                                <p className="text-danger">
                                                  {" "}
                                                  {
                                                    validationnErr.gpay_number
                                                  }{" "}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>

                                          <div className="col-lg-6">
                                            <div class="form-group">
                                              <label>Paytm</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="inputEmail4"
                                                placeholder="Paytm"
                                                name="paytm_number"
                                                value={paytm_number}
                                                onChange={handleChange}
                                              />
                                              {paytmNumberref.current == true ? (
                                                <p className="text-danger">
                                                  {" "}
                                                  {
                                                    validationnErr.paytm_number
                                                  }{" "}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div> */}
                                      </span>
                                    ) : (
                                      <span className="col-lg-12 justify-content-start pl-0">
                                        <div className="col-lg-6">
                                          <div className="form-group  pl-0 pr-0 kyc-datafrom">
                                            {/* <p>Bank Details</p>
                                            <p className="d-flex justify-content-between align-items-center w-100 mb-1">
                                              Account Holder Name :{" "}
                                              <span className="ml-2">
                                                {AccountHolderName}
                                              </span>
                                            </p>
                                            <p className="d-flex justify-content-between align-items-center w-100 mb-1">
                                              BankName :{" "}
                                              <span className="ml-2">
                                                {AccountNumber}
                                              </span>
                                            </p>
                                            <p className="d-flex justify-content-between align-items-center w-100 mb-1">
                                              Account Holder Name :{" "}
                                              <span className="ml-2">
                                                {BankName}
                                              </span>
                                            </p>
                                            <p className="d-flex justify-content-between align-items-center w-100 mb-1">
                                              IFSC Code :{" "}
                                              <span className="ml-2">
                                                {IFSCCode}
                                              </span>
                                            </p>
                                            <p className="d-flex justify-content-between align-items-center w-100 mb-1">
                                              Account Holder Name :{" "}
                                              <span className="ml-2">
                                                {BranchName}
                                              </span>
                                            </p>
                                            <p className="d-flex justify-content-between align-items-center w-100 mb-1">
                                              Branch Address :{" "}
                                              <span className="ml-2">
                                                {BranchAddress}
                                              </span>
                                            </p> */}
                                            {/* <p className="d-flex justify-content-between align-items-center w-100 mb-1">
                                              Pan Card Details :{" "}
                                              <span className="ml-2">
                                                {pan_number}
                                              </span>
                                            </p> */}
                                            {/* <p className="d-flex justify-content-between align-items-center w-100 mb-1">
                                              Gpay Details :{" "}
                                              <span className="ml-2">
                                                {gpay_number}
                                              </span>
                                            </p>
                                            <p className="d-flex justify-content-between align-items-center w-100 mb-1">
                                              Paytm Details :{" "}
                                              <span className="ml-2">
                                                {paytm_number}
                                              </span>
                                            </p> */}
                                          </div>
                                        </div>
                                      </span>
                                    )}
                                  </div>
                                  <div>
                                    <div>
                                      <div>
                                        {kycstatus == 0 || kycstatus == 3 ? (
                                          <div class="form-group text-center mt-5">
                                            {loading == true ? (
                                              <Button class="button-red security__button button_www button_margud mx-auto mt-5">
                                                Loading
                                              </Button>
                                            ) : (
                                              <button
                                                class="button-red security__button button_www button_margud mx-auto mt-5"
                                                onClick={submitID}
                                              >
                                                Submit
                                              </button>
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
}
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
