import React, { useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import { Button } from "@material-ui/core";

import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { setAuthToken, getAuthToken } from "../core/lib/localStorage";
import OTPInput, { ResendOTP } from "otp-input-react";
import useState from "react-usestateref";
import P2pbuys from "./P2pbuys";
import Nodata from "../img/no_data.png";

const options = ["one", "two", "three"];
const defaultOption = options[0];

function Home() {
  const p2pFormValue = {
    qty: "",
    total: "",
  };
  const [sideLoader,setSiteLoader]=useState(true);

  const [getP2POrders, setgetAllp2pOrders, getP2POrdersref] = useState([]);
  const [sendDatas, setSendDatas, sendDatasref] = useState("");
  const [show, setShow, showref] = useState(false);
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCurrencyFiat, setallCurrencyFiat, allCurrencyFiatref] = useState(
    []
  );
  const [p2pformValue, setp2pFormValue, p2pformValueref] =
    useState(p2pFormValue);
  const [activetab, setActive, activetabref] = useState("BTC");
  const [activetype, setActivetype, activetyperef] = useState("BTC");
  const [fiatCurrency, setfiatCurrency, fiatCurrencyref] = useState("");
  const [profileDatas, setprofileData, profileDatasref] = useState("");
  const [loginTrue, setloginTrue] = useState(true);
  const [p2pData, setp2pData, p2pDataref] = useState("");
  const [p2pbalance, setp2pbalance, p2pbalanceref] = useState("");
  const [Count, setCount, Countref] = useState();

  const { qty, total } = p2pformValue;

  useEffect(() => {
    getAllp2pOrders();
    getAllcurrency();
    let user_token = getAuthToken();
    // console.log("user_token===", typeof user_token);
    // console.log("user_token.length===", user_token.length);
    if (user_token != "" && user_token != undefined && user_token != null) {
      setloginTrue(true);
      getProfile();
    } else {
      setloginTrue(false);
    }
  }, []);

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setprofileData(resp.data);
      }
    } catch (error) {}
  };

  const getAllp2pOrders = async () => {
    var onj = {
      currency:
        fiatCurrencyref.current && fiatCurrencyref.current != "all"
          ? fiatCurrencyref.current
          : "",
    };
    setSiteLoader(true);
    var data = {
      apiUrl: apiService.p2pGetOrder,
      payload: onj,
    };
    var resp = await postMethod(data);
    setSiteLoader(false);
    // console.log(resp.Message, "-=-=-resp=-=-");
    if (resp) {
      var data = resp.Message;
      console.log(resp.Message, "resp.Message");
      setgetAllp2pOrders(resp.Message);
    }
  };

  const confirm_handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;
    let p2pformData = { ...p2pformValue, ...{ [name]: value } };
    setp2pFormValue(p2pformData);
    console.log("p2pformValueref.current.qty===", p2pformValueref.current.qty);
    if (p2pformValueref.current.qty > 0) {
      var order_qty = p2pformValueref.current.qty;
      var min_qty = p2pDataref.current.fromLimit;
      var max_qty = p2pDataref.current.toLimit;
      if (
        order_qty < p2pDataref.current.fromLimit ||
        order_qty > p2pDataref.current.toLimit
      ) {
        toast.error(
          "Please enter quantity between " + min_qty + " and " + max_qty + ""
        );
      } else {
        p2pformValueref.current.total = parseFloat(
          order_qty * p2pDataref.current.price
        ).toFixed(2);
        console.log("p2pformData====", p2pformValue);
      }
    } else {
      toast.error("Please enter valid quantity");
    }
  };
  const getp2pOrder = async (data) => {
    console.log(data, "data");
    setp2pData(data);
    var onj = {
      fromCurrency: data.fromCurrency,
    };
    var data = {
      apiUrl: apiService.getp2pBalance,
      payload: onj,
    };
    var resp = await postMethod(data);
    console.log(resp.Message, "-=-=-resp=-=-");
    if (resp) {
      var data = resp.Message;
      setp2pbalance(resp.p2pbalance);
    }
  };
  const confirm_order_sell = async () => {
    try {
      var obj = {};
      obj.qty = p2pformValue.qty;
      obj.total = p2pformValue.total;
      obj.orderId = p2pDataref.current.orderId;
      obj.p2porderId = p2pDataref.current._id;
      obj.type = "sell";

      if (obj.qty != "" || obj.total != "") {
        var data = {
          apiUrl: apiService.p2p_confirm_sellorder,
          payload: obj,
        };
        var resp = await postMethod(data);
        console.log(resp, "resp");
        if (resp.status) {
          console.log(resp);
          toast.success(resp.Message);
          //navigate(resp.link);
          window.location.href = resp.link;
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter quantity");
      }
    } catch (error) {}
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.getP2Pcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      var currArrayFiat = [{ value: "all", label: "Select Currency" }];
      var data = resp.data;
      for (var i = 0; i < data.length; i++) {
        if (data[i].coinType == "1") {
          var obj = {
            id: data[i]._id,
            currencySymbol: data[i].currencySymbol,
          };
          currArrayCrypto.push(obj);
        }
        if (data[i].coinType == "2") {
          var obj = {
            value: data[i]._id,
            label: data[i].currencySymbol,
          };
          currArrayFiat.push(obj);
        }
      }
      var obj1 = {
        id: "All",
        currencySymbol: "All",
      };
      currArrayCrypto.push(obj1);
      setallCurrency(currArrayCrypto);
      setallCurrencyFiat(currArrayFiat);
    }
  };

  const defaulatCurrFiat = allCurrencyFiatref.current[0];

  const onSelect = async (option) => {
    // console.log(option, "-=-onSelecttop");

    if (option.label == "Select Currency") {
      // console.log("call currency all");
      setfiatCurrency(option.value);
      // console.log("call currency all", fiatCurrencyref.current);
      getAllp2pOrders();
      setActive(activetabref.current);
      setActivetype("buy");
    } else {
      setfiatCurrency(option.label);
      var onj = {
        currency: option.label,
      };
      var data = {
        apiUrl: apiService.p2pGetOrder,
        payload: onj,
      };
      var resp = await postMethod(data);
      if (resp) {
        var data = resp.Message;
        setgetAllp2pOrders(resp.Message);
        console.log(resp.Message, "resp.Message11");
      }
    }
  };

  const handleChange = async (e) => {
    const newActiveTab = e.target.getAttribute("data-tab");
    getAllp2pOrders();
    setActive(newActiveTab);
    setActivetype("buy");
    var j = 0;
    for (let i = 0; i < getP2POrdersref.current.length; i++) {
      const element = getP2POrdersref.current[i];
      if (element.firstCurrency == activetabref.current) {
        j = j + 1;
        console.log(j, "j");
      } else if (activetabref.current == "All") {
        j = j + 1;
      } else {
        j = j + 0;
        console.log(j, "j");
      }
    }
    setCount(j);
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
            
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5 padin_zero ">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div className="container d-flex justify-content-center padin_zero">
              <div className="col-lg-12">
                <div className="p2ppost p2plist bg_card_super">
                  <div
                    id="confirm_p2p_sell"
                    class="modal launchpad_doce fade"
                    role="dialog"
                  >
                    <div class="modal-dialog modal-dialog-centered ">
                      <div class="modal-content">
                        <div class="modal-body model_confirms">
                          <h2 className="h2 text-center"> Confirm Order </h2>
                          <form className="launch_card new_table  py-3 tranbg mb-0">
                            <div className="input_section">
                              <p>
                                <span>
                                  {getP2POrdersref.current &&
                                  getP2POrdersref.current.orderType == "buy"
                                    ? "Enter quantity to sell"
                                    : "Enter quantity to buy"}
                                </span>
                              </p>
                              <div className="input_select_s newbtind mt-3">
                                <input
                                  type="text"
                                  name="qty"
                                  // value={qty}
                                  className="px-2"
                                  onChange={confirm_handleChange}
                                />
                              </div>
                            </div>
                            <div className="input_section mt-4">
                              <p>
                                <span>
                                  {getP2POrdersref.current &&
                                  getP2POrdersref.current.orderType == "buy"
                                    ? "You Will Receive"
                                    : "You Will Pay"}
                                </span>
                              </p>
                              <div className="input_select_s newbtind mt-3">
                                <input
                                  type="text"
                                  name="total"
                                  value={total}
                                  onChange={confirm_handleChange}
                                />
                              </div>
                            </div>
                            <div className="submiot">
                              <Button
                                className="trade-btn green bonfirm_butotn"
                                data-toggle="modal"
                                data-target="#launchpad_doce"
                                onClick={confirm_order_sell}
                              >
                                Confirm
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-block button_flex">
                    <Link to="/p2p/post_ad">
                      <Button className="my-2 mx-auto postadd">
                        {" "}
                        Go to post add{" "}
                      </Button>
                    </Link>
                  </div>
                  <ul class="nav nav-tabs">
                    {allCurrencyref.current &&
                      allCurrencyref.current.map((item, i) => {
                        var classactive = "";
                        if (i == 0) {
                          classactive = "active";
                        }
                        return (
                          <li class={`${classactive}`}>
                            <a
                              data-toggle="tab"
                              data-tab={`${item.currencySymbol}`}
                              href={`#${item.currencySymbol}`}
                              class={`${classactive}`}
                              onClick={handleChange}
                            >
                              {item.currencySymbol}
                            </a>
                          </li>
                        );
                      })}
                  </ul>

                  <div class="tab-content">
                    {allCurrencyref.current &&
                      allCurrencyref.current.map((item, j) => {
                        return (
                          <>
                            {activetabref.current == item.currencySymbol ? (
                              <div className="row">
                                <div className="col-lg-8 col-md-6 col-sm-6">
                                  <div className="buy_sell_s ">
                                    <ul class="nav nav-tabs primaryNav">
                                      <li class="active">
                                        <a
                                          data-toggle="tab"
                                          href={`#BuyT${item.currencySymbol}`}
                                          class="nav-link color-green active"
                                        >
                                          Buy
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          data-toggle="tab"
                                          href={`#SellT${item.currencySymbol}`}
                                          className="nav-link color-red"
                                        >
                                          Sell
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-6 d-flex align-item-center">
                                  <div class="form-group curroncy_blac">
                                    <label>Fiat </label>
                                    <Dropdown
                                      options={allCurrencyFiatref.current}
                                      onChange={(o) => onSelect(o)}
                                      value={defaulatCurrFiat}
                                      placeholder="Select an option"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div
                              id={`${item.currencySymbol}`}
                              class={`tab-pane fade in ${
                                activetabref.current == item.currencySymbol
                                  ? "active show"
                                  : ""
                              }`}
                            >
                              <div class="tab-content">
                                <div
                                  id={`BuyT${item.currencySymbol}`}
                                  class="tab-pane fade in active show"
                                >
                                  <div className="buyform_now">
                                    <div class="fixTableHead table_section_color">
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>Advertisers</th>
                                            <th>Price</th>
                                            <th>Available</th>
                                            <th>Limit</th>
                                            <th>Payment</th>
                                            <th>Trade</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Countref.current > 0 ? (
                                            getP2POrdersref.current &&
                                            getP2POrdersref.current.map(
                                              (orders, i) => {
                                                var ordertype =
                                                  orders.orderType == "buy"
                                                    ? "sell"
                                                    : "buy";
                                                return orders.firstCurrency ==
                                                  activetabref.current &&
                                                  ordertype == "buy" ? (
                                                  <tr>
                                                    <td>{orders.username}</td>
                                                    <td>
                                                      {orders && orders.price}{" "}
                                                      {orders &&
                                                        orders.secondCurrnecy}
                                                    </td>
                                                    <td>
                                                      <p>
                                                        <small className="availe_small">
                                                          Available
                                                        </small>{" "}
                                                        {orders &&
                                                          orders.filledAmount}{" "}
                                                        {orders &&
                                                          orders.firstCurrency}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <p>
                                                        {orders &&
                                                          orders.fromLimit}
                                                        {" - "}
                                                        {orders &&
                                                          orders.toLimit}{" "}
                                                        {orders &&
                                                          orders.firstCurrency}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <p className="paymet-mode">
                                                        {orders &&
                                                          orders.paymentMethod}
                                                      </p>
                                                    </td>
                                                    {loginTrue == true ? (
                                                      profileDatasref.current &&
                                                      profileDatasref.current._id.toString() ==
                                                        orders.userId.toString() ? (
                                                        <td>
                                                          <Link
                                                            to={`/p2p/view/${orders.orderId}`}
                                                          >
                                                            <Button className="trade-btn green">
                                                              {" "}
                                                              View{" "}
                                                            </Button>{" "}
                                                          </Link>
                                                          {/* </Link> */}
                                                        </td>
                                                      ) : (
                                                        <td>
                                                          <Button
                                                            className="trade-btn green"
                                                            data-toggle="modal"
                                                            data-target="#confirm_p2p_sell"
                                                            onClick={() =>
                                                              getp2pOrder(
                                                                orders
                                                              )
                                                            }
                                                          >
                                                            {orders &&
                                                            orders.orderType ==
                                                              "buy"
                                                              ? "sell"
                                                              : "buy"}
                                                          </Button>
                                                        </td>
                                                      )
                                                    ) : (
                                                      <td>
                                                        <Link to={"/login"}>
                                                          <Button
                                                            className="trade-btn green"
                                                            data-toggle="modal"
                                                            data-target="#confirm_p2p_sell"
                                                            onClick={() =>
                                                              getp2pOrder(
                                                                orders
                                                              )
                                                            }
                                                          >
                                                            {" "}
                                                            {orders &&
                                                            orders.orderType ==
                                                              "buy"
                                                              ? "sell"
                                                              : "buy"}{" "}
                                                          </Button>
                                                        </Link>
                                                      </td>
                                                    )}
                                                  </tr>
                                                ) : (
                                                  ""
                                                );
                                              }
                                            )
                                          ) : (
                                            <td colSpan="6">
                                              {/* <img src={Nodata} className="logo" width="30%"/> */}
                                              No Data Found
                                            </td>
                                          )}

                                          {getP2POrdersref.current &&
                                            getP2POrdersref.current.map(
                                              (orders, i) => {
                                                var ordertype =
                                                  orders.orderType == "buy"
                                                    ? "sell"
                                                    : "buy";
                                                return activetabref.current ==
                                                  "All" &&
                                                  ordertype == "buy" ? (
                                                  <tr>
                                                    <td>{orders.username}</td>
                                                    <td>
                                                      {orders && orders.price}{" "}
                                                      {orders &&
                                                        orders.secondCurrnecy}
                                                    </td>
                                                    <td>
                                                      <p>
                                                        <small className="availe_small">
                                                          Available
                                                        </small>{" "}
                                                        {orders &&
                                                          orders.totalAmount}{" "}
                                                        {orders &&
                                                          orders.firstCurrency}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <p>
                                                        {orders &&
                                                          orders.fromLimit}
                                                        {" - "}
                                                        {orders &&
                                                          orders.toLimit}{" "}
                                                        {orders &&
                                                          orders.firstCurrency}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <p className="paymet-mode">
                                                        {orders &&
                                                          orders.paymentMethod}
                                                      </p>
                                                    </td>
                                                    {loginTrue == true ? (
                                                      profileDatasref.current &&
                                                      profileDatasref.current._id.toString() ==
                                                        orders.userId.toString() ? (
                                                        <td>
                                                          <Link
                                                            to={`/p2p/view/${orders.orderId}`}
                                                          >
                                                            <Button className="trade-btn green">
                                                              View
                                                            </Button>{" "}
                                                          </Link>
                                                        </td>
                                                      ) : (
                                                        <td>
                                                          <Button
                                                            className="trade-btn green"
                                                            data-toggle="modal"
                                                            data-target="#confirm_p2p_sell"
                                                            onClick={() =>
                                                              getp2pOrder(
                                                                orders
                                                              )
                                                            }
                                                          >
                                                            {" "}
                                                            {orders &&
                                                            orders.orderType ==
                                                              "buy"
                                                              ? "sell"
                                                              : "buy"}{" "}
                                                          </Button>{" "}
                                                        </td>
                                                      )
                                                    ) : (
                                                      <td>
                                                        <Link to={"/login"}>
                                                          <Button className="trade-btn green">
                                                            {" "}
                                                            {orders &&
                                                            orders.orderType ==
                                                              "buy"
                                                              ? "sell"
                                                              : "buy"}{" "}
                                                          </Button>
                                                        </Link>
                                                      </td>
                                                    )}
                                                  </tr>
                                                ) : (
                                                  ""
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id={`SellT${item.currencySymbol}`}
                                  class="tab-pane fade in"
                                >
                                  <div className="buyform_now">
                                    <div class="fixTableHead">
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>Advertisers</th>
                                            <th>Price</th>
                                            <th>Limit/Available</th>
                                            <th>Payment</th>
                                            <th>Trade</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {Countref.current > 0 ? (
                                            getP2POrdersref.current &&
                                            getP2POrdersref.current.map(
                                              (orders, i) => {
                                                var ordertype =
                                                  orders.orderType == "buy"
                                                    ? "sell"
                                                    : "buy";
                                                return orders.firstCurrency ==
                                                  activetabref.current &&
                                                  ordertype == "sell" ? (
                                                  <tr>
                                                    <td>{orders.username}</td>
                                                    <td>
                                                      {orders && orders.price}{" "}
                                                      {orders &&
                                                        orders.secondCurrnecy}
                                                    </td>
                                                    <td>
                                                      <p>
                                                        <small className="availe_small">
                                                          Available
                                                        </small>{" "}
                                                        {orders &&
                                                          orders.filledAmount}{" "}
                                                        {orders &&
                                                          orders.firstCurrency}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <p>
                                                        <small className="availe_small">
                                                          Limit
                                                        </small>{" "}
                                                        {orders &&
                                                          orders.fromLimit}
                                                        {" - "}
                                                        {orders &&
                                                          orders.toLimit}{" "}
                                                        {orders &&
                                                          orders.firstCurrency}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <p className="paymet-mode">
                                                        {orders &&
                                                          orders.paymentMethod}
                                                      </p>
                                                    </td>
                                                    {loginTrue == true ? (
                                                      profileDatasref.current &&
                                                      profileDatasref.current._id.toString() ==
                                                        orders.userId.toString() ? (
                                                        <td>
                                                          <Link
                                                            to={`/p2p/chat/${orders.orderId}`}
                                                          >
                                                            <Button className="trade-btn green">
                                                              {" "}
                                                              View{" "}
                                                            </Button>
                                                          </Link>{" "}
                                                        </td>
                                                      ) : (
                                                        <td>
                                                          <Button
                                                            className="trade-btn green"
                                                            data-toggle="modal"
                                                            data-target="#confirm_p2p_sell"
                                                            onClick={() =>
                                                              getp2pOrder(
                                                                orders
                                                              )
                                                            }
                                                          >
                                                            {" "}
                                                            {orders &&
                                                            orders.orderType ==
                                                              "buy"
                                                              ? "sell"
                                                              : "buy"}{" "}
                                                          </Button>{" "}
                                                        </td>
                                                      )
                                                    ) : (
                                                      <td>
                                                        <Link to={"/login"}>
                                                          <Button className="trade-btn green">
                                                            {" "}
                                                            {orders &&
                                                            orders.orderType ==
                                                              "buy"
                                                              ? "sell"
                                                              : "buy"}{" "}
                                                          </Button>
                                                        </Link>
                                                      </td>
                                                    )}
                                                  </tr>
                                                ) : (
                                                  ""
                                                );
                                              }
                                            )
                                          ) : (
                                            <td colSpan="6">
                                              {/* <img src={Nodata} className="logo"  width="30%"  /> */}
                                              No data Found
                                            </td>
                                          )}

                                          {getP2POrdersref.current &&
                                            getP2POrdersref.current.map(
                                              (orders, i) => {
                                                var ordertype =
                                                  orders.orderType == "buy"
                                                    ? "sell"
                                                    : "buy";
                                                return activetabref.current ==
                                                  "All" &&
                                                  ordertype == "sell" ? (
                                                  <tr>
                                                    <td>{orders.username}</td>
                                                    <td>
                                                      {orders && orders.price}{" "}
                                                      {orders &&
                                                        orders.secondCurrnecy}
                                                    </td>
                                                    <td>
                                                      <p>
                                                        <small className="availe_small">
                                                          Available
                                                        </small>{" "}
                                                        {orders &&
                                                          orders.totalAmount}{" "}
                                                        {orders &&
                                                          orders.firstCurrency}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <p>
                                                        {orders &&
                                                          orders.fromLimit}
                                                        {" - "}
                                                        {orders &&
                                                          orders.toLimit}{" "}
                                                        {orders &&
                                                          orders.firstCurrency}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      <p className="paymet-mode">
                                                        {orders &&
                                                          orders.paymentMethod}
                                                      </p>
                                                    </td>
                                                    {loginTrue == true ? (
                                                      profileDatasref.current &&
                                                      profileDatasref.current._id.toString() ==
                                                        orders.userId.toString() ? (
                                                        <td>
                                                          <Link
                                                            to={`/p2p/view/${orders.orderId}`}
                                                          >
                                                            <Button className="trade-btn green">
                                                              View
                                                            </Button>{" "}
                                                          </Link>
                                                        </td>
                                                      ) : (
                                                        <td>
                                                          <Button
                                                            className="trade-btn green"
                                                            data-toggle="modal"
                                                            data-target="#confirm_p2p_sell"
                                                            onClick={() =>
                                                              getp2pOrder(
                                                                orders
                                                              )
                                                            }
                                                          >
                                                            {" "}
                                                            {orders &&
                                                            orders.orderType ==
                                                              "buy"
                                                              ? "sell"
                                                              : "buy"}{" "}
                                                          </Button>{" "}
                                                        </td>
                                                      )
                                                    ) : (
                                                      <td>
                                                        <Link to={"/login"}>
                                                          <Button className="trade-btn green">
                                                            {" "}
                                                            {orders &&
                                                            orders.orderType ==
                                                              "buy"
                                                              ? "sell"
                                                              : "buy"}{" "}
                                                          </Button>
                                                        </Link>
                                                      </td>
                                                    )}
                                                  </tr>
                                                ) : (
                                                  ""
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
