import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Button } from "@material-ui/core";
import "react-dropdown/style.css";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import Moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import { Link, useNavigate } from "react-router-dom";

function Home() {
  Moment.locale("en");
  const [depositHistory, setdepositHistory] = useState([]);
  const [depositcurrentpage, setdepositcurrentpage] = useState(1);
  const [deposittotalpage, setdepositTotalpages] = useState(0);
  const [Depositcsvfile, SetDepositcsvfile] = useState([]);
  const [TestDepositcsvfile, SetTestDepositcsvfile] = useState([]);
  const [fiatdepositHistory, setfiatdepositHistory] = useState([]);
  const [fiatdepositcurrentpage, setfiatdepositcurrentpage] = useState(1);
  const [fiatdeposittotalpage, setfiatdepositTotalpages] = useState(0);
  const [FiatDepositcsvfile, SetFiatDepositcsvfile] = useState([]);
  const [TestFiatDepositcsvfile, SetTestFiatDepositcsvfile] = useState([]);
  const [checkAuth, setcheckAuth] = useState(false);
  const [sideLoader,setSiteLoader]=useState(true);

  const Depositcsv = async () => {
    await SetDepositcsvfile(TestDepositcsvfile);
  };
  const FiatDepositcsv = async () => {
    await SetFiatDepositcsvfile(TestFiatDepositcsvfile);
  };

  const getdepositHistory = async (page) => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    setSiteLoader(true);
    var deposit_history_list = await postMethod(obj);
    setSiteLoader(false);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.result);
      SetTestDepositcsvfile(deposit_history_list.depositcsv);
      setdepositTotalpages(deposit_history_list.count);
    }
  };

  const getfiatdepositHistory = async (page) => {
    var obj = {
      apiUrl: apiService.fiatdeposit_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var fiatdeposit_history_list = await postMethod(obj);
    if (fiatdeposit_history_list) {
      setfiatdepositHistory(fiatdeposit_history_list.result);
      SetTestFiatDepositcsvfile(fiatdeposit_history_list.depositcsv);
      setfiatdepositTotalpages(fiatdeposit_history_list.pages);
    }
  };

  const fiatdepositrecordpage = 5;
  const fiatdepositpagerange = 5;

  const depositrecordpage = 5;
  const depositpagerange = 5;

  const handlepagefiatdeposit = (page) => {
    // console.log(page, "==-=-=-p2ppage==-==-=");
    getfiatdepositHistory(page);
    setfiatdepositcurrentpage(page);
  };

  const handlepagedeposit = (page) => {
    // console.log(page, "==-=-=-p2ppage==-==-=");
    getdepositHistory(page);
    setdepositcurrentpage(page);
  };
  const navigate = useNavigate();

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getdepositHistory(1);
    getfiatdepositHistory(1);
  }, [0]);

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5 padinf_ mb-5">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padinf_">
            <div className="container d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="bg_ash bg_card_section bg_card_super">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="title_section_funds">
                        <h2>Deposit History</h2>
                      </div>
                    </div>
                    <div className="col-lg-8"></div>
                  </div>
                </div>
                <div className="staking_optoikn">
                  {/* //======================Staking Details==============// */}

                  <div class="">
                    <div id="LockedStaking" class="">
                      <div className="staking_title bg_card_super">
                        <div class="fixTableHead table_section_color">
                          <div className="title-table p-0">
                            {/* <h4>History</h4> */}
                            <ul className="nav nav-tabs width_tabs_section">
                              <li className="active">
                                <a
                                  data-toggle="tab"
                                  href="#OpenOrders"
                                  className="active"
                                >
                                  Crypto Deposit
                                </a>
                              </li>
                              <li>
                                <a data-toggle="tab" href="#OrderHistory">
                                  Fiat Deposite
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="tab-content border_non">
                            {/* ==========ACTIVE OREDERS========== */}
                            <div
                              id="OpenOrders"
                              className="tab-pane fade in active show "
                            >
                              <div className="fixTableHead table_section_color card_light p-0 m-0">
                                {depositHistory && depositHistory.length > 0 ? (
                                  <CSVLink
                                    className="btn btn-primary float-right mr-2 mt-2 mb-3"
                                    filename="Deposit History"
                                    onClick={Depositcsv}
                                    data={Depositcsvfile}
                                  >
                                    <i
                                      class="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </CSVLink>
                                ) : (
                                  <div className="btn yellow float-right mr-2 mt-2 mb-3">
                                    <i
                                      class="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                )}
                                <table>
                                  <thead>
                                    <tr>
                                      <th scope="col">S.No</th>
                                      <th scope="col">Date</th>
                                      <th scope="col">Time</th>
                                      <th scope="col">Currency</th>
                                      <th scope="col">Amount</th>
                                      <th scope="col">Transaction Id</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {depositHistory &&
                                    depositHistory.length > 0 ? (
                                      depositHistory.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                              {Moment(item.date).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </td>
                                            <td>
                                              {Moment(item.date).format(
                                                "hh:mm a"
                                              )}
                                            </td>
                                            <td>{item.currencySymbol}</td>
                                            <td>
                                              {parseFloat(item.amount).toFixed(
                                                8
                                              )}
                                            </td>
                                            <td>
                                              <div className="copy_iconss">
                                                <span className="text_wrapaa">
                                                  {item.txnid}
                                                </span>
                                                <div className="copyicon">
                                                  <span
                                                    onClick={() =>
                                                      copy(item.txnid)
                                                    }
                                                  >
                                                    <i
                                                      class="fa fa-clone"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <span className="text-green">
                                                Completed
                                              </span>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <td colSpan="7">
                                        <span className="w-100 text-center d-block">
                                          No Deposit History Found!
                                        </span>
                                      </td>
                                    )}
                                  </tbody>
                                </table>
                                {depositHistory && depositHistory.length > 0 ? (
                                  <div className="mt-5">
                                    <Pagination
                                      itemClass="page-item" // add it for bootstrap 4
                                      linkClass="page-link" // add it for bootstrap 4
                                      activePage={depositcurrentpage}
                                      itemsCountPerPage={depositrecordpage}
                                      totalItemsCount={deposittotalpage}
                                      pageRangeDisplayed={depositpagerange}
                                      onChange={handlepagedeposit}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div id="OrderHistory" className="tab-pane fade">
                              {/* ==========OREDERS HISTORY========== */}

                              <div className="fixTableHead table_section_color card_light p-0 m-0">
                                {fiatdepositHistory &&
                                fiatdepositHistory.length > 0 ? (
                                  <CSVLink
                                    className="btn btn-primary float-right mr-2 mt-2 mb-3"
                                    filename="Deposit History"
                                    onClick={FiatDepositcsv}
                                    data={FiatDepositcsvfile}
                                  >
                                    <i
                                      class="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </CSVLink>
                                ) : (
                                  <div className="btn yellow float-right mr-2 mt-2 mb-3">
                                    <i
                                      class="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                )}
                                <table>
                                  <thead>
                                    <tr>
                                      <th>S.No </th>
                                      <th>Date </th>
                                      <th>Currency </th>
                                      <th>Amount </th>
                                      <th>Payment ID </th>
                                      <th>Platform </th>
                                      <th>Status </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {fiatdepositHistory &&
                                    fiatdepositHistory.length > 0 ? (
                                      fiatdepositHistory.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                              {Moment(item.created_at).format(
                                                "lll"
                                              )}
                                            </td>
                                            <td>{item.Currency}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.Paymentid}</td>
                                            <td>{item.platform}</td>
                                            <td>
                                              <span className="text-green">
                                                Completed
                                              </span>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <td colSpan="7">
                                        <span className="w-100 text-center d-block">
                                          No Deposit History Found!
                                        </span>
                                      </td>
                                    )}
                                  </tbody>
                                </table>
                                {fiatdepositHistory &&
                                fiatdepositHistory.length > 0 ? (
                                  <div className="mt-5">
                                    <Pagination
                                      itemClass="page-item" // add it for bootstrap 4
                                      linkClass="page-link" // add it for bootstrap 4
                                      activePage={fiatdepositcurrentpage}
                                      itemsCountPerPage={fiatdepositrecordpage}
                                      totalItemsCount={fiatdeposittotalpage}
                                      pageRangeDisplayed={fiatdepositpagerange}
                                      onChange={handlepagefiatdeposit}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
