import React, {useEffect} from "react";
import useState from "react-usestateref";
import {Button} from "@material-ui/core";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
// import Arrow from "../img/ArrowRight.svg";
// import glogo from "../img/google_logo.svg";
// import profile_1 from "../img/profile_1.svg";
// import Pagination from "react-js-pagination";
// import profile_4 from "../img/profile_4.svg";
// import profile_5 from "../img/profile_5.svg";
// import profile_6 from "../img/profile_6.svg";
// import validator from "validator";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import Launchhistory from "./Launchpadnowneww";
import {CSVLink, CSVDownload} from "react-csv";
import ReactSwitch from "react-switch";
import OTPInput, {ResendOTP} from "otp-input-react";

import {
  setAuthToken,
  getAuthToken,
  removeAuthToken,
} from "../core/lib/localStorage";

import {setAuthorization, removeAuthorization} from "../core/service/axios";
import Moment from "moment";

function Home() {
  const options = ["one", "two", "three"];
  Moment.locale("en");

  const initialFormValue = {
    oldpassword: "",
    password: "",
    confirmPassword: "",
  };

  const profileForm = {
    email: "",
    username: "",
    mobileNumber: "",
  };
  const [OTP, setOTP] = useState("");
  const [profileData, setprofileData] = useState("");
  const [sessionHistory, setsessionHistory] = useState([]);
  const [twofaHistory, set2faHistory] = useState([]);
  // const [notification, Setnotification] = useState([]);
  // const [notifyCurrentPage, setnotifyCurrentPage] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [passwordValidate, setpasswordValidate, passwordValidateref] = useState(
    false
  );
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  // const [oldpassworddValidate, setoldpassworddValidate] = useState(true);
  const [validationnErr, setvalidationnErr] = useState();
  const [oldpassvalidate, setoldpassvalidate, oldpassvalidateref] = useState(
    false
  );
  const [passconfNotmatch, setpassconfNotmatch] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [tfaDetails, setTfaDetails] = useState({});
  const [tfaCode, setTfaCode] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [addDetails, setaddDetails] = useState({});
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [depositHistory, setdepositHistory] = useState([]);
  const [p2pOrders, setp2pOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tfacurrentPage, settfaCurrentPage] = useState(1);
  const [launchcurrentpage, setlaunchcurrentpage] = useState(1);
  const [transferhistorylist, setTransferhistorylist] = useState([]);
  const [transfercurrentPage, settransferCurrentPage] = useState(1);
  const [transfertotalpage, settransfertotalpage] = useState(0);
  const [transfercsvfile, Settransfercsvfile, transfercsvfileref] = useState(
    []
  );
  const [totalPage, setTotalpages] = useState(0);
  const [tfatotalPage, settfaTotalpages] = useState(0);
  const [notifytotalpage, Setnotifytotalpage] = useState(0);
  const [launchtotalpage, setlaunchtotalpage] = useState(0);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [passHidnew, setPasshidenew] = useState(false);
  const [inputTypenew, setinputTypenew] = useState("password");
  const navigate = useNavigate();
  // const [records, setrecords] = useState([]);
  // const [p2pcurrentpage, setp2pcurrentpage] = useState(1);
  const [p2ptotalpage, setp2pTotalpages] = useState(0);
  // const [depositcurrentpage, setdepositcurrentpage] = useState(1);
  // const [deposittotalpage, setdepositTotalpages] = useState(0);

  // const [withdrawcurrentpage, setwithdrawcurrentpage] = useState(1);
  // const [withdrawtotalpage, setwithdrawTotalpages] = useState(0);

  // const [p2porderHistory, setp2porderHistory] = useState([]);
  // const [p2phistorycurrentpage, setp2phistorycurrentpage] = useState(1);
  // const [giftcurrentpage, setgiftcurrentpage] = useState(1);
  // const [gifttotalpage, Setgifttotalpage] = useState(0);

  // const [p2phistorytotalpage, setp2phistoryTotalpages] = useState(0);
  // const [gifthistory, Setgifthistory] = useState([]);

  const {oldpassword, password, confirmPassword} = formValue;

  const [profileformValue, setprofileFormValue] = useState(profileForm);

  const data = {
    APcode: "",
    changeAPcode: "",
    Status: "",
  };

  const [formData, setformData] = useState(data);

  const {email, username, mobileNumber} = profileformValue;

  const [usernamevalidate, setusernamevalidate, usernamevalidateref] = useState(
    false
  );
  const [mobilevalidate, setmobilevalidate, mobilevalidateref] = useState(
    false
  );
  const [profileLoader, setprofileLoader] = useState(false);
  // const [csvfile, Setcsvfile] = useState([]);
  // const [tfacsvfile, Settfacsvfile] = useState([]);
  // const [launchcsvfile, Setlaunchcsvfile] = useState([]);
  // const [testlaunchcsvfile, Settestlaunchcsvfile] = useState([]);
  // const [p2pcsvfile, Setp2pcsvfile] = useState([]);
  const [testp2pcsvfile, Settestp2pcsvfile] = useState([]);
  // const [p2phistorycsvfile, Sethistoryp2pcsvfile] = useState([]);
  // const [testp2phistorycsvfile, Settesthistoryp2pcsvfile] = useState([]);
  const [testtfacsvfile, Settesttfacsvfile] = useState([]);
  const [testcsvfile, Settestcsvfile] = useState([]);
  // const [withdrawcsvfile, Setwithdrawcsvfile] = useState([]);
  const [testwithdrawcsvfile, Settestwithdrawcsvfile] = useState([]);

  // const [Depositcsvfile, SetDepositcsvfile] = useState([]);
  // const [TestDepositcsvfile, SetTestDepositcsvfile] = useState([]);

  const handleChange = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    setFormValue(formData);
  };

  const handleChange_profile = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...profileformValue, ...{[name]: value}};
    setprofileFormValue(formData);
  };
const [sideLoader,setSiteLoader]=useState(true);
  const getProfile = async (page) => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      setSiteLoader(true)
      var resp = await getMethod(data);
      setSiteLoader(false)
      if (resp.status) {
        
        setprofileData(resp.data);
        console.log(resp.data, "===-=-=-=resp.data-=-");
        profileformValue.username = resp.data.username;
        profileformValue.email = resp.data.email;
        profileformValue.mobileNumber = resp.data.mobileNumber;
        localStorage.setItem("eligibleforEarn", resp.data.kycstatus);
      }

      var payload = {
        perpage: 5,
        page: page,
        tfaperpage: 5,
        tfapage: page,
      };
      var data = {
        apiUrl: apiService.getSessionHisotry,
        payload: payload,
      };

      var resp = await postMethod(data);
      // console.log(resp, "|||||||||||||||");
      if (resp.status) {
        setsessionHistory(resp.logindata.data);
        set2faHistory(resp.data.data);

        Settestcsvfile(resp.csvData);
        Settesttfacsvfile(resp.tfadata);
        setTotalpages(resp.logindata.total);
        settfaTotalpages(resp.data.total);
        // console.log(resp.data.total, "=-=-resp.data.total-==-=");
      }
    } catch (error) {}
  };

  // const twoFAcsv = async () => {
  //   Settfacsvfile(testtfacsvfile);
  // };

  // const csv = async () => {
  //   Setcsvfile(testcsvfile);
  //   // console.log(testcsvfile, "testcsvfile");
  // };

  async function fetchwithdrawcsv() {
    var data = {
      apiUrl: apiService.withdrawcsvfile,
    };

    var resp = await postMethod(data);
    await Settestwithdrawcsvfile(resp.data);
    // console.log(testwithdrawcsvfile, "=-=-=-=-popopo-=-==--=---");
    // console.log(resp.data, "=-=-=-=-popopo-=-==--=---");
  }

  // const withdrawcsv = async () => {
  //   // console.log("=-=-=-=-popopo-=-==--=---");
  //   await Setwithdrawcsvfile(testwithdrawcsvfile);
  // };
  // async function fetchdepositcsv() {
  //   var data = {
  //     apiUrl: apiService.Depositfile,
  //   };
  //   var resp = await postMethod(data);
  //   await SetTestDepositcsvfile(resp.data);
  // console.log(resp.data, "withdrawresponse");

  // console.log(resp, "withdrawresponse");
  // }

  // const Depositcsv = async () => {
  //   await SetDepositcsvfile(TestDepositcsvfile);
  // };
  // const notifyrecordPerPage = 5;

  // const notifypageRange = 5;
  // const notify = async (page) => {
  //   var payload = {
  //     perpage: 5,
  //     page: page,
  //   };

  //   var Notification = {
  //     apiUrl: apiService.getnotification,
  //     payload: payload,
  //   };
  //   var resp = await postMethod(Notification);
  //   if (resp.status) {
  //     Setnotification(resp.data.data);
  //     // console.log(resp.data.data, "=-=-resp.data.data-==-=");

  //     Setnotifytotalpage(resp.data.total);
  //     // console.log(resp.data.total, "=-=resp.data.total=-=-=");
  //   } else {
  //   }
  // };
  // const handlenotifyPageChange = (pageNumber) => {
  //   notify(pageNumber);
  //   setnotifyCurrentPage(pageNumber);
  // };
  // const getdepositHistory = async (page) => {
  //   var obj = {
  //     apiUrl: apiService.deposit_history,
  //     payload: { FilPerpage: 5, FilPage: page },
  //   };
  //   var deposit_history_list = await postMethod(obj);
  //   if (deposit_history_list) {
  //     setdepositHistory(deposit_history_list.result);
  //     SetTestDepositcsvfile(deposit_history_list.depositcsv);
  //     setdepositTotalpages(deposit_history_list.pages);
  //   }
  // };

  // const depositrecordpage = 5;
  // const depositpagerange = 5;
  // const handlepagedeposit = (page) => {
  //   // console.log(page, "==-=-=-p2ppage==-==-=");
  //   getdepositHistory(page);
  //   setdepositcurrentpage(page);
  // };

  // const getwithdrawHistory = async (page) => {
  //   var data = {
  //     apiUrl: apiService.withdraw_history,
  //     payload: { FilPerpage: 5, FilPage: page },
  //   };
  //   var withdraw_history_list = await postMethod(data);
  //   if (withdraw_history_list) {
  //     setwithdrawHistory(withdraw_history_list.result);
  //     setwithdrawTotalpages(withdraw_history_list.pages);
  //   }
  // };

  // const withdrawrecordpage = 5;
  // const withdrawpagerange = 5;
  // const handlepagewithdraw = (page) => {
  //   // console.log(page, "==-=-=-p2ppage==-==-=");
  //   getwithdrawHistory(page);
  //   setwithdrawcurrentpage(page);
  // };

  const getp2pOrders = async (page) => {
    var data = {
      apiUrl: apiService.p2pOrders,
      payload: {FilPerpage: 5, FilPage: page},
    };
    var p2p_orders_list = await postMethod(data);
    // console.log("p2p_orders_list===", p2p_orders_list.returnObj.Message);
    if (p2p_orders_list.status) {
      setp2pOrders(p2p_orders_list.returnObj.Message);
      Settestp2pcsvfile(p2p_orders_list.p2porder);

      setp2pTotalpages(p2p_orders_list.returnObj.total);
    }
  };
  // const p2pordercsv = async () => {
  //   Setp2pcsvfile(testp2pcsvfile);
  // };
  // const p2phistorycsv = async () => {
  //   Sethistoryp2pcsvfile(testp2phistorycsvfile);
  //   // console.log(testp2phistorycsvfile, "==-=-=-testp2phistorycsvfile==-==-=");
  // };

  // const p2precordpage = 5;
  // const p2ppagerange = 5;
  // const handlepagep2p = (p2ppage) => {
  //   // console.log(p2ppage, "==-=-=-p2ppage==-==-=");
  //   getp2pOrders(p2ppage);
  //   setp2pcurrentpage(p2ppage);
  // };
  // const giftrecordpage = 5;
  // const giftpagerange = 5;

  // const handlepagegifthistory = (page) => {
  //   // console.log(page, "page");
  //   getgifthistory(page);
  //   setgiftcurrentpage(page);
  // };

  // const getgifthistory = async (page) => {
  //   var obj = {
  //     FilPerpage: 5,
  //     FilPage: page,
  //   };
  //   // console.log(obj, "---------------");
  //   var data = {
  //     apiUrl: apiService.giftcardhistory,
  //     payload: obj,
  //   };
  //   var giftcardhistory = await postMethod(data);
  //   // console.log(giftcardhistory, "giftcardhistory");
  //   // console.log(giftcardhistory.Message);
  //   Setgifthistory(giftcardhistory.Message.Message);
  //   Setgifttotalpage(giftcardhistory.Message.total);
  //   // console.log(giftcardhistory.Message.total);
  // };

  // const getp2pHistory = async (page) => {
  //   var data = {
  //     apiUrl: apiService.p2pHistory,
  //     payload: { FilPerpage: 5, FilPage: page },
  //   };
  //   var p2p_orders_history = await postMethod(data);
  //   // console.log("p2p_orders_history===", p2p_orders_history.returnObj.Message);
  //   if (p2p_orders_history.status) {
  //     setp2porderHistory(p2p_orders_history.returnObj.Message);
  //     Settesthistoryp2pcsvfile(p2p_orders_history.p2parray);

  //     // console.log(
  //     //   p2p_orders_history.p2parray,
  //     //   "==-=-=-p2p_orders_history.p2parray==-==-="
  //     // );

  //     // console.log(testp2phistorycsvfile, "==-=-=-testp2phistorycsvfile==-==-=");

  //     setp2phistoryTotalpages(p2p_orders_history.returnObj.total);
  //   }
  // };

  // const p2phistoryrecordpage = 5;
  // const p2phistorypagerange = 5;
  // const handlepagep2p_history = (p2ppage) => {
  //   // console.log(p2ppage, "==-=-=-p2ppage==-==-=");
  //   getp2pHistory(p2ppage);
  //   setp2phistorycurrentpage(p2ppage);
  // };

  // const launchrecordpage = 5;
  // const launchpagerange = 5;
  // const handlepage = (launchpage) => {
  //   // console.log(launchpage, "==-=-=-launchpage==-==-=");
  //   getLaunchpadHisoty(launchpage);
  //   setlaunchcurrentpage(launchpage);
  // };
  // const recordPerPage = 5;
  // // total number of the records
  // // const totalRecords = 15;
  // // range of pages in paginator
  // const pageRange = 5;

  // const tfarecordPerPage = 5;
  // const tfapageRange = 5;

  // const handlePageChange = (pageNumber, page) => {
  //   getProfile(pageNumber);
  //   setCurrentPage(pageNumber);

  //   // console.log(pageNumber,'=-=--=-=-pageNumber-=-')
  //   // setchoosepage()
  // };

  // const tfahandlePageChange = (pageNumber) => {
  //   getProfile(pageNumber);
  //   settfaCurrentPage(pageNumber);
  // };
  // const getLaunchpadHisoty = async (page) => {
  //   try {
  //     var payload = {
  //       perpage: 5,
  //       page: page,
  //     };
  //     // console.log(payload, "-=-=paylaod=-=-=");
  //     var data = {
  //       apiUrl: apiService.lauchPadHistory,
  //       payload: payload,
  //     };
  //     // console.log(data, "-=-=data-=-=");
  //     var resp = await postMethod(data);

  //     if (resp) {
  //       // console.log(resp);
  //       var data = resp.data.data;
  //       // console.log(data, "--===data======-");
  //       setrecords(data);
  //       Settestlaunchcsvfile(resp.launchcsvdata);
  //       // console.log(resp.launchcsvdata, "-=-=data.launchcsvdata-=-=-");

  //       var datas = resp.data.total;
  //       // console.log(datas, "-=-=datas-=-=-");
  //       setlaunchtotalpage(datas);
  //       // console.log(resp.data.total, "-=-=-=data.total=-=-=");
  //     }
  //   } catch (error) { }
  // };
  // const launchcsv = async () => {
  //   Setlaunchcsvfile(testlaunchcsvfile);
  // };
  const validate = async (values) => {
    const errors = {};
    // console.log(
    //   values.oldpassword === values.password,
    //   "[values.oldpassword === values.password"
    // );
    if (values.oldpassword == "") {
      errors.oldpassword = "Old Password is required";
      setoldpassvalidate(true);
    } else if (
      values.oldpassword.length < 8 ||
      values.oldpassword.length > 45
    ) {
      setoldpassvalidate(true);
      errors.oldpassword = "shouldnot below 8 above 45 letters";
    } else if (values.oldpassword == values.password) {
      errors.oldpassword = "Old Password and New Passwords are same";
      setoldpassvalidate(true);
    } else {
      setoldpassvalidate(false);
    }

    if (values.password == "") {
      setpasswordValidate(true);
      errors.password = "Password is required";
    } else if (values.password.length < 8 || values.password.length > 45) {
      setpasswordValidate(true);
      errors.password = "shouldnot below 8 above 45 letters";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter atone digit character";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character";
    } else {
      setpasswordValidate(false);
    }

    if (values.confirmPassword == "") {
      errors.confirmPassword = "Confirm Password is required";
      setconfirmPasswordValidate(true);
    } else if (values.confirmPassword != values.password) {
      setconfirmPasswordValidate(true);
      errors.confirmPassword = "Password and Confirm Password does not match";
    } else if (
      values.confirmPassword.length < 8 ||
      values.confirmPassword.length > 45
    ) {
      setconfirmPasswordValidate(true);
      errors.confirmPassword =
        "confirmPassword is required and shouldnot below 8 above 45 letters";
    } else {
      setconfirmPasswordValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const profile_validate = async (values) => {
    const errors = {};
    if (values.username == "") {
      setusernamevalidate(true);
      errors.username = "username is required";
    } else {
      setusernamevalidate(false);
    }

    if (values.mobileNumber == "") {
      errors.mobileNumber = "Mobile Number is a required field";
      setmobilevalidate(true);
    } else if (
      values.mobileNumber.length < 5 ||
      values.mobileNumber.length > 15
    ) {
      setmobilevalidate(true);
      errors.mobileNumber = "mobile must  5 to 15 characters!";
    } else {
      setmobilevalidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    fetchwithdrawcsv();
    // fetchdepositcsv();
    getProfile();
    FindData();
    // notify();
    fetchTfaData();
    // getLaunchpadHisoty();
    getp2pOrders(1);
    // getdepositHistory(1);
    // getwithdrawHistory(1);
    // getp2pHistory(1);
    // getgifthistory(1);
    transferhistory(1);
    // console.log(checked);
  }, [0]);

  const formSubmit = async (payload) => {
    // console.log(formValue, "=-=--=--=-=-=-=-=-=-=-=-=-=-=-=");
    // return
    validate(formValue);
    // console.log(
    //   confirmPasswordValidateref.current,
    //   "==-=-=-=-cpmfirmpasswordValidate=-==-=-==-"
    // );
    // console.log(
    //   passwordValidateref.current,
    //   "==-=-=-=-oldpassvalidate=-==-=-==-"
    // );
    // console.log(
    //   passwordValidateref.current,
    //   "==-=-=-=-passwordValidateref=-==-=-==-"
    // );
    if (
      confirmPasswordValidateref.current == false &&
      passwordValidateref.current == false &&
      oldpassvalidateref.current == false
    ) {
      // console.log("==-=-=-=-if=-==-=-==-");
      // return false
      var obj = {
        oldPass: formValue.oldpassword,
        password: formValue.password,
        cpass: formValue.confirmPassword,
      };

      var data = {
        apiUrl: apiService.changePassword,
        payload: obj,
      };
      // console.log(data, "====data===");
      setbuttonLoader(true);
      var resp = await postMethod(data);
      // console.log(resp.email);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status) {
        await removeAuthorization();
        await removeAuthToken();
        localStorage.clear();
        navigate("/");
        toast.success(resp.Message);
        // window.location.reload(true);
      } else {
        toast.error(resp.Message);
      }
    } else {
    }
  };

  const handleSubmit = async () => {
    setValidationErrors({});
    // return
    if (!tfaCode || tfaCode === "") {
      setValidationErrors({
        tfaCode: "2FA code is required",
      });
    } else {
      // console.log(tfaDetails, "=--=tfaDetails");
      let tfaStatus = tfaDetails;
      // if (tfaDetails?.tfastatus == 0) {
      //   tfaStatus = 1;
      // }

      var data = {
        apiUrl: apiService.changeTfaStatus,
        payload: {
          userToken: tfaCode,
          tfaStatus: tfaStatus,
        },
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        toast.success(resp.Message);
        setTfaCode("");
        fetchTfaData();
        localStorage.setItem("tfa_status", resp.result.tfastatus);
        if (typeof resp?.errors !== "undefined") {
          const isErrorEmpty = Object.keys(resp?.errors).length === 0;
          if (!isErrorEmpty) {
            setValidationErrors(resp?.errors);
          }
        } else {
          //  window.location.reload()
        }
      } else {
        toast.error(resp.Message);
      }
    }
  };

  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getTfaDetials,
      };
      var resp = await getMethod(data);
      setTfaDetails(resp?.data.tfastatus);
      console.log(resp, "tfadetail");
      setaddDetails(resp?.data);
    } catch (error) {}
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const passwordHidenewP = (data) => {
    if (data == "hide") {
      setPasshidenew(true);
      setinputTypenew("text");
    } else {
      setPasshidenew(false);
      setinputTypenew("password");
    }
  };

  // const copy = async (text) => {
  //   navigator.clipboard.writeText(text);
  //   toast.success("Copied");
  // };

  const profileSubmit = async (payload) => {
    profile_validate(profileformValue);
    console.log(mobilevalidateref.current);
    if (
      usernamevalidateref.current == false &&
      mobilevalidateref.current == false
    ) {
      var obj = {
        username: profileformValue.username,
        mobileNumber: profileformValue.mobileNumber,
      };

      var data = {
        apiUrl: apiService.update_profile,
        payload: obj,
      };
      setprofileLoader(true);
      var resp = await postMethod(data);
      if (resp.status) {
        setprofileLoader(false);
        toast.success(resp.message);
        getProfile();
      } else {
        toast.error(resp.message);
      }
    }
  };

  const [otpPage, setotpPage] = useState(true);
  const [codePage, setcodePage] = useState(true);
  const [changeCode, setchangeCode] = useState(false);
  const [Anticode, setAnticode] = useState("");
  const [checked, setChecked] = useState("");
  const [AntiPcode, setAntiPcode] = useState(false);
  const [changeAnticode, setchangeAnticode] = useState(false);
  const [APcodevalied, setAPcodevalied] = useState("");
  const [APcodes, setAPcodes] = useState("");

  const getEandD = async (val) => {
    setChecked(val);
    formData["Status"] = checked;
    var data = {
      apiUrl: apiService.EnableAnticode,
      payload: formData,
    };
    var responce = await postMethod(data);
    if (responce) {
      toast.success(responce.Message);
    } else {
      toast.error(responce.Message);
    }
  };

  const verifyOTP = async () => {
    console.log(formData.APcode, "formData.APcode");
    console.log(formData.changeAPcode, "formData.changeAPcode");
    if (OTP != "") {
      var obj = {
        OTP: OTP,
        changeAPcode: formData.changeAPcode,
        APcode: formData.APcode,
      };
      var data = {
        apiUrl: apiService.verificationOtp,
        payload: obj,
      };
      var responce = await postMethod(data);
      if (responce.Status == true) {
        toast.success(responce.Message);
        // navigate("/profile");
        window.location.reload();
        setchangeCode(true);
        FindData();
      }
    } else {
      toast.error("Enter OTP");
    }
  };

  const getValue = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let values = {...formData, ...{[name]: value}};
    setformData(values);
  };

  const changeAntiCode = async () => {
    condition(formData);
    if (
      formData.changeAPcode != "" &&
      formData.changeAPcode.length > 4 &&
      formData.changeAPcode.length < 21
    ) {
      var data = {
        apiUrl: apiService.changeAntiPhising,
        payload: formData,
      };
      var responce = await postMethod(data);
      if (responce.Status == true) {
        toast.success(responce.Message);
        setcodePage(false);
        console.log(formData.changeAPcode, "formData.changeAPcode");
      } else {
        toast.error(responce.Message);
        setcodePage(true);
      }
    }
  };

  const condition = () => {
    const Err = {};
    if (formData.APcode == "") {
      setAntiPcode(true);
      Err.APcode = "Anti phishing code is required";
    } else if (formData.APcode.length < 4) {
      Err.APcode = "Minimum 4 characters Only allowed";
      setAntiPcode(true);
    } else if (formData.APcode.length > 20) {
      Err.APcode = "Maximum 20 characters Only allowed";
      setAntiPcode(true);
    }

    if (formData.changeAPcode == "") {
      Err.changeAPcode = "Anti phishing code required";
      setchangeAnticode(true);
    } else if (formData.changeAPcode.length < 4) {
      Err.changeAPcode = "Minimum 4 characters Only allowed";
      setchangeAnticode(true);
    } else if (formData.changeAPcode.length > 20) {
      Err.changeAPcode = "Maximum 20 characters Only allowed";
      setchangeAnticode(true);
    }
    setAPcodevalied(Err);
    return Err;
  };

  const createCode = async () => {
    if (APcodes == "") {
      condition(formData);
      if (
        formData.APcode != "" &&
        formData.APcode.length > 4 &&
        formData.APcode.length < 21
      ) {
        formData["Status"] = checked;
        var data = {
          apiUrl: apiService.antiphishingcode,
          payload: formData,
        };
        var responce = await postMethod(data);
        if (responce.Status == true) {
          toast.success(responce.Message);
          setotpPage(false);
        }
      }
    } else {
      toast.error("Already code created");
    }
  };

  const FindData = async () => {
    var data = {
      apiUrl: apiService.findDetails,
      payload: formData,
    };
    var responce = await postMethod(data);
    if (responce.data != null) {
      if (responce.data.APcode != "") {
        var str = responce.data.APcode;
        setAPcodes(str);
        var replaced = str.replace(/.(?=.{4,}$)/g, "*");
        setAnticode(replaced);
        setchangeCode(true);
      } else {
        setchangeCode(false);
      }
      if (responce.data.Status == "false") {
        setchangeCode(true);
        setChecked(true);
      }
      if (responce.data.Status == "true") {
        setchangeCode(true);
        setChecked(false);
      }
    }
  };
  // const transferrecordpage = 5;
  // const transferpagerange = 5;
  // const transferhandlepage = (page) => {
  //   // console.log(launchpage, "==-=-=-launchpage==-==-=");
  //   transferhistory(page);
  //   settransferCurrentPage(page);
  // };
  const transferhistory = async (page) => {
    var payload = {
      perpage: 5,
      page: page,
    };
    var data = {
      apiUrl: apiService.transferhistory,
      payload: payload,
    };
    var responce = await postMethod(data);
    setTransferhistorylist(responce.data.data);
    Settransfercsvfile(responce.data.CSV);
    console.log(transfercsvfileref.current, "transfercsvfile");
    settransfertotalpage(responce.data.total);
    console.log(responce, "responcereaspoijknkj");
  };
  return (
    <div className="">
      <main className="main-content tradepage-bg inner_body_bg padin_button wallet_overview bg-covermain-content tradepage-bg login_body_ bg-cover">
        <Header />

        <div className="container">
          <div className="">
            <div className="">
              <div className="row justify-center">
                <div className="col-lg-12">
                  <div className="">
                    <div className="container profile_section p-0">
                      <div className="row">
                        <div className="col-lg-2 p-0">
                          <ul className="nav nav-tabs">
                            <li className="heading_mwmu">
                              <h4>
                                {" "}
                                <b>Settings</b>{" "}
                              </h4>
                            </li>
                            <li className="active">
                              <a
                                data-toggle="tab"
                                href="#home"
                                className="active"
                              >
                                <img
                                  src={require("../img/New/settings.png")}
                                  className="left_menu_icons"
                                />
                                Settings - Profile
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#menu5">
                                <img
                                  src={require("../img/New/edit.png")}
                                  className="left_menu_icons"
                                />
                                Edit Profile
                              </a>
                            </li>
                            {/* <li>
                              <a data-toggle="tab">
                                <img
                                  src={require("../img/New/vacancy.png")}
                                  className="left_menu_icons"
                                />

                                <Link to="/kyc" className="p-0">
                                  {" "}
                                  KYC{" "}
                                </Link>
                              </a>
                            </li> */}
                            {/* <li>
                              <a data-toggle="tab">
                                <img
                                  src={require("../img/New/payment-method.png")}
                                  className="left_menu_icons"
                                />

                                <Link to="/Bankdetails" className="p-0">
                                  {" "}
                                  Payment Methods{" "}
                                </Link>
                              </a>
                            </li> */}
                            {/* <li>
                              <a data-toggle="tab">
                                <img
                                  src={require("../img/New/payment.png")}
                                  className="left_menu_icons"
                                />

                                <Link to="/buycurrency " className="p-0">
                                  {" "}
                                  Payment Gateway{" "}
                                </Link>
                              </a>
                            </li> */}
                            {/* <li>
                              <a data-toggle="tab">
                                <img
                                  src={require("../img/New/money-transfer.png")}
                                  className="left_menu_icons"
                                />

                                <Link to="/internaltransfer" className="p-0">
                                  {" "}
                                  Internal Transfer{" "}
                                </Link>
                              </a>
                            </li> */}
                            <li className="heading_mwmu">
                              <h4>
                                {" "}
                                <b>Account Security</b>{" "}
                              </h4>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#home1">
                                <img
                                  src={require("../img/New/authentication.png")}
                                  className="left_menu_icons"
                                />
                                2FA
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#menu2">
                                <img
                                  src={require("../img/New/padlock.png")}
                                  className="left_menu_icons"
                                />
                                Change password
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#anti">
                                <img
                                  src={require("../img/New/password.png")}
                                  className="left_menu_icons"
                                />
                                Anti-Phishing Code
                              </a>
                              <br />
                            </li>
                            {/* <li className="heading_mwmu">
                              <h4>
                                {" "}
                                <b>Help</b>{" "}
                              </h4>
                            </li> */}
                            {/* <li>
                              <a data-toggle="tab">
                                <img
                                  src={require("../img/New/customer-service.png")}
                                  className="left_menu_icons"
                                />

                                <Link to="/support" className="p-0">
                                  {" "}
                                  Support{" "}
                                </Link>
                              </a>
                            </li> */}
                            {/* <li>
                              <a data-toggle="tab" href="#home3">
                                <img
                                  src={require("../img/New/active.png")}
                                  className="left_menu_icons"
                                />
                                Notifications
                              </a>
                            </li>
                            <li className="heading_mwmu">
                              <h4>
                                {" "}
                                <b>History</b>{" "}
                              </h4>
                            </li> */}
                            {/* <li>
                              <a data-toggle="tab" href="#transactiondeposit">
                                <img
                                  src={require("../img/New/application.png")}
                                  className="left_menu_icons"
                                />
                                Deposit History
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#transactionwith">
                                <img
                                  src={require("../img/New/application.png")}
                                  className="left_menu_icons"
                                />
                                Withdraw History
                              </a>
                            </li> */}
                            {/* <li>
                              <a data-toggle="tab" href="#LaunchpadHistory">
                                <img
                                  src={require("../img/New/application.png")}
                                  className="left_menu_icons"
                                />
                                Launchpad History
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#menu3">
                                <img
                                  src={require("../img/New/delivery-box.png")}
                                  className="left_menu_icons"
                                />
                                P2P My Orders
                              </a>
                            </li>

                            <li>
                              <a data-toggle="tab" href="#menu4">
                                <img
                                  src={require("../img/New/application.png")}
                                  className="left_menu_icons"
                                />
                                P2P History
                              </a>
                            </li> */}
                            {/* <li>
                              <a data-toggle="tab" href="#menu6">
                                <img
                                  src={require("../img/New/application.png")}
                                  className="left_menu_icons"
                                />
                                Gift History
                              </a>
                            </li> */}
                            {/* <li>
                              <a data-toggle="tab" href="#home2">
                                <img
                                  src={require("../img/New/application.png")}
                                  className="left_menu_icons"
                                />
                                2FA History
                              </a>
                            </li> */}
                            {/* <li>
                              <a data-toggle="tab" href="#home7">
                                <img
                                  src={require("../img/New/application.png")}
                                  className="left_menu_icons"
                                />
                                Sessions & login history
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#home8">
                                <img
                                  src={require("../img/New/application.png")}
                                  className="left_menu_icons"
                                />
                                Internal Transfer History
                              </a>
                            </li> */}
                          </ul>
                        </div>
                        <div className="col-lg-10 pt-3">
                          <div className="cars_bg_ss mt-0">
                            <div className="tab-content">
                              {/* <div
                                id="LaunchpadHistory"
                                className="tab-pane fade in"
                              >
                                <div className="sessions__title">
                                  Launchpad History
                                </div>
                                <div className="card_white_bg p-4">
                                  {records && records.length > 0 ? (
                                    <CSVLink
                                      className="btn btn-primary float-right mr-2 mt-2"
                                      filename="Launchpad History"
                                      onClick={launchcsv}
                                      data={launchcsvfile}
                                    >
                                      <i
                                        class="fa fa-download"
                                        aria-hidden="true"
                                      ></i>
                                    </CSVLink>
                                  ) :
                                    (
                                      <div className="btn yellow float-right mr-2 mt-2">
                                        <i
                                          class="fa fa-download"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    )
                                  }

                                  <div className="table-responsive transaction">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">S.No</th>
                                          <th scope="col">Currency</th>
                                          <th scope="col">Total Amount</th>
                                          <th scope="col">Purchase ID</th>
                                          <th scope="col">Fees</th>
                                          <th scope="col">Sell Currency</th>
                                          <th scope="col">Purchase Date</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {records && records.length > 0 ? (
                                          records.map((item, i) => {
                                            var startdate = Moment(
                                              item.createdDate
                                            ).format("lll");
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>{item.tokenSymbol}</td>
                                                <td>
                                                  {item.tokenAmount}{" "}
                                                  {item.tokenSymbol}
                                                </td>
                                                <td>{item.orderid}</td>
                                                <td>
                                                  {parseFloat(
                                                    item.fees
                                                  ).toFixed(8)}{" "}
                                                </td>
                                                <td>{item.sellCurrency}</td>
                                                <td>{startdate}</td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <tr>
                                            <td colSpan="7">
                                              <span className="w-100 text-center d-block">
                                                No Launchpad orders found!
                                              </span>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <Pagination
                                    itemClass="page-item" // add it for bootstrap 4
                                    linkClass="page-link" // add it for bootstrap 4
                                    activePage={launchcurrentpage}
                                    itemsCountPerPage={launchrecordpage}
                                    totalItemsCount={launchtotalpage}
                                    pageRangeDisplayed={launchpagerange}
                                    onChange={handlepage}
                                  />
                                </div>
                              </div> */}

                             
                              <div
                              id="home"
                              className="tab-pane fade in active show"
                            >
                              <div className="row">
                                <div className="col-lg-6">
                                  {/* <div className="name_section_profile">
                                    <h1> Developer </h1>
                                    <button className="color_green">
                                      Level 2 verified
                                    </button>
                                  </div> */}
                                </div>
                                <div className="col-lg-6">
                                  <div className="select"></div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-12">
                                
                              {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
                              <>
<div className="bg_ash bg_card_section bg_card_super mt-0">
                                    <div className="row">
                                      <div className="col-lg-4">
                                        <div className="title_section_funds pl-3">
                                          <h2>Profile Details</h2>
                                        </div>
                                      </div>
                                      
                                      <div className="col-lg-8"></div>
                                    </div>
                                  </div>

                                  <div className="futer_section bg_card_super">
                                    {/* <p className="tilet_head">level 1</p> */}
                                    <div className="flex_container">
                                      <p>First Name</p>
                                      <div>
                                        <span>
                                          {" "}
                                          {profileData &&
                                            profileData.firstname}{" "}
                                          {/* {profileData.lastname}{" "} */}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex_container">
                                      <p>Last Name</p>
                                      <div>
                                        <span>
                                          {" "}
                                          {profileData &&
                                            profileData.lastname}{" "}
                                          {/* {profileData.lastname}{" "} */}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex_container">
                                      <p>Email</p>
                                      <div>
                                        <span>
                                          {" "}
                                          {profileData &&
                                            profileData.email}{" "}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex_container">
                                      <p>Mobile</p>
                                      <div>
                                        <span>
                                          {" "}
                                          {profileData &&
                                            profileData.mobileNumber}{" "}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex_container">
                                      <p>KYC Status</p>
                                      <div>
                                        <span>
                                          {" "}
                                          {profileData &&
                                          profileData.kycstatus == "1" ? (
                                            <span
                                              className="status_done"
                                              style={{color: "green"}}
                                            >
                                              Verified
                                            </span>
                                          ) : profileData.kycstatus == "0" ? (
                                            <span
                                              className="status_pending"
                                              style={{color: "block"}}
                                            >
                                              Not Upload
                                            </span>
                                          ) : profileData.kycstatus == "2" ? (
                                            <span
                                              className="status_pending"
                                              style={{color: "orange"}}
                                            >
                                              Pending
                                            </span>
                                          ) : (
                                            <span
                                              className="status_rejected"
                                              style={{color: "red"}}
                                            >
                                              {" "}
                                              Rejected{" "}
                                            </span>
                                          )}{" "}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="flex_container">
                                      <p>2FA Status</p>
                                      <div>
                                        <span>
                                          {" "}
                                          {profileData &&
                                          profileData.tfastatus == "0" ? (
                                            <span
                                              className="status_done"
                                              style={{color: "red"}}
                                            >
                                              Disable
                                            </span>
                                          ) : (
                                            <span
                                              className="status_done"
                                              style={{color: "green"}}
                                            >
                                              Enable
                                            </span>
                                          )}{" "}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="flex_container">
                                      <p>Account Status</p>
                                      <div>
                                        <span>
                                          {" "}
                                          {profileData &&
                                          profileData.verifyEmail == "0" ? (
                                            <span style={{color: "red"}}>
                                              {" "}
                                              DeActive{" "}
                                            </span>
                                          ) : (
                                            <span
                                              className="status_done"
                                              style={{color: "green"}}
                                            >
                                              {" "}
                                              Active{" "}
                                            </span>
                                          )}{" "}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  </>
}
                                </div>
                              </div>

                            </div>
                              
                            

                              {/* <div id="home2" className="tab-pane fade in">
                                <div className="profile__wrapper">
                                  <div className="sessions">
                                    <div className="sessions__section">
                                      <div className="sessions__title">
                                        2FA history
                                      </div>
                                      <div className="card_white_bg p-4">
                                        {twofaHistory &&
                                          twofaHistory.length > 0 ? (
                                          <CSVLink
                                            className="btn btn-primary float-right mr-2 mt-2"
                                            filename="2FA History"
                                            onClick={twoFAcsv}
                                            data={tfacsvfile}
                                          >
                                            <i
                                              class="fa fa-download"
                                              aria-hidden="true"
                                            ></i>
                                          </CSVLink>) :
                                          (
                                            <div className="btn yellow float-right mr-2 mt-2">
                                              <i
                                                class="fa fa-download"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          )
                                        }

                                        <div className="sessions__table">
                                          <div className="sessions__row">
                                           
                                            <div className="sessions__col">
                                              Date / time
                                            </div>
                                            <div className="sessions__col">
                                              IP address
                                            </div>
                                            <div className="sessions__col">
                                              Browser
                                            </div>
                                            <div className="sessions__col">
                                              Status
                                            </div>
                                          </div>

                                          {twofaHistory &&
                                            twofaHistory.length > 0 ? (
                                            twofaHistory.map((item, i) => {
                                              return (
                                                <div className="sessions__row">
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {Moment(
                                                        item.createdDate
                                                      ).format("lll")}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {item.ipAddress}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {item.browser}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {item.Status}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <td colSpan="7">
                                              <span className="w-100 text-center d-block">
                                                No History Found!
                                              </span>
                                            </td>
                                          )}
                                        </div>
                                        <Pagination
                                          itemClass="page-item" // a
                                          dd
                                          it
                                          for
                                          bootstrap
                                          linkClass="page-link" // add it for bootstrap 4
                                          activePage={tfacurrentPage}
                                          itemsCountPerPage={tfarecordPerPage}
                                          totalItemsCount={tfatotalPage}
                                          pageRangeDisplayed={tfapageRange}
                                          onChange={tfahandlePageChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              {/* <div id="home7" className="tab-pane fade in">
                                <div className="profile__wrapper">
                                  <div className="sessions">
                                    <div className="sessions__section">
                                      <div className="sessions__title">
                                        Login History
                                      </div>
                                      <div className="card_white_bg p-4">
                                        {sessionHistory &&
                                          sessionHistory.length > 0 ? (
                                          <CSVLink
                                            className="btn btn-primary float-right ml-2 mt-2"
                                            filename="Login History"
                                            onClick={csv}
                                            data={csvfile}
                                          >
                                            <i
                                              class="fa fa-download"
                                              aria-hidden="true"
                                            ></i>
                                          </CSVLink>) :
                                          (
                                            <div className="btn yellow float-right mr-2 mt-2">
                                              <i
                                                class="fa fa-download"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          )
                                        }

                                        <div className="sessions__table">
                                          <div className="sessions__row">
                                            <div className="sessions__col">
                                              S.No
                                            </div>
                                            <div className="sessions__col">
                                              Date / time
                                            </div>
                                            <div className="sessions__col">
                                              IP address
                                            </div>
                                            <div className="sessions__col">
                                              Device
                                            </div>
                                            <div className="sessions__col">
                                              Platform
                                            </div>
                                          </div>

                                          {sessionHistory &&
                                            sessionHistory.length > 0 ? (
                                            sessionHistory.map((item, i) => {
                                              return (
                                                <div className="sessions__row">
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {i + 1}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {Moment(
                                                        item.createdDate
                                                      ).format("lll")}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {item.ipAddress}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {item.browser}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {item.platform}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {item.Status}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <td colSpan="7">
                                              <span className="w-100 text-center d-block">
                                                No History Found!
                                              </span>
                                            </td>
                                          )}
                                        </div>
                                        <Pagination
                                          itemClass="page-item" // add it for bootstrap 4
                                          linkClass="page-link" // add it for bootstrap 4
                                          activePage={currentPage}
                                          itemsCountPerPage={recordPerPage}
                                          totalItemsCount={totalPage}
                                          pageRangeDisplayed={pageRange}
                                          onChange={handlePageChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              {/* <div id="home8" className="tab-pane fade in">
                                <div className="profile__wrapper">
                                  <div className="sessions">
                                    <div className="sessions__section">
                                      <div className="sessions__title">
                                        Internal Transfer History
                                      </div>
                                      <div className="card_white_bg p-4">
                                        {transferhistorylist &&
                                          transferhistorylist.length > 0 ? (<CSVLink
                                            className="btn btn-primary float-right ml-2 mt-2"
                                            filename="Transfer History"
                                            // onClick={csv}
                                            data={transfercsvfileref.current}
                                          >
                                            <i
                                              class="fa fa-download"
                                              aria-hidden="true"
                                            ></i>
                                          </CSVLink>) :
                                          (
                                            <div className="btn yellow float-right mr-2 mt-2">
                                              <i
                                                class="fa fa-download"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          )
                                        }

                                        <div className="sessions__table">
                                          <div className="sessions__row">
                                            <div className="sessions__col">
                                              S.No
                                            </div>
                                            <div className="sessions__col">
                                              Date / time
                                            </div>
                                            <div className="sessions__col">
                                              Currency
                                            </div>
                                            <div className="sessions__col">
                                              From Wallet
                                            </div>
                                            <div className="sessions__col">
                                              To Wallet
                                            </div>
                                            <div className="sessions__col">
                                              Amount
                                            </div>
                                            <div className="sessions__col">
                                              Fees
                                            </div>
                                          </div>

                                          {transferhistorylist &&
                                            transferhistorylist.length > 0 ? (
                                            transferhistorylist.map(

                                              (item, i) => {
                                                const fee = parseFloat(item.fees)
                                                const fees = fee.toFixed(9);
                                                return (
                                                  <div className="sessions__row">
                                                    <div className="sessions__col">
                                                      <div className="sessions__content">
                                                        {i + 1}
                                                      </div>
                                                    </div>
                                                    <div className="sessions__col">
                                                      <div className="sessions__content">
                                                        {Moment(
                                                          item.createdDate
                                                        ).format("lll")}
                                                      </div>
                                                    </div>
                                                    <div className="sessions__col">
                                                      <div className="sessions__content">
                                                        {item.currency}
                                                      </div>
                                                    </div>
                                                    <div className="sessions__col">
                                                      <div className="sessions__content">
                                                        {item.fromWallet}
                                                      </div>
                                                    </div>
                                                    <div className="sessions__col">
                                                      <div className="sessions__content">
                                                        {item.toWallet}
                                                      </div>
                                                    </div>
                                                    <div className="sessions__col">
                                                      <div className="sessions__content">
                                                        {item.amount}
                                                      </div>
                                                    </div>
                                                    <div className="sessions__col">
                                                      <div className="sessions__content">
                                                        {fees}
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )
                                          ) : (
                                            <td colSpan="7">
                                              <span className="w-100 text-center d-block">
                                                No History Found!
                                              </span>
                                            </td>
                                          )}
                                        </div>
                                        <Pagination
                                          itemClass="page-item" // add it for bootstrap 4
                                          linkClass="page-link" // add it for bootstrap 4
                                          activePage={transfercurrentPage}
                                          itemsCountPerPage={transferrecordpage}
                                          totalItemsCount={transfertotalpage}
                                          pageRangeDisplayed={transferpagerange}
                                          onChange={transferhandlepage}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}

                              {/* <div
                                id="transactiondeposit"
                                className="tab-pane fade in"
                              >
                                <div className="table-responsive transaction">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <div id="home2" className="tab-pane fade in">
                                          <div className="profile__wrapper">
                                            <div className="sessions">
                                              <div className="sessions__section">
                                                <th scope="col">S.No</th>

                                                <th scope="col"> Date / time</th>
                                                <th scope="col"> IP address</th>
                                                <th scope="col"> Device</th>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {sessionHistory &&
                                          sessionHistory.map((item, i) => {
                                            return (
                                              <tr>
                                                 <td>{i + 1}</td>
                                                 <td>{Moment( item.createdDate).format( "lll")}</td>
                                                 <td>{item.ipAddress} </td>
                                                 <td> {item.platform}</td>
                                              </tr>
                                            )}
                                          )}

                                    </tbody>
                                  </table>
                                </div> */}

                              {/* <div
                                id="transactiondeposit"
                                className="tab-pane fade in"
                              >
                                <div className="sessions__title">
                                  Deposit History
                                </div>
                                <div className="card_white_bg p-4">
                                  {depositHistory &&
                                    depositHistory.length > 0 ?
                                    (
                                      <CSVLink
                                        className="btn btn-primary float-right mr-2 mt-2"
                                        filename="Deposit History"
                                        onClick={Depositcsv}
                                        data={Depositcsvfile}
                                      >
                                        <i
                                          class="fa fa-download"
                                          aria-hidden="true"
                                        ></i>
                                      </CSVLink>
                                    ) : (
                                      <div className="btn yellow float-right mr-2 mt-2">
                                        <i
                                          class="fa fa-download"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    )}

                                  <div className="table-responsive transaction">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">S.No</th>
                                          <th scope="col">Date</th>
                                          <th scope="col">Time</th>
                                          <th scope="col">Currency</th>
                                          <th scope="col">Amount</th>
                                          <th scope="col">Transaction Id</th>
                                          <th scope="col">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {depositHistory &&
                                          depositHistory.length > 0 ? (
                                          depositHistory.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  {Moment(item.date).format(
                                                    "YYYY-MM-DD"
                                                  )}
                                                </td>
                                                <td>
                                                  {Moment(item.date).format(
                                                    "hh:mm a"
                                                  )}
                                                </td>
                                                <td>{item.currencySymbol}</td>
                                                <td>
                                                  {parseFloat(
                                                    item.amount
                                                  ).toFixed(8)}
                                                </td>
                                                <td>
                                                  <div className="copy_iconss">
                                                    <span className="text_wrapaa">
                                                      {item.txnid}
                                                    </span>
                                                    <div className="copyicon">
                                                      <span
                                                        onClick={() =>
                                                          copy(item.txnid)
                                                        }
                                                      >
                                                        <i
                                                          class="fa fa-clone"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <span className="text-green">
                                                    Completed
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <td colSpan="7">
                                            <span className="w-100 text-center d-block">
                                              No Deposit History Found!
                                            </span>
                                          </td>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <Pagination
                                    itemClass="page-item" // add it for bootstrap 4
                                    linkClass="page-link" // add it for bootstrap 4
                                    activePage={depositcurrentpage}
                                    itemsCountPerPage={depositrecordpage}
                                    totalItemsCount={deposittotalpage}
                                    pageRangeDisplayed={depositpagerange}
                                    onChange={handlepagedeposit}
                                  />
                                </div>
                              </div> */}

                              {/* //----------Withdraw-----------------------// */}

                              {/* <div
                                id="transactionwith"
                                className="tab-pane fade in"
                              >
                                <div className="sessions__title">
                                  Withdraw History
                                </div>
                                <div className="card_white_bg p-4">
                                  {withdrawHistory &&
                                    withdrawHistory.length > 0 ? (
                                    <CSVLink
                                      className="btn btn-primary float-right mr-2 mt-2"
                                      filename="Withdraw History"
                                      onClick={withdrawcsv}
                                      data={withdrawcsvfile}
                                    >
                                      <i
                                        class="fa fa-download"
                                        aria-hidden="true"
                                      ></i>
                                    </CSVLink>
                                  ) : (
                                    <div className="btn yellow float-right mr-2 mt-2">
                                      <i
                                        class="fa fa-download"
                                        aria-hidden="true"
                                      ></i>
                                    </div>


                                  )}

                                  <div className="table-responsive transaction">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">S.No</th>
                                          <th scope="col">Date</th>
                                          <th scope="col">Time</th>
                                          <th scope="col">Currency</th>
                                          <th scope="col">Amount</th>
                                          <th scope="col">Transaction Id</th>
                                          <th scope="col">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {withdrawHistory &&
                                          withdrawHistory.length > 0 ? (
                                          withdrawHistory.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  {Moment(
                                                    item.created_at
                                                  ).format("YYYY-MM-DD")}
                                                </td>
                                                <td>
                                                  {Moment(
                                                    item.created_at
                                                  ).format("hh:mm a")}
                                                </td>
                                                <td>{item.currency}</td>
                                                <td>
                                                  {parseFloat(
                                                    item.amount
                                                  ).toFixed(8)}
                                                </td>
                                                <td>
                                                  <div className="copy_iconss">
                                                    <span className="text_wrapaa">
                                                      {item.txn_id}
                                                    </span>
                                                    <div className="copyicon">
                                                      <span
                                                        onClick={() =>
                                                          copy(item.txn_id)
                                                        }
                                                      >
                                                        <i
                                                          class="fa fa-clone"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  {item.status ==
                                                    "Completed" ? (
                                                    <span className="text-green">
                                                      {item.status}
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {item.status !==
                                                    "Completed" ? (
                                                    <span className="text-red">
                                                      {item.status}
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <td colSpan="7">
                                            <span className="w-100 text-center d-block">
                                              No Withdraw History Found!
                                            </span>
                                          </td>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <Pagination
                                    itemClass="page-item" // add it for bootstrap 4
                                    linkClass="page-link" // add it for bootstrap 4
                                    activePage={withdrawcurrentpage}
                                    itemsCountPerPage={withdrawrecordpage}
                                    totalItemsCount={withdrawtotalpage}
                                    pageRangeDisplayed={withdrawpagerange}
                                    onChange={handlepagewithdraw}
                                  />
                                </div>
                              </div> */}

                              {/* //----------2FA-----------------------// */}

                              <div id="home1" className="tab-pane fade in">
                                <div className="bg_ash bg_card_section bg_card_super mt-0">
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <div className="title_section_funds pl-3">
                                        <h2>2FA</h2>
                                      </div>
                                    </div>
                                    <div className="col-lg-8"></div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 d-flex">
                                    <div className="card_new bg_card_super">
                                      <div className="security__title h3">
                                        <span>Disable</span> 2FA
                                      </div>
                                      <div className="security__text">
                                        If you want to turn off 2FA, input your
                                        account password and the six-digit code
                                        provided by the Google Authenticator app
                                        below, then click{" "}
                                        <span>"Disable 2FA"</span>.
                                      </div>
                                      <div className="security__subtitle">
                                        {" "}
                                        Enable 2FA{" "}
                                      </div>
                                      <div className="security__info">
                                        To disable the two-factor
                                        authentication, enter the six-digit code
                                        given to you by the Google Authenticator
                                        app.
                                      </div>
                                      <div className="security__email">
                                        <i
                                          className="far fa-envelope icon icon-email"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {profileData && profileData.email}{" "}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <div className="card_new bg_card_super">
                                      <div className="security__form">
                                        <form className="sign-up form row flex_form_clas ml-auto mr-auto">
                                          {tfaDetails === 0 ? (
                                            <div className="field field_11">
                                              <div className="field__wrap">
                                                <img
                                                  alt=""
                                                  src={addDetails?.tfa_url}
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div className="security__row">
                                            <div className="field field_view">
                                              <div className="field__label">
                                                2FA code
                                              </div>
                                              <div className="field__wrap">
                                                <input
                                                  type="number"
                                                  id="tfa_code"
                                                  aria-describedby="emailHelp"
                                                  required=""
                                                  placeholder="TFA Code"
                                                  className="field__input"
                                                  name="tfaCode"
                                                  value={tfaCode}
                                                  onChange={(e) =>
                                                    setTfaCode(e.target.value)
                                                  }
                                                />
                                              </div>
                                              {validationErrors.tfaCode && (
                                                <p className="text-danger">
                                                  {" "}
                                                  {
                                                    validationErrors.tfaCode
                                                  }{" "}
                                                </p>
                                              )}
                                            </div>
                                          </div>

                                          <span className="w-100 d-flex justify-content-center">
                                            <button
                                              type="button"
                                              className="button-red security__button button_www button_margud"
                                              onClick={handleSubmit}
                                            >
                                              {tfaDetails === 0
                                                ? "Enable 2FA"
                                                : "Disable 2FA"}{" "}
                                            </button>
                                          </span>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div id="menu2" className="tab-pane fade">
                                <div className="bg_ash bg_card_section bg_card_super mt-0">
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <div className="title_section_funds pl-3">
                                        <h2>New password</h2>
                                      </div>
                                    </div>
                                    <div className="col-lg-8"></div>
                                  </div>
                                </div>
                                <div className="change_password_section classs_change_profile bg_card_super">
                                  <div className="register_login">
                                    <form>
                                      <div className="form-group">
                                        <label>Old Password</label>
                                        <div className="postion_reletitt">
                                          <input
                                            type={inputType}
                                            name="oldpassword"
                                            required=""
                                            id="exampleInputPassword1"
                                            min={8}
                                            max={45}
                                            placeholder="Old password"
                                            className="form-control"
                                            value={oldpassword}
                                            onChange={handleChange}
                                          />
                                          <div className="input-group-addon">
                                            {passHide == false ? (
                                              <i
                                                className="bi bi-eye-slash-fill"
                                                onClick={() =>
                                                  passwordHide("hide")
                                                }
                                              ></i>
                                            ) : (
                                              <i
                                                className="bi bi-eye-fill"
                                                onClick={() =>
                                                  passwordHide("show")
                                                }
                                              ></i>
                                            )}
                                          </div>
                                        </div>
                                        <div>
                                          {oldpassvalidate == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {validationnErr.oldpassword}{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label>New Password</label>
                                        <div className="postion_reletitt">
                                          <input
                                            type={inputTypenew}
                                            min={8}
                                            max={45}
                                            name="password"
                                            required=""
                                            id="exampleInputPassword1"
                                            placeholder="New password"
                                            className="form-control"
                                            value={password}
                                            onChange={handleChange}
                                          />

                                          <div className="input-group-addon">
                                            {passHidnew == false ? (
                                              <i
                                                className="bi bi-eye-slash-fill"
                                                onClick={() =>
                                                  passwordHidenewP("hide")
                                                }
                                              ></i>
                                            ) : (
                                              <i
                                                className="bi bi-eye-fill"
                                                onClick={() =>
                                                  passwordHidenewP("show")
                                                }
                                              ></i>
                                            )}
                                          </div>

                                          <div className="input-group-addon">
                                            {passHidnew == false ? (
                                              <i
                                                className="bi bi-eye-slash-fill"
                                                onClick={() =>
                                                  passwordHidenewP("hide")
                                                }
                                              ></i>
                                            ) : (
                                              <i
                                                className="bi bi-eye-fill"
                                                onClick={() =>
                                                  passwordHidenewP("show")
                                                }
                                              ></i>
                                            )}
                                          </div>
                                        </div>
                                        <div>
                                          {passwordValidate == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {validationnErr.password}{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label>Confirm Password</label>
                                        <div className="postion_reletitt">
                                          <input
                                            type={inputTypeconf}
                                            required=""
                                            min={8}
                                            max={45}
                                            id="exampleInputPassword1"
                                            placeholder="confirm password"
                                            className="form-control"
                                            name="confirmPassword"
                                            value={confirmPassword}
                                            onChange={handleChange}
                                          />
                                          <div className="input-group-addon">
                                            {passHidconf == false ? (
                                              <i
                                                className="bi bi-eye-slash-fill"
                                                onClick={() =>
                                                  passwordHideconf("hide")
                                                }
                                              ></i>
                                            ) : (
                                              <i
                                                className="bi bi-eye-fill"
                                                onClick={() =>
                                                  passwordHideconf("show")
                                                }
                                              ></i>
                                            )}
                                          </div>
                                        </div>
                                        <div>
                                          {confirmPasswordValidate == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {
                                                validationnErr.confirmPassword
                                              }{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <span className="p-0">
                                        {buttonLoader == false ? (
                                          <button
                                            type="button"
                                            className="btn btn-primary fullbinance"
                                            onClick={formSubmit}
                                          >
                                            Submit
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn btn-primary fullbinance"
                                          >
                                            loading...
                                          </button>
                                        )}
                                        {/* <button className="btn btn-primary w-100">
                                          Submit
                                        </button> */}
                                      </span>
                                    </form>
                                  </div>
                                </div>
                              </div>
                              <div id="anti" className="tab-pane fade">
                                <div className="bg_ash bg_card_section bg_card_super mt-0">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="title_section_funds pl-3">
                                        <h2>Create Anti-Phishing Code</h2>
                                      </div>
                                    </div>
                                    <div className="col-lg-6"></div>
                                  </div>
                                </div>
                                <div className="data_productO_autipash bg_card_super m-0">
                                  {changeCode == false ? (
                                    <form>
                                      <div className="info_code">
                                        <div className="icon">
                                          <i class="bi bi-info-circle"></i>
                                        </div>
                                        <p>
                                          do not disclose your password or
                                          verification codes to anyone, include
                                          our support
                                        </p>
                                      </div>
                                      <div className="ifon_contrr">
                                        <h4>What is Anti-Phishing Code?</h4>
                                        <p>
                                          An Anti-Phishing code is a code that
                                          helps to prevent Phishing Attempts
                                          form fake BitbuyerX website or mail
                                          addresses
                                        </p>
                                      </div>
                                      <div className="ifon_contrr">
                                        <h4>How dose it Work?</h4>
                                        <p>
                                          once you've send your unique
                                          Anti-Phishing code, it will be
                                          included in all offical BitbuyerX
                                          email
                                        </p>
                                      </div>
                                      <Button
                                        className="btn btn-primary"
                                        data-toggle="modal"
                                        data-target="#createanti"
                                      >
                                        Create Anti-Phishing Code
                                      </Button>
                                    </form>
                                  ) : (
                                    <div>
                                      <div className="float-right">
                                        <ReactSwitch
                                          checked={checked}
                                          onChange={getEandD}
                                        />
                                      </div>
                                      <form>
                                        <h1>Change Anti-Phishing Code</h1>
                                        <div className="info_code">
                                          <div className="icon">
                                            <i class="bi bi-info-circle"></i>
                                          </div>
                                          <p>
                                            do not disclose your password or
                                            verification codes to anyone,
                                            include our support
                                          </p>
                                        </div>
                                        <div className="ifon_contrr">
                                          <h4>Old Anti-Phishing Code</h4>
                                          <input
                                            type="text"
                                            value={Anticode}
                                            className="form-control"
                                          ></input>
                                        </div>
                                        <Button
                                          className="btn btn-primary"
                                          data-toggle="modal"
                                          data-target="#createanti2"
                                        >
                                          Change Anti-Phishing Code
                                        </Button>
                                      </form>
                                    </div>
                                  )}
                                </div>
                              </div>

                              {/* <div id="menu3" className="tab-pane fade in">
                                <div className="sessions__title">
                                  P2P Order History
                                </div>
                                <div className="card_white_bg">
                                  {p2pOrders && p2pOrders.length > 0 ? (
                                    <CSVLink
                                      className="btn btn-primary float-right mr-2 mt-2"
                                      filename="P2p Order History"
                                      onClick={p2pordercsv}
                                      data={p2pcsvfile}
                                    >
                                      <i
                                        class="fa fa-download"
                                        aria-hidden="true"
                                      ></i>
                                    </CSVLink>) :
                                    (
                                      <div className="btn yellow float-right mr-2 mt-2">
                                        <i
                                          class="fa fa-download"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    )
                                  }

                                  <div className="table-responsive transaction">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">S.No</th>
                                          <th scope="col">Date</th>
                                          <th scope="col">Time</th>
                                          <th scope="col">Currency</th>
                                          <th scope="col">Quantity</th>
                                          <th scope="col">Price</th>
                                          <th scope="col">Order Type</th>
                                          <th scope="col">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {p2pOrders && p2pOrders.length > 0 ? (
                                          p2pOrders.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  {Moment(
                                                    item.createdAt
                                                  ).format("YYYY-MM-DD")}
                                                </td>
                                                <td>
                                                  {Moment(
                                                    item.createdAt
                                                  ).format("hh:mm a")}
                                                </td>
                                                <td>{item.firstCurrency}</td>
                                                <td>
                                                  {parseFloat(
                                                    item.totalAmount
                                                  ).toFixed(8)}
                                                </td>
                                                <td>{item.price}</td>
                                                <td>
                                                  {item.orderType == "buy" ? (
                                                    <span className="text-green">
                                                      Buy
                                                    </span>
                                                  ) : (
                                                    <span className="text-red">
                                                      Sell
                                                    </span>
                                                  )}
                                                </td>
                                                <td>
                                                  {item.status == "filled" ? (
                                                    <span className="text-green">
                                                      {item.status}
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {item.status !== "filled" ? (
                                                    <span className="text-red">
                                                      {item.status}
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <td colSpan="8">
                                            <span className="w-100 text-center d-block">
                                              No Orders Found!
                                            </span>
                                          </td>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <Pagination
                                    itemClass="page-item" // add it for bootstrap 4
                                    linkClass="page-link" // add it for bootstrap 4
                                    activePage={p2pcurrentpage}
                                    itemsCountPerPage={p2precordpage}
                                    totalItemsCount={p2ptotalpage}
                                    pageRangeDisplayed={p2ppagerange}
                                    onChange={handlepagep2p}
                                  />
                                </div>
                              </div> */}

                              {/* <div id="menu4" className="tab-pane fade in">
                                <div className="sessions__title">
                                  P2P History
                                </div>
                                <div className="card_white_bg p-4">
                                  {p2porderHistory &&
                                    p2porderHistory.length > 0 ? (
                                    <CSVLink
                                      className="btn btn-primary float-right mr-2 mt-2"
                                      onClick={p2phistorycsv}
                                      filename="P2p History"
                                      data={p2phistorycsvfile}
                                    >
                                      <i
                                        class="fa fa-download"
                                        aria-hidden="true"
                                      ></i>
                                    </CSVLink>) :
                                    (
                                      <div className="btn yellow float-right mr-2 mt-2">
                                        <i
                                          class="fa fa-download"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    )
                                  }

                                  <div className="table-responsive transaction">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">S.No</th>
                                          <th scope="col">Date</th>
                                          <th scope="col">Time</th>
                                          <th scope="col">Currency</th>
                                          <th scope="col">Quantity</th>
                                          <th scope="col">Price</th>
                                          <th scope="col">Order Type</th>
                                          <th scope="col">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {p2porderHistory &&
                                          p2porderHistory.length > 0 ? (
                                          p2porderHistory.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  {Moment(item.datetime).format(
                                                    "YYYY-MM-DD"
                                                  )}
                                                </td>
                                                <td>
                                                  {Moment(item.datetime).format(
                                                    "hh:mm a"
                                                  )}
                                                </td>
                                                <td>{item.fromCurrency}</td>
                                                <td>
                                                  {parseFloat(
                                                    item.askAmount
                                                  ).toFixed(8)}
                                                </td>
                                                <td>{item.askPrice}</td>
                                                <td>
                                                  {item.type == "buy" ? (
                                                    <span className="text-green">
                                                      Buy
                                                    </span>
                                                  ) : (
                                                    <span className="text-red">
                                                      Sell
                                                    </span>
                                                  )}
                                                </td>
                                                <td>
                                                  <span className="text-green">
                                                    Completed
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <td colSpan="8">
                                            <span className="w-100 text-center d-block">
                                              No Orders Found!
                                            </span>
                                          </td>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <Pagination
                                    itemClass="page-item" // add it for bootstrap 4
                                    linkClass="page-link" // add it for bootstrap 4
                                    activePage={p2phistorycurrentpage}
                                    itemsCountPerPage={p2phistoryrecordpage}
                                    totalItemsCount={p2phistorytotalpage}
                                    pageRangeDisplayed={p2phistorypagerange}
                                    onChange={handlepagep2p_history}
                                  />
                                </div>
                              </div> */}

                              <div id="menu5" className="tab-pane fade">
                                <div className="bg_ash bg_card_section bg_card_super mt-0">
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <div className="title_section_funds pl-3">
                                        <h2>Edit Profile</h2>
                                      </div>
                                    </div>
                                    <div className="col-lg-8"></div>
                                  </div>
                                </div>
                                <div className="change_password_section classs_change_profile bg_card_super">
                                  <div className="register_login">
                                    <form>
                                      <div className="form-group">
                                        <label>Username</label>
                                        <div className="postion_reletitt">
                                          <input
                                            type="text"
                                            name="username"
                                            required=""
                                            id="exampleInputPassword1"
                                            placeholder="Username"
                                            className="form-control"
                                            value={username}
                                            maxLength={25}
                                            onChange={handleChange_profile}
                                          />
                                        </div>
                                        <div>
                                          {usernamevalidateref.current ==
                                          true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {validationnErr.username}{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label>Email</label>
                                        <div className="postion_reletitt">
                                          <input
                                            type="email"
                                            name="email"
                                            required=""
                                            id="exampleInputPassword1"
                                            placeholder="Email"
                                            className="form-control"
                                            value={email}
                                            readonly
                                          />
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label>Mobile Number</label>
                                        <div className="postion_reletitt">
                                          <input
                                            type="text"
                                            name="mobileNumber"
                                            required=""
                                            id="exampleInputPassword1"
                                            placeholder="Mobile Number"
                                            className="form-control"
                                            maxLength={15}
                                            value={mobileNumber}
                                            onChange={handleChange_profile}
                                          />
                                        </div>
                                        <div>
                                          {mobilevalidateref.current == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {validationnErr.mobileNumber}{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <span className="p-0">
                                        {profileLoader == false ? (
                                          <button
                                            type="button"
                                            className="btn btn-primary fullbinance"
                                            onClick={profileSubmit}
                                          >
                                            Submit
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn btn-primary fullbinance"
                                          >
                                            loading...
                                          </button>
                                        )}
                                        {/* <button className="btn btn-primary w-100">
                                          Submit
                                        </button> */}
                                      </span>
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* <div id="menu6" className="tab-pane fade in">
                                <div className="sessions__title">
                                  Sending Gift History
                                </div>

                                <div className="page_re_direct">
                                  <Link to="/giftCardbuy" target="_blank">
                                    Send
                                    <p>Send or create card</p>
                                    <img
                                      src={require("../img/gift-box.png")}
                                      className="l"
                                    />
                                  </Link>
                                  <Link to="/giftCard" target="_blank">
                                    Receive
                                    <p>Check value or redeem</p>
                                    <img
                                      src={require("../img/gift.png")}
                                      className="second"
                                    />
                                  </Link>
                                </div>
                                <div className="card_white_bg p-4">
                                  <CSVLink
                                    className="btn btn-primary float-right mr-2 mt-2"
                                    // onClick={gifthistorycsv}
                                    filename="gift History"
                                    // data={gifthistorydata}
                                  >
                                    <i
                                      class="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </CSVLink>
                                  <div className="table-responsive transaction">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">S.No</th>
                                          <th scope="col">Date</th>
                                          <th scope="col">Gift Currency</th>
                                          <th scope="col">Amount of Gift</th>
                                          <th scope="col">Sender</th>
                                          <th scope="col">Receiver</th>
                                          <th scope="col">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {gifthistory &&
                                          gifthistory.length > 0 ? (
                                          gifthistory.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  {Moment(item.datetime).format(
                                                    "YYYY-MM-DD"
                                                  )}
                                                </td>
                                                <td>{item.Giftcurrency}</td>
                                                <td>{item.Amount}</td>

                                                <td>{item.fromusername}</td>
                                                <td>{item.tousername}</td>
                                                <td>
                                                  <span className="text-green">
                                                    {item.redeemcodestatus}
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <td colSpan="8">
                                            <span className="w-100 text-center d-block">
                                              No Orders Found!
                                            </span>
                                          </td>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <Pagination
                                    itemClass="page-item" // add it for bootstrap 4
                                    linkClass="page-link" // add it for bootstrap 4
                                    activePage={giftcurrentpage}
                                    itemsCountPerPage={giftrecordpage}
                                    totalItemsCount={gifttotalpage}
                                    pageRangeDisplayed={giftpagerange}
                                    onChange={handlepagegifthistory}
                                  />
                                </div>
                              </div> */}

                              {/* <div id="home3" className="tab-pane fade in">
                                <div className="profile__wrapper">
                                  <div className="sessions">
                                    <div className="sessions__section">
                                      <div className="sessions__title">
                                        Notifications
                                      </div>

                                      <div className="card_white_bg p-4">
                                        {sessionHistory &&
                                          sessionHistory.length > 0 ? (
                                          <CSVLink
                                            className="btn btn-primary float-right ml-2 mt-2"
                                            filename="Login History"
                                            onClick={csv}
                                            data={csvfile}
                                          >
                                            <i
                                              class="fa fa-download"
                                              aria-hidden="true"
                                            ></i>
                                          </CSVLink>) :
                                          (
                                            <div className="btn yellow float-right mr-2 mt-2">
                                              <i
                                                class="fa fa-download"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          )
                                        }

                                        <div className="sessions__table">
                                          <div className="sessions__row">
                                            <div className="sessions__col">
                                              S.No
                                            </div>
                                            <div className="sessions__col">
                                              Date / time
                                            </div>
                                            <div className="sessions__col">
                                              IP address
                                            </div>
                                            <div className="sessions__col">
                                              Device
                                            </div>
                                            <div className="sessions__col">
                                              Platform
                                            </div>
                                          </div>

                                          {sessionHistory &&
                                            sessionHistory.length > 0 ? (
                                            sessionHistory.map((item, i) => {
                                              return (
                                                <div className="sessions__row">
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {i + 1}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {Moment(
                                                        item.createdDate
                                                      ).format("lll")}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {item.ipAddress}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {item.browser}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {item.platform}
                                                    </div>
                                                  </div>
                                                  <div className="sessions__col">
                                                    <div className="sessions__content">
                                                      {item.Status}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <td colSpan="7">
                                              <span className="w-100 text-center d-block">
                                                No History Found!
                                              </span>
                                            </td>
                                          )}
                                        </div>
                                        <Pagination
                                          itemClass="page-item" // add it for bootstrap 4
                                          linkClass="page-link" // add it for bootstrap 4
                                          activePage={currentPage}
                                          itemsCountPerPage={recordPerPage}
                                          totalItemsCount={totalPage}
                                          pageRangeDisplayed={pageRange}
                                          onChange={handlePageChange}
                                        />
                                      </div>

                                      <div className="card_white_bg p-4">
                                        <div className="tabel-responsive new_tabele_notit">
                                          <div className="sessions__table">
                                            <div className="sessions__row">
                                              <div className="sessions__col">
                                                S.No
                                              </div>
                                              <div className="sessions__col">
                                                Date / time
                                              </div>
                                              <div className="sessions__col">
                                                From
                                              </div>
                                              <div className="sessions__col">
                                                Message
                                              </div>
                                            </div>

                                            {notification &&
                                              notification.length > 0 ? (
                                              notification.map((item, i) => {
                                                return (
                                                  <div className="sessions__row">
                                                    <div className="sessions__col">
                                                      <div className="sessions__content">
                                                        {i + 1}
                                                      </div>
                                                    </div>
                                                    <div className="sessions__col">
                                                      <div className="sessions__content">
                                                        {Moment(
                                                          item.createdDate
                                                        ).format("lll")}
                                                      </div>
                                                     
                                                    </div>
                                                    <div className="sessions__col">
                                                      <div className="sessions__content">
                                                        {item.from_user_name}
                                                      </div>
                                                    </div>
                                                    <div className="sessions__col">
                                                      <div className="sessions__content tect_werp">
                                                        {item.message}
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              })
                                            ) : (
                                              <td colSpan="7">
                                                <span className="w-100 text-center d-block">
                                                  notifications not found
                                                </span>
                                              </td>
                                            )}
                                          </div>
                                        </div>
                                        <Pagination
                                          itemClass="page-item" // add it for bootstrap 4
                                          linkClass="page-link" // add it for bootstrap 4
                                          activePage={notifyCurrentPage}
                                          itemsCountPerPage={
                                            notifyrecordPerPage
                                          }
                                          totalItemsCount={notifytotalpage}
                                          pageRangeDisplayed={notifypageRange}
                                          onChange={handlenotifyPageChange}
                                        />
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>

      <div class="modal" id="createanti">
        <div class="modal-dialog modal-dialog-centered data_productO_autipash create_code_section model_section">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Create Anti-Phishing Code</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div className="change_password_section w-100">
                <div className="register_login">
                  {otpPage == true ? (
                    <form>
                      <div className="form-group">
                        <label>Anti-Phishing Code</label>
                        <input
                          type="text"
                          placeholder="Enter Code"
                          name="APcode"
                          className="form-control"
                          onChange={getValue}
                          minLength={4}
                          maxLength={20}
                        />
                        <div>
                          {AntiPcode == true ? (
                            <p className="text-danger">
                              {" "}
                              {APcodevalied.APcode}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <span>
                          Please Enter 4-20 characters, excluding special
                          symbols
                        </span>
                      </div>
                      <Button
                        className="btn btn-primary mt-3"
                        onClick={createCode}
                      >
                        Submit
                      </Button>
                    </form>
                  ) : (
                    <form>
                      <div className="form-group">
                        <label className="text-center mx-auto d-block mb-3">
                          Email verification Code
                        </label>
                        <div className="otp_content_e">
                          <OTPInput
                            value={OTP}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure
                          />
                        </div>
                      </div>
                      <Button
                        className="btn btn-primary mt-3"
                        onClick={verifyOTP}
                      >
                        Submit
                      </Button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="createanti2">
        <div class="modal-dialog modal-dialog-centered data_productO_autipash create_code_section model_section">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Change Anti-Phishing Code</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div className="change_password_section w-100">
                <div className="register_login">
                  {codePage == true ? (
                    <form>
                      <div className="form-group">
                        <label>Anti-Phishing Code</label>
                        <input
                          type="text"
                          placeholder="Enter Code"
                          name="changeAPcode"
                          className="form-control"
                          onChange={getValue}
                        />

                        <div>
                          {changeAnticode == true ? (
                            <p className="text-danger">
                              {" "}
                              {APcodevalied.changeAPcode}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <span>
                          Please Enter 4-20 characters, excluding special
                          symbols
                        </span>
                      </div>
                      <Button
                        className="btn btn-primary mt-3"
                        onClick={changeAntiCode}
                      >
                        Submit
                      </Button>
                    </form>
                  ) : (
                    <form>
                      <div className="form-group">
                        <label className="text-center mx-auto d-block mb-3">
                          Email verification Code
                        </label>
                        <div className="otp_content_e">
                          <OTPInput
                            value={OTP}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure
                          />
                        </div>
                      </div>
                      <Button
                        className="btn btn-primary mt-3"
                        onClick={verifyOTP}
                      >
                        Submit
                      </Button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
