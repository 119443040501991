import React, { useEffect } from "react";
import useState from "react-usestateref";
import "react-dropdown/style.css";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import Moment from "moment";

function Home() {
  Moment.locale("en");
  const navigate = useNavigate();
  const [giftcurrentpage, setgiftcurrentpage] = useState(1);
  const [gifttotalpage, Setgifttotalpage] = useState(0);
  const [gifthistory, Setgifthistory] = useState([]);
const [sideLoader,setSiteLoader]=useState(true);

  const giftrecordpage = 5;
  const giftpagerange = 5;
  const handlepagegifthistory = (page) => {
    getgifthistory(page);
    setgiftcurrentpage(page);
  };
  const getgifthistory = async (page) => {
    var obj = {
      FilPerpage: 5,
      FilPage: page,
    };
    setSiteLoader(true)
    var data = {
      apiUrl: apiService.giftcardhistory,
      payload: obj,
    };
    var giftcardhistory = await postMethod(data);
    setSiteLoader(false)
    Setgifthistory(giftcardhistory.Message.Message);
    Setgifttotalpage(giftcardhistory.Message.total);
  };
  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getgifthistory(1);
  }, [0]);
  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5 padinf_ mb-5">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padinf_">
            <div className="container d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="bg_ash bg_card_section bg_card_super">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="title_section_funds">
                        <h2> Sending Gift History</h2>
                      </div>
                    </div>
                    <div className="col-lg-8"></div>
                  </div>
                </div>
                <div className="staking_optoikn">
                  {/* //======================Staking Details==============// */}

                  <div class="">
                    <div id="LockedStaking" class="">
                      <div className="staking_title bg_card_super">
                        <div class="fixTableHead table_section_color">
                          <div className="page_re_direct">
                            <Link
                              to="/giftCardbuy"
                              target="_blank"
                              className="card_flex_gift"
                            >
                              <div className="class">
                                Send
                                <p>Send or create card</p>
                              </div>
                              <img
                                src={require("../img/gift-box.png")}
                                className="l"
                              />
                            </Link>
                            <Link
                              to="/giftCard"
                              target="_blank"
                              className="card_flex_gift"
                            >
                              <div className="class">
                                Receive
                                <p>Check value or redeem</p>
                              </div>
                              <img
                                src={require("../img/gift.png")}
                                className="second"
                              />
                            </Link>
                          </div>

                          <div className="table-responsive transaction">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">S.No</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Gift Currency</th>
                                  <th scope="col">Amount of Gift</th>
                                  <th scope="col">Sender</th>
                                  <th scope="col">Receiver</th>
                                  <th scope="col">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {gifthistory && gifthistory.length > 0 ? (
                                  gifthistory.map((item, i) => {
                                    return (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                          {Moment(item.datetime).format(
                                            "YYYY-MM-DD"
                                          )}
                                        </td>
                                        <td>{item.Giftcurrency}</td>
                                        <td>{item.Amount}</td>

                                        <td>{item.fromusername}</td>
                                        <td>{item.tousername}</td>
                                        <td>
                                        {item.redeemcodestatus == "Deactive" ? (
                                          <span className="text-danger">
                                            {item.redeemcodestatus}
                                          </span>
                                        ) : (
                                          <span className="text-green">
                                            {item.redeemcodestatus}
                                          </span>
                                        ) }

                                          
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <td colSpan="8">
                                    <span className="w-100 text-center d-block">
                                      No Orders Found!
                                    </span>
                                  </td>
                                )}
                              </tbody>
                            </table>
                          </div>

                          <div className="mt-5">
                            <Pagination
                              itemClass="page-item" // add it for bootstrap 4
                              linkClass="page-link" // add it for bootstrap 4
                              activePage={giftcurrentpage}
                              itemsCountPerPage={giftrecordpage}
                              totalItemsCount={gifttotalpage}
                              pageRangeDisplayed={giftpagerange}
                              onChange={handlepagegifthistory}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
