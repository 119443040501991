import React, {useEffect} from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Arrow from "../img/ArrowRight.svg";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import {Button} from "@material-ui/core";

import {setAuthorization} from "../core/service/axios";
function Home() {
  const options = ["one", "two", "three"];

  const initialFormValue = {
    email: "",
    password: "",
  };

  useEffect(() => {
    var token = localStorage.getItem("user_token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] = useState(
    false
  );
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");

  const {email, isTerms, password, confirmPassword} = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is required field";
      setemailValidate(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      setemailValidate(true);
    } else {
      setemailValidate(false);
    }

    if (values.password == "") {
      setpasswordValidate(true);
      errors.password = "password is required field";
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password =
        "Password is required and shouldnot below 5 above 25 letters";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at One digit character";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character";
    } else {
      setpasswordValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async () => {
    validate(formValue);
    if (
      emailValidateref.current === false &&
      passwordValidateref.current === false
    ) {
      var data = {
        apiUrl: apiService.signin,
        payload: formValue,
      };
      setbuttonLoader(true)
      var resp = await postMethod(data);
      setbuttonLoader(false)
      if (resp?.tfa === 1) {
        navigate("/verify-tfa", {
          state: {
            socketToken: resp?.socketToken,
          },
        });
      } else {
        if (resp.status == true) {
          toast.success(resp.Message);
          setFormValue(initialFormValue);
          // console.log(resp, "[--=-=resp");
          await setAuthorization(resp.token);
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem("tfa_status", resp.tfa);
          localStorage.setItem("socket_token", resp.socketToken);
          localStorage.setItem("jwNkiKmttscotlox", resp.jwNkiKmttscotlox);
          navigate("/Dashboard");
        } else {
          toast.error(resp.Message);
        }
      }
    }
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  return (
    <div className="">
      <main className="main-content  login_body_ bg-cover bg_white">
        <Header />
        <div className="tradepage-bg">
          <div class="container p-0">
            <div className="max-w-8xl mx-auto  sm:px-6 md:px-8 padin_zero p-0">
              <div class="row">
                <div class="col-lg-6 p-0">
                  <div class="login_content_section">
                    <h1>
                      Fastest & secure <br /> platform to invest <br />
                      in crypto
                    </h1>
                    <p>
                      Buy and sell cryptocurrencies, trusted by 10M wallets{" "}
                      <br />
                      with over $30 billion in transactions.
                    </p>
                    {/* <div class="login_btn_n">
                    <button type="submit" class="btn btn-primary but_paginys">
                      <Link to="/register">Try for FREE</Link>

                      {/* <img src={Arrow} className="logo" /> */}
                    {/* </button> */}
                    {/* </div> */}
                  </div>
                </div>
                <div class="col-lg-6 p-0">
                  <div class="form_login_section">
                    <h1>Hi, Welcome Back</h1>
                    <p>Enter your credentials to continue</p>
                    {/* <div class="login_with_gool">
                    <button>
                      <img src={glogo} className="logo" />
                      Sign in with google
                    </button>
                  </div>
                  <span class="or_login_section">or sign with Email</span> */}

                    <div class="form register_login">
                      <form>
                        <div class="form-group">
                          <label>Your Email</label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Your Email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                          />
                          <div>
                            {emailValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr.email}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Password</label>
                          <div className="postion_reletitt">
                            <input
                              type={inputType}
                              class="form-control"
                              id="exampleInputPassword1"
                              placeholder="Enter your password"
                              name="password"
                              minLength={3}
                              maxLength={25}
                              value={password}
                              onChange={handleChange}
                            />
                            <div className="input-group-addon">
                              {passHide == false ? (
                                <i
                                  className="bi bi-eye-slash-fill"
                                  onClick={() => passwordHide("hide")}
                                ></i>
                              ) : (
                                <i
                                  className="bi bi-eye-fill"
                                  onClick={() => passwordHide("show")}
                                ></i>
                              )}
                            </div>
                          </div>
                          <div>
                            {passwordValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr.password}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div class="custem_check">
                          {/* <div>
                          <div class="custom-control custom-checkbox mb-3">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customCheck"
                              name="example1"
                            />
                            <label
                              class="custom-control-label"
                              for="customCheck"
                            >
                              Custom checkbox
                            </label>
                          </div>
                        </div> */}
                        </div>

                        {buttonLoader == false ? (
                          <button
                            type="button"
                            class="btn btn-primary w-100"
                            onClick={formSubmit}
                          >
                            Login
                          </button>
                        ) : (
                          <button type="button" class="btn btn-primary w-100">
                            loading...  
                          </button>
                        )}

                        <p class="bottom_linnk">
                          <Link to="/forgot">
                            <a className="forget">Forgot Password?</a>
                          </Link>
                          <Link to="/register">
                            <a>Register for free</a>
                          </Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
