import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Button } from "@material-ui/core";
import "react-dropdown/style.css";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import Moment from "moment";

function Home() {
  const [currentPair, setcurrentPair] = useState("");
  const [checkAuth, setcheckAuth] = useState(false);
  const [pairDetails, setpairDetails] = useState("");

  const [perpage, setperpage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeOrderDatas, setActiveOders] = useState([]);
  const [tradeHistoryData, settradeHistory] = useState([]);
  // const [alltradeHistoryData, setalltradeHistory] = useState([]);
  const [cancelOrders, setcancelOrders] = useState([]);
  const [totalactive, settotalactive] = useState(0);
  const [currentPagecan, setCurrentPagecan] = useState(1);
  const [totalcan, settotalcan] = useState(0);
  const [currentPageHis, setcurrentPageHis] = useState(1);
  const [totalHist, settotalHist] = useState(0);

  const recordPerPage = 5;
  const pageRange = 5;
  const recordPerPagecan = 5;
  const pageRangecan = 5;

  const recordPerPageHist = 5;
  const pageRangeHis = 5;
  const [sideLoader,setSiteLoader]=useState(true);

  useEffect(() => {
    getActiveOrders(1, "BTC_USDT");
    tradeHistory(1);
    getCancelOrders(1);
    // console.log(checked);
  }, []);
  const getActiveOrders = async (pages, getpair) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: getpair,
      };
      var data = {
        apiUrl: apiService.getActiveOrders,
        payload: obj,
      };
      setSiteLoader(true);
      var resp = await postMethod(data);
      if (resp.status) {
      setSiteLoader(false)
        settotalactive(resp.count);
        setActiveOders(resp.result);
      } else {
      }
    } catch (error) {}
  };

  const tradeHistory = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: pairDetails.pair,
      };
      var data = {
        apiUrl: apiService.tradeHistory,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settradeHistory(resp.result);
        // console.log(resp.result, "=-=-=-esp.result=-=-=");
        settotalHist(resp.count);
      } else {
      }
    } catch (error) {}
  };

  
  const getCancelOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: currentPair,
      };
      var data = {
        apiUrl: apiService.getCancelOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setcancelOrders(resp.result);
        // console.log(resp.result, "-=-==-resp.result-=-=");
        settotalcan(resp.count);
      } else {
      }
    } catch (error) {}
  };

  const activePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getActiveOrders(pageNumber, "");
  };

  const cancelPageChange = (pageNumber) => {
    setCurrentPagecan(pageNumber); // call API to get data based on pageNumber
    getCancelOrders(pageNumber);
  };

  const orderhistoryactive = (pageNumber) => {
    setcurrentPageHis(pageNumber); // call API to get data based on pageNumber
    tradeHistory(pageNumber);
  };

  const orderCancel = async (cancelDatas) => {
    try {
      var obj = {
        _id: cancelDatas._id,
      };
      var data = {
        apiUrl: apiService.cancelOrder,
        payload: obj,
      };
      var fetchTradeHisotory = await postMethod(data);
      if (fetchTradeHisotory) {
        toast.success(
          "Order cancelled successfully, your amount credit to your wallet"
        );
        getActiveOrders(1, currentPair);
      } else {
        toast.error("Please try again later");
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };
  const callCancelOrder = async () => {
    if (checkAuth === true) {
      getCancelOrders(1);
    }
  };
  const callOrdehistory = async () => {
    if (checkAuth === true) {
      tradeHistory(1);
    }
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5 padinf_ mb-5">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padinf_">
            <div className="container d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="bg_ash bg_card_section bg_card_super">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="title_section_funds">
                        <h2>Spot History</h2>
                      </div>
                    </div>
                    <div className="col-lg-8"></div>
                  </div>
                </div>
                <div className="staking_optoikn">
                  {/* //======================Staking Details==============// */}

                  <div class="">
                    <div id="LockedStaking" class="">
                      <div className="staking_title bg_card_super">
                        <div class="fixTableHead">
                          <div className="title-table ">
                            {/* <h4>History</h4> */}
                            <ul className="nav nav-tabs width_tabs_section">
                              <li className="active">
                                <a
                                  data-toggle="tab"
                                  href="#OpenOrders"
                                  className="active"
                                >
                                  Open Orders
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#OrderHistory"
                                  onClick={callOrdehistory}
                                >
                                  Order History
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tab"
                                  href="#OrderBook"
                                  onClick={callCancelOrder}
                                >
                                  Cancel Orders
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="tab-content">
                            {/* ==========ACTIVE OREDERS========== */}
                            <div
                              id="OpenOrders"
                              className="tab-pane fade in active show "
                            >
                              <div className="fixTableHead card_light p-0 m-0 table_section_color">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Pair </th>
                                      <th>Price </th>
                                      <th>Side </th>
                                      <th>Order Type </th>
                                      <th>Amount </th>
                                      <th>Total </th>
                                      <th>Cancel </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {activeOrderDatas.length > 0 ? (
                                      activeOrderDatas.map((item, i) => {
                                        var dates = Moment(
                                          item.createddate
                                        ).format("DD-MM-YYYY hh:mm:ss");
                                        return (
                                          <tr>
                                            <td>{dates} </td>
                                            <td>{item.pairName} </td>
                                            <td>
                                              {item.tradeType == "buy" ? (
                                                <span className="text-green">
                                                  {" "}
                                                  {item.ordertype == "Stop"
                                                    ? parseFloat(
                                                        item.stoporderprice
                                                      ).toFixed(4)
                                                    : parseFloat(
                                                        item.price
                                                      ).toFixed(4)}{" "}
                                                </span>
                                              ) : (
                                                <span className="text-red">
                                                  {" "}
                                                  {item.ordertype == "Stop"
                                                    ? parseFloat(
                                                        item.stoporderprice
                                                      ).toFixed(4)
                                                    : parseFloat(
                                                        item.price
                                                      ).toFixed(4)}{" "}
                                                </span>
                                              )}
                                            </td>
                                            <td> {item.tradeType} </td>
                                            <td> {item.ordertype} </td>
                                            <td>
                                              {" "}
                                              {parseFloat(
                                                item.filledAmount
                                              ).toFixed(4)}{" "}
                                            </td>
                                            <td>
                                              {item.ordertype == "Stop"
                                                ? parseFloat(
                                                    item.filledAmount *
                                                      item.stoporderprice
                                                  ).toFixed(4)
                                                : parseFloat(
                                                    item.filledAmount *
                                                      item.price
                                                  ).toFixed(4)}
                                            </td>
                                            <td>
                                              <Button
                                                className="btn btn-primary-alta connectBtn"
                                                onClick={() =>
                                                  orderCancel(item)
                                                }
                                              >
                                                Cancel
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        {" "}
                                        {!checkAuth ? (
                                          <td colSpan="8" className="mt-5">
                                            {" "}
                                            No found open orders!
                                          </td>
                                        ) : (
                                          <td colSpan="8" className="mt-5">
                                            {" "}
                                            No found open orders!
                                          </td>
                                        )}
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                {activeOrderDatas &&
                                activeOrderDatas.length > 0 ? (
                                  <div className="mt-5">
                                    <Pagination
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      activePage={currentPage}
                                      itemsCountPerPage={recordPerPage}
                                      totalItemsCount={totalactive}
                                      pageRangeDisplayed={pageRange}
                                      onChange={activePageChange}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div id="OrderHistory" className="tab-pane fade">
                              {/* ==========OREDERS HISTORY========== */}

                              <div className="fixTableHead table_section_color card_light p-0 m-0">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Pair </th>
                                      <th>Side </th>
                                      <th>Type </th>
                                      <th>Price </th>
                                      <th>Amount </th>
                                      <th>Total </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {tradeHistoryData.length > 0 ? (
                                      tradeHistoryData.map((item, i) => {
                                        var datesHis = Moment(
                                          item.created_at
                                        ).format("DD-MM-YYYY hh:mm:ss");
                                        return (
                                          <tr>
                                            <td>{datesHis}</td>
                                            <td>{item.pair} </td>
                                            <td>
                                              {item.type == "buy" ? (
                                                <span className="text-green">
                                                  {" "}
                                                  Buy{" "}
                                                </span>
                                              ) : (
                                                <span className="text-red">
                                                  {" "}
                                                  Sell{" "}
                                                </span>
                                              )}
                                            </td>

                                            <td>{item.seller_ordertype}</td>
                                            <td>
                                              {item.type == "buy" ? (
                                                <span className="text-green">
                                                  {" "}
                                                  {item.askPrice}{" "}
                                                </span>
                                              ) : (
                                                <span className="text-red">
                                                  {" "}
                                                  {item.askPrice}{" "}
                                                </span>
                                              )}
                                            </td>
                                            <td> {item.askAmount} </td>
                                            <td>{item.total}</td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        {" "}
                                        {!checkAuth ? (
                                          <td colSpan="7">
                                            {" "}
                                            No Order history found!
                                          </td>
                                        ) : (
                                          <td colSpan="7">
                                            {" "}
                                            No Order history found!
                                          </td>
                                        )}{" "}
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                {tradeHistoryData &&
                                tradeHistoryData.length > 0 ? (
                                  <div className="mt-5">
                                    <Pagination
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      activePage={currentPageHis}
                                      itemsCountPerPage={recordPerPageHist}
                                      totalItemsCount={totalHist}
                                      pageRangeDisplayed={pageRangeHis}
                                      onChange={orderhistoryactive}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            {/* ==================CANCEL ORDERS============ */}

                            <div id="OrderBook" className="tab-pane fade">
                              <div className="fixTableHead table_section_color card_light p-0 m-0">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Pair</th>
                                      <th>Side </th>
                                      <th>Type </th>
                                      <th>Price </th>
                                      <th>Amount </th>
                                      <th>Total </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {cancelOrders.length > 0 ? (
                                      cancelOrders.map((item, i) => {
                                        var total =
                                          item.ordertype == "Stop"
                                            ? +item.filledAmount *
                                              +item.stoporderprice
                                            : +item.filledAmount * +item.price;
                                        return (
                                          <tr>
                                            <td>{item.createddate} </td>
                                            <td>{item.pairName} </td>
                                            <td>
                                              {item.tradeType == "buy" ? (
                                                <span className="text-green">
                                                  {" "}
                                                  Buy{" "}
                                                </span>
                                              ) : (
                                                <span className="text-red">
                                                  {" "}
                                                  Sell{" "}
                                                </span>
                                              )}
                                            </td>
                                            <td>{item.ordertype} </td>
                                            <td>
                                              {item.tradeType == "buy" ? (
                                                <span className="text-green">
                                                  {" "}
                                                  {item.ordertype == "Stop"
                                                    ? parseFloat(
                                                        item.stoporderprice
                                                      ).toFixed(4)
                                                    : parseFloat(
                                                        item.price
                                                      ).toFixed(4)}{" "}
                                                </span>
                                              ) : (
                                                <span className="text-red">
                                                  {" "}
                                                  {item.ordertype == "Stop"
                                                    ? parseFloat(
                                                        item.stoporderprice
                                                      ).toFixed(4)
                                                    : parseFloat(
                                                        item.price
                                                      ).toFixed(4)}{" "}
                                                </span>
                                              )}
                                            </td>
                                            <td> {item.amount} </td>
                                            <td>
                                              {parseFloat(total).toFixed(4)}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        {" "}
                                        {!checkAuth ? (
                                          <td colSpan="6">
                                            No Cancel order found!
                                          </td>
                                        ) : (
                                          <td colSpan="6">
                                            {" "}
                                            No Cancel order found!{" "}
                                          </td>
                                        )}
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                {cancelOrders && cancelOrders.length > 0 ? (
                                  <div className="mt-5">
                                    <Pagination
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      activePage={currentPagecan}
                                      itemsCountPerPage={recordPerPagecan}
                                      totalItemsCount={totalcan}
                                      pageRangeDisplayed={pageRangecan}
                                      onChange={cancelPageChange}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
