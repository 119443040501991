import React, { useEffect, useState } from "react";
import useStateRef from "react-usestateref";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import Dropdown from "react-dropdown";
import { toast } from "react-toastify";
import "react-dropdown/style.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import useRazorpay from "react-razorpay";

function PaymentGetway() {
  const navigate = useNavigate();

  const Razorpay = useRazorpay();

  const [currency, setcurrency] = useState(false);
  const [selectcurrency, setselectcurrency] = useState("");
  const [amount, setamount, amountref] = useStateRef("");
  const [mobilevalidation, setmobilevalidation] = useState(false);
  const [currencyvalid, setcurrencyvalid] = useState(false);
  const [sideLoader,setSiteLoader]=useState(true);

  useEffect(()=>{
    setTimeout(() => {
      setSiteLoader(false)
 }, 20000)

  })
  const submit = (e) => {
    e.preventDefault();
    validate();
    if (amountref.current != "" && selectcurrency != "") {
      setcurrency(true);
      console.log(selectcurrency, "111111111===");
    } else {
      setcurrency(false);
      console.log(amountref.current, "222222222===");
    }
  };

  const validate = () => {
    if (amountref.current == "") {
      setmobilevalidation(true);
    } else {
      setmobilevalidation(false);
    }
    if (selectcurrency == "") {
      setcurrencyvalid(true);
    } else {
      setcurrencyvalid(false);
    }
  };

  const options = ["USD", "INR"];

  const getAmount = (e) => {
    setamount(e.target.value);
    console.log(e.target.value, "e.target.value");
  };

  const getCurrency = (e) => {
    setselectcurrency(e.value);
    console.log(e.value, "e.value");
  };

  const addBalance = async (details) => {
    console.log(details, "details");
    try {
      var obj = {
        amount: amountref.current,
        currency: "USD",
        data: details,
      };
      var data = {
        apiUrl: apiService.addBalance,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.Status == true) {
        toast.success(resp.Message);
        navigate("/Dashboard");
      } else {
      }
    } catch (error) {}
  };

  const payOptions = {
    "client-id":
      "AcL6l-CsJpkmaZgfYK7fEp2INRcZ0tk_p5o14XFOSc5jWVuMj8OUTeDxhC3aAzlAu71xKOi0ctnVg9ww",
    currency: "USD",
  };

  const handlePayment = async (params) => {
    console.log(params, "=-=-=-=-params");
    var obj = {
      Amount: amountref.current + "00",
      Currency: selectcurrency,
    };
    var data = {
      apiUrl: apiService.addBalance,
      payload: obj,
    };
    var resp = await postMethod(data);
    console.log(resp, "=-=-=-=-=-resp");
    if (resp) {
      const options = {
        key: "rzp_test_oSB58XJWBr3lNV",
        amount: amountref.current,
        currency: selectcurrency,
        name: "Bitbuyerx",
        description: "Test Transaction",
        image:
          "upi://pay?pa=facelifepraveen@okaxis&pn=Praveen%20Kanna&aid=uGICAgICzi7rZPQ",
        order_id: resp.data,
        handler: async function (response) {
          console.log(response, "response");
          var payload = {
            Currency: selectcurrency,
            amount: amountref.current,
            Id: response.razorpay_payment_id,
          };
          var data = {
            apiUrl: apiService.Paymentgateway,
            payload: payload,
          };
          var responce = await postMethod(data);
          console.log(responce);
          if (responce.Status == true) {
            toast.success(responce.Message);
            navigate("/Dashboard");
          }
        },
        prefill: {
          name: "THOMAS MANICKAM",
          email: "pavi.beleaf05@gmail.com",
          contact: "6374351475",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      console.log(options, "=-=options-=-=-");
      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        console.log(response, "response");

        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
      rzp1.open();
    }
  };

  return (
    <>
      <div className="">
        <main className="main-content tradepage-bg login_body_ bg-cover bg_white">
          <Header />
          {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
          <>
          
          <section className="bg_gray_sectiond">
            <div className="container">
              <div className="bg_ash bg_card_section bg_card_super">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="title_section_funds">
                      <h2>Deposit Fiat</h2>
                    </div>
                  </div>
                  <div className="col-lg-8"></div>
                </div>
              </div>
              <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero contas_paypal"></div>
            </div>
          </section>
          <div class="container">
            <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
              <div class="row justify-center">
                <div class="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <form className="pay_form_sss_">
                        <h3>Select Currency</h3>
                        <div className="form-group">
                          <label>Currency</label>
                          <Dropdown
                            options={options}
                            placeholder="Select Currency"
                            onChange={getCurrency}
                          />
                          {currencyvalid == true ? (
                            <p className="text-danger">Currency is required</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group">
                          <label>Amount</label>

                          <input
                            type="number"
                            placeholder="Enter Amount"
                            className="form-control"
                            onChange={getAmount}
                          />
                          {mobilevalidation == true ? (
                            <p className="text-danger">Amount is required</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <button onClick={submit}>Choose payment method</button>
                        <br />
                      </form>

                      <PayPalScriptProvider options={payOptions}>
                        {currency == true ? (
                          selectcurrency == "USD" ? (
                            <div className="px-5">
                              <PayPalButtons
                                createOrder={(data, actions) => {
                                  return actions.order.create({
                                    purchase_units: [
                                      {
                                        amount: {
                                          value: amountref.current,
                                        },
                                      },
                                    ],
                                  });
                                }}
                                onApprove={(data, actions) => {
                                  return actions.order
                                    .capture()
                                    .then((details) => {
                                      addBalance(details);
                                      // const name = details.payer.name.given_name;
                                      // alert(`Transaction completed by ${name}`);
                                    });
                                }}
                              />
                            </div>
                          ) : (
                            <div className="">
                              <button
                                className="button_class1 rounded mx-auto d-block"
                                onClick={handlePayment}
                              >
                                <img
                                  src={require("../img/razorpay.png")}
                                  className="razorpay mx-auto d-block"
                                />
                              </button>
                            </div>
                          )
                        ) : (
                          ""
                        )}
                      </PayPalScriptProvider>
                    </div>
                    <div className="col-lg-6">
                      <div id="main">
                        <h3>FAQ</h3>
                        <div class="container">
                          <div class="accordion" id="faq">
                            <div class="card">
                              <div class="card-header" id="faqhead1">
                                <a
                                  href="#"
                                  class="btn btn-header-link"
                                  data-toggle="collapse"
                                  data-target="#faq1"
                                  aria-expanded="true"
                                  aria-controls="faq1"
                                >
                                  How to Fund A Bitbuyerx Account
                                </a>
                              </div>

                              <div
                                id="faq1"
                                class="collapse show"
                                aria-labelledby="faqhead1"
                                data-parent="#faq"
                              >
                                <div class="card-body">
                                  Bitbuyerx accepts a wide variety of
                                  cryptocurrencies for funding, including
                                  popular options like Bitcoin (BTC), Ethereum
                                  (ETH) and many others. You can find the full
                                  list of supported cryptocurrencies on the
                                  Bitbuyerx platform.
                                </div>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-header" id="faqhead2">
                                <a
                                  href="#"
                                  class="btn btn-header-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#faq2"
                                  aria-expanded="true"
                                  aria-controls="faq2"
                                >
                                  You will Continuously Deposit Every Day.
                                </a>
                              </div>

                              <div
                                id="faq2"
                                class="collapse"
                                aria-labelledby="faqhead2"
                                data-parent="#faq"
                              >
                                <div class="card-body">
                                  Anim pariatur cliche reprehenderit, enim
                                  eiusmod high life accusamus terry richardson
                                  ad squid. 3 wolf moon officia aute, non
                                  cupidatat skateboard dolor brunch. Food truck
                                  quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                                  moon tempor, sunt aliqua put a bird on it
                                  squid single-origin coffee nulla assumenda
                                  shoreditch et. Nihil anim keffiyeh helvetica,
                                  craft beer labore wes anderson cred nesciunt
                                  sapiente ea proident. Ad vegan excepteur
                                  butcher vice lomo. Leggings occaecat craft
                                  beer farm-to-table, raw denim aesthetic synth
                                  nesciunt you probably haven't heard of them
                                  accusamus labore sustainable VHS.
                                </div>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-header" id="faqhead3">
                                <a
                                  href="#"
                                  class="btn btn-header-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#faq3"
                                  aria-expanded="true"
                                  aria-controls="faq3"
                                >
                                  Depositing and Withdrawing USD via SWIFT
                                </a>
                              </div>

                              <div
                                id="faq3"
                                class="collapse"
                                aria-labelledby="faqhead3"
                                data-parent="#faq"
                              >
                                <div class="card-body">
                                  Anim pariatur cliche reprehenderit, enim
                                  eiusmod high life accusamus terry richardson
                                  ad squid. 3 wolf moon officia aute, non
                                  cupidatat skateboard dolor brunch. Food truck
                                  quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                                  moon tempor, sunt aliqua put a bird on it
                                  squid single-origin coffee nulla assumenda
                                  shoreditch et. Nihil anim keffiyeh helvetica,
                                  craft beer labore wes anderson cred nesciunt
                                  sapiente ea proident. Ad vegan excepteur
                                  butcher vice lomo. Leggings occaecat craft
                                  beer farm-to-table, raw denim aesthetic synth
                                  nesciunt you probably haven't heard of them
                                  accusamus labore sustainable VHS.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>}
          <Footer />
        </main>
      </div>
    </>
  );
}

export default PaymentGetway;
