import React, {useState, useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Arrow from "../img/ArrowRight.svg";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {getMethod, postMethod} from "../core/service/common.api";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import {useParams} from "react-router-dom";
import {setAuthorization} from "../core/service/axios";
import {Button} from "@material-ui/core";
import Select from "react-select";
import "rc-slider/assets/index.css";
import Pagination from "react-js-pagination";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

function Internaltransfer() {
  let navigate = useNavigate();
  useEffect(() => {
    getData();
  }, []);

  const options = ["Spot", "p2p", "LaunchPad", "Future", "Staking"];

  const defaultOption = options[0];

  const launchpadchoose = ["Spot", "p2p", "Future", "Staking"];
  const p2p = ["Spot", "LaunchPad", , "Future", "Staking"];
  const Spot = ["p2p", "LaunchPad", , "Future", "Staking"];
  const future = ["Spot", "p2p", "LaunchPad", "Staking"];
  const Staking = ["Spot", "p2p", "LaunchPad", "Future"];

  const [P2Pamound, setP2Pamound] = useState();
  const [P2Pamound1, setP2Pamound1] = useState();
  const [currencydatas, setcurrencydatas] = useState([]);
  const [amountvalue, setamountvalue] = useState("");
  const [Amount, SetAmount] = useState(0);
  const [currencyName, setcurrencyName] = useState("");
  const [tocuurncy, Settocuurncy] = useState("");
  const [input, setinput] = useState(true);
  const [dropdown, setdropdown] = useState(false);
  const [changeAmount, setchangeAmount] = useState(false);
  const [mobilevalidation, setmobilevalidation] = useState(false);
  const [currencyImage, setcurrencyImage] = useState(false);
  const [sideLoader,setSiteLoader]=useState(true);

  var value = Amount;
  const [amountValue1, setamountValue1] = useState(value);

  const amountValue = (e) => {
    setamountValue1(e.target.value);
    console.log(e.target.value);
  };

  const tocurrncyDrop = (value) => {
    Settocuurncy(value.label);

    if (value.label == "Spot") {
      setP2Pamound(Spot);
      setinput(false);
    }

    if (value.label == "p2p") {
      setP2Pamound(p2p);
      setinput(false);
    }

    if (value.label == "LaunchPad") {
      setP2Pamound(launchpadchoose);
      setinput(false);
    }
    if (value.label == "Future") {
      setP2Pamound(future);
      setinput(false);
    }
    if (value.label == "Staking") {
      setP2Pamound(Staking);
      setinput(false);
    }
  };
  const handleChangeDrop = (value) => {
    setamountvalue(value.label);

    if (value.label == "Spot") {
      setP2Pamound1(Spot);
      setinput(false);
    }

    if (value.label == "p2p") {
      setP2Pamound1(p2p);
      setinput(false);
    }

    if (value.label == "LaunchPad") {
      setP2Pamound1(launchpadchoose);
      setinput(false);
    }
    if (value.label == "Future") {
      setP2Pamound1(future);
      setinput(false);
    }
    if (value.label == "Staking") {
      setP2Pamound1(Staking);
      setinput(false);
    }
  };

  const choosecurrency = async (dataset) => {
    console.log(dataset.target, "datasetdataset");
    setcurrencyName(dataset.target.value);
    if (currencyName == "" && currencyName == undefined) {
    } else {
      setdropdown(true);
    }
    var obj = {
      currency: dataset.target.value,
    };
    var data = {
      apiUrl: apiService.currencybalance,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp) {
      console.log(resp.data.Image[0].Currency_image, "=-=-=-=-=");
      setcurrencyImage(resp.data.Image[0].Currency_image);
    }
    if (amountvalue == "Spot") {
      SetAmount(resp.data.Spot);
    }
    if (amountvalue == "p2p") {
      SetAmount(resp.data.P2p);
    }
    if (amountvalue == "Future") {
      SetAmount(resp.data.Future);
    }
    if (amountvalue == "LaunchPad") {
      SetAmount(resp.data.LaunchPad);
    }
    if (amountvalue == "Staking") {
      SetAmount(resp.data.Staking);
    }
  };

  const getData = async () => {
    try {
      setSiteLoader(true);
      var data = {
        apiUrl: apiService.Currencydata,
      };
      var resp = await postMethod(data);
      setSiteLoader(false);
      setcurrencydatas(resp.result);
    } catch (err) {
      console.log(err, "errgetdata");
    }
  };

  const maxValue = () => {
    setchangeAmount(true);
  };

  const unmaxValue = () => {
    setchangeAmount(false);
  };

  const popUpCloce = () => {
    console.log("working");
  };

  const formSubmit = async (dataset) => {
    if (amountValue1 == "") {
      setmobilevalidation(true);
    } else {
      setmobilevalidation(false);

      if (amountvalue != "" && tocuurncy != "" && currencyName != "") {
        if (amountvalue !== tocuurncy) {
          if (changeAmount == true) {
            var amount = Amount;
          } else {
            var amount = amountValue1;
          }
          var obj = {
            Fromcurrency: amountvalue,
            Tocurrency: tocuurncy,
            Currency: currencyName,
            Amount: amount,
          };
          var data = {
            apiUrl: apiService.Internaltranfer,
            payload: obj,
          };
          var resp = await postMethod(data);
          if (resp.Status == true) {
            toast.success(resp.Message);
            navigate("/internalhistory");
          } else {
            toast.error(resp.Message);
          }
        } else {
          toast.error("Not allowed same Wallet transaction");
        }
      } else {
        toast.error("All field Required");
      }
    }
  };
  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div class="container">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div class="my_fund_content bottm_padid">
              <div class="container">
                <div class="">
                  <div className="row justify-center">
                    <div className="col-lg-12">
                      <div className="gift-content">
                        <h2 className="text-center">Internal Transfer</h2>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="my_fund_sectin_ bg_card_section buy-gift-cad">
                        <h4 className="text-center">Transfer</h4>
                        <div className="transfer_bonx_conet">
                          {/* <p>Internal Transfer Are Free on BitbuyerX</p> */}
                          <div className="select_scriot">
                            <div className="first_coin">
                              <span>
                                <img
                                  src={require("../img/New/wallet.png")}
                                  className="logo"
                                />{" "}
                                From
                              </span>
                              {input == true ? (
                                <Dropdown
                                  options={options}
                                  name="toCurrency"
                                  onChange={handleChangeDrop}
                                  placeholder="Fiat And Spot"
                                />
                              ) : (
                                <Dropdown
                                  options={P2Pamound}
                                  name="toCurrency"
                                  onChange={handleChangeDrop}
                                  placeholder="Fiat And Spot"
                                />
                              )}
                            </div>
                            <div className="icon_secton">
                              <span>
                                {" "}
                                <i class="bi bi-arrow-down-short"></i>
                              </span>
                            </div>
                            <div className="first_coin">
                              <span>
                                <img
                                  src={require("../img/New/crypto.png")}
                                  className="logo"
                                />{" "}
                                To
                              </span>
                              {input == true ? (
                                <Dropdown
                                  name="toCurrency"
                                  options={options}
                                  placeholder="Funding"
                                  onChange={tocurrncyDrop}
                                />
                              ) : (
                                <Dropdown
                                  name="toCurrency"
                                  options={P2Pamound1}
                                  placeholder="Funding"
                                  onChange={tocurrncyDrop}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <hr className="mt-2" />
                        <div className="form mt-3">
                          <div className="form-group">
                            <label>Coin</label>
                            <div
                              className="select_label_model"
                              data-toggle="modal"
                              data-target="#search_coin"
                            >
                              {dropdown == false ? (
                                <div className="coin_text">
                                  <img
                                    src={require("../img/number1.png")}
                                    className="logo"
                                  />
                                  <span className="text_color">
                                    Select Currency
                                  </span>
                                </div>
                              ) : (
                                <div className="coin_text">
                                  <img src={currencyImage} className="logo" />
                                  <span className="text_color">
                                    {currencyName}
                                  </span>
                                </div>
                              )}
                              <i class="bi bi-caret-down-fill"></i>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="fulwisth_s">
                              {" "}
                              Amount
                              <span>
                                <strong>{Amount.toFixed(4)}</strong> Available{" "}
                              </span>
                            </label>
                            <div className="form_rel_v">
                              {changeAmount == true ? (
                                <div>
                                  <input
                                    type="number"
                                    name="Amount"
                                    class="form-control"
                                    placeholder="Enter Amount"
                                    value={Amount}
                                    onChange={amountValue}
                                    id="Amount"
                                  />
                                  {mobilevalidation == true ? (
                                    <p className="text-danger">
                                      Amount is required
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {/* <button onClick={unmaxValue}>Remove</button> */}
                                </div>
                              ) : (
                                <div>
                                  <input
                                    type="number"
                                    name="Amount"
                                    class="form-control"
                                    placeholder="Enter Amount"
                                    onChange={amountValue}
                                    id="Amount"
                                  />
                                  {mobilevalidation == true ? (
                                    <p className="text-danger">
                                      Amount is required
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {/* <button onClick={maxValue}>MAX</button> */}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="amount_price1 ">
                            <div className="balace_fee">
                              <Button
                                className="mx-auto d-block"
                                onClick={formSubmit}
                              >
                                Confirm
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
      <div class="modal" id="search_coin">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div className="coin_search_section">
                <button
                  onClick={popUpCloce}
                  type="button"
                  class="open"
                  data-dismiss="modal"
                >
                  &times;
                </button>
                {currencydatas.map((item, i) => {
                  return (
                    <div className="select_label_model">
                      <div className="coin_text" onClick={choosecurrency}>
                        <span className="text_color d-flex">
                          <img src={item.currencyImage} className="logo" />
                          <option
                            value={item.currencyName}
                            class="open my-auto"
                            data-dismiss="modal"
                          >
                            {item.currencyName}
                          </option>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Internaltransfer;
