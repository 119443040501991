import React, { useState, useEffect } from "react";
import Header from "./Header";
import Arrow from "../img/ArrowRight.svg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { setAuthToken, getAuthToken } from "../core/lib/localStorage";
import { useParams } from "react-router-dom";
import { setAuthorization } from "../core/service/axios";
import { v4 as uuid } from "uuid";
import { Button } from "@material-ui/core";
import Footer from "./Footer";

import "rc-slider/assets/index.css";
import Pagination from "react-js-pagination";

function Home() {
  const options = ["one", "two", "three"];
  const twentmmmyfive = ["25"];

  const initialvalues = {
    amount: "",
    twentmmmyfive: "",
    email: "",
    message: "",
  };
  const [formvalue, Setformvalue] = useState(initialvalues);
  const [perpage, setperpage] = useState(5);
  const [page, setpage] = useState(1);
  const [search, setsearch] = useState(1);
  const [balanceDetails, setbalanceDetails] = useState([]);
  const [balance_overallusdt, setbalance_overallusde] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [totalINRPrice, setToatalINRPrice] = useState(0);
  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [inorderPrice, setinorderPrice] = useState(0);
  const [currencylist, Setcurrencylist] = useState([]);
  const [balance, Setbalance] = useState(0);
  const [selectcurrency, Setselectcurrency] = useState([]);
  const [amountErr, SetamountErr] = useState("");
  const [emailErr, SetemailErr] = useState("");
  const [messageErr, SetmessageErr] = useState("");
  const [vallidationErr, setvalidationnErr] = useState("");
  const [value, Setvalue] = useState(0);
  const [totalamount, Settotalamount] = useState();
  const [fees, Setfees] = useState();
  const [percentages, Setpercentage] = useState(25);
  const [twentyfivepercentage, Settwentyfivepercentage] = useState(0);
  const [defaulttwentifypercentage, Setdefaulttwentifypercentage] =
    useState(false);
  const [fiftypercentages, Setfiftypercentage] = useState(50);
  const [seventyfivepercentages, Setseventyfivepercentage] = useState(75);
  const [choosecurrencies, Setchoosecurrency] = useState();
  const [currentpercentage, Setcurrentpercentage] = useState();

  const { amount, email, message } = formvalue;

  const handlechange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log(value);
    Setvalue(value);
    let formData = { ...formvalue, ...{ [name]: value } };
    Setformvalue(formData);
    var commonfeesAmount = (value * 0.5) / 100;
    // console.log(commonfeesAmount, "commonfeesAmount");
    Setfees(commonfeesAmount);
    var commontotalAmount = +value + commonfeesAmount;
    Settotalamount(commontotalAmount);
    // console.log(commontotalAmount, "commontotalAmount");
  };
  const handleperchange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log(value);
    // Setvalue(value);
    let formData = { ...formvalue, ...{ [name]: value } };
    Setformvalue(formData);
  };
  const chosechange = (e) => {
    // console.log(e);
    // console.log("--------------");

    e.preventDefault();
    const { name, value } = e.target;
    // console.log(value);
  };

  const percentage = async (e) => {
    Setcurrentpercentage("25percent");
    // console.log(e)
    e.preventDefault();
    const { name, value } = e.target;
    // console.log(e.target.value);
    // console.log(e.target);
    let formData = { ...formvalue, ...{ [name]: value } };
    Setformvalue(formData);
    // console.log(formData);
    // console.log(formvalue);
    Setdefaulttwentifypercentage(true);
    // console.log(e);
    Setpercentage();
    // console.log(Setpercentage, "----");
    // console.log(percentages, "----=-");
    var obj = {
      percentage: Number(percentages),
    };
    // console.log(obj, "======");
    if ((obj.percentage = 25)) {
      var twentyfive = (balance * 25) / 100;
      // console.log(twentyfive);
      Settwentyfivepercentage(twentyfive);
      var totalamount = parseFloat((twentyfive * 0.5) / 100);

      // console.log(totalamount,"totalamount")
      Setfees(totalamount);
      var twentyfivetotalamount = twentyfive - totalamount;
      // console.log(twentyfivetotalamount,"twentyfivetotalamount")
      Settotalamount(twentyfivetotalamount);
    } else {
      // console.log("choose percentage");
    }
  };
  const fiftypercentage = async (e) => {
    Setcurrentpercentage("50percent");
    Setdefaulttwentifypercentage(true);
    // console.log(e);
    Setfiftypercentage(50);
    // console.log(Setfiftypercentage, "----");
    // console.log(fiftypercentages, "----=-");
    var obj = {
      percentage: Number(fiftypercentages),
    };
    // console.log(obj, "======");
    if ((obj.percentage = 50)) {
      var twentyfive = (balance * 50) / 100;
      // console.log(twentyfive);
      Settwentyfivepercentage(twentyfive);
      var totalamount = parseFloat((twentyfive * 0.5) / 100).toFixed(8);
      // console.log(totalamount,"totalamount")
      Setfees(totalamount);
      var twentyfivetotalamount = twentyfive - totalamount;
      // console.log(twentyfivetotalamount,"twentyfivetotalamount")
      Settotalamount(twentyfivetotalamount);
    } else {
      // console.log("choose percentage");
    }
  };
  const seventyfivepercentage = async (e) => {
    Setcurrentpercentage("75percent");
    Setdefaulttwentifypercentage(true);
    // console.log(e);
    Setseventyfivepercentage(75);
    // console.log(Setseventyfivepercentage, "----");
    // console.log(seventyfivepercentages, "----=-");
    var obj = {
      percentage: Number(seventyfivepercentages),
    };
    // console.log(obj, "======");
    if ((obj.percentage = 75)) {
      var twentyfive = (balance * 75) / 100;
      // console.log(twentyfive);
      Settwentyfivepercentage(twentyfive);
      var totalamount = parseFloat((twentyfive * 0.5) / 100).toFixed(8);
      // console.log(totalamount,"totalamount")
      Setfees(totalamount);
      var twentyfivetotalamount = twentyfive - totalamount;
      // console.log(twentyfivetotalamount,"twentyfivetotalamount")
      Settotalamount(twentyfivetotalamount);
    } else {
      // console.log("choose percentage");
    }
  };
  const hundredpercentage = async (e) => {
    Setcurrentpercentage("100percent");
    Setdefaulttwentifypercentage(true);
    // console.log(e);
    Setseventyfivepercentage(100);
    // console.log(Setseventyfivepercentage, "----");
    // console.log(seventyfivepercentages, "----=-");
    var obj = {
      percentage: Number(seventyfivepercentages),
    };
    if ((obj.percentage = 100)) {
      var twentyfive = (balance * 100) / 100;
      // console.log(twentyfive);
      Settwentyfivepercentage(twentyfive);
      var totalamount = parseFloat((twentyfive * 0.5) / 100).toFixed(8);
      // console.log(totalamount,"totalamount")
      Setfees(totalamount);
      var twentyfivetotalamount = twentyfive - totalamount;
      // console.log(twentyfivetotalamount,"twentyfivetotalamount")
      Settotalamount(twentyfivetotalamount);
    } else {
      // console.log("choose percentage");
    }
  };
  const vallidation = async (values) => {
    const errors = {};
    // console.log("--======");

    if (!values.amount) {
      errors.amount = "Enter Your Amount";
      SetamountErr(true);
    } else {
      SetamountErr(false);
    }
    if (!values.email) {
      errors.email = "Email is a required field";
      SetemailErr(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      SetemailErr(true);
    }
    if (!values.message) {
      errors.message = "Text Any Message";
      SetmessageErr(true);
    } else {
      SetmessageErr(false);
    }
    setvalidationnErr(errors);
  };

  const preventminus = async (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const recordPerPage = 5;

  // total number of the records
  const totalRecords = 7;

  // range of pages in paginator
  const pageRange = 5;
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getUserbalance(pageNumber);
  };

  const getUserbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
      search: search,
    };

    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };
    var resp = await postMethod(data);
    // console.log(resp, "-------resp----");
    if (resp.status) {
      // console.log(resp.Message, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.Message;

      setbalanceDetails(resp.Message);
      setbalance_overallusde(resp.balance);

      settotal(resp.total);
    } else {
    }
  };
  const searchWalletList = async () => [getUserbalance(1)];
  const getPortfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    var resp = await getMethod(data);
    if (resp.status) {
      // console.log(resp.balance, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    } else {
    }
  };

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getPortfolio();
    giftcurrency();
    getUserbalance(currentPage);
  }, []);

  const giftcurrency = async () => {
    try {
      var data = {
        apiUrl: apiService.getStatkingDetails,
      };
      var resp = await postMethod(data);
      // console.log(resp.data.result)
      Setcurrencylist(resp.data.result);
    } catch {
      // console.log("[[[[");
    }
  };

  const choosecurrency = async (dataset) => {
    // console.log(dataset.target.value);

    try {
      var obj = {
        currency: dataset.target.value,
      };
      var data = {
        apiUrl: apiService.choosecurrency,
        payload: obj,
      };
      Setchoosecurrency(obj.currency);
      Setselectcurrency(obj.currency);

      // console.log("choosecurrencies",choosecurrencies)
      var resp = await postMethod(data);
      // console.log(resp.data);
      if (resp.data == 0) {
        toast.error("Insufficient Balance");
      } else {
        Setbalance(resp.data);
      }
    } catch (error) {}
  };

  const sendgift = async () => {
    try {
      // console.log("--------");
      vallidation(formvalue);

      const unique_id = uuid();
      const small_id = unique_id.slice(0, 16);
      const small = unique_id;

      var obj = {
        email: formvalue.email,
        Message: formvalue.message,
        choosecurrency: choosecurrencies,
        Redeem: small_id,
        fees: fees,
        Amount: totalamount,
        EnterAmount: value,
      };
      console.log(obj);
      if (fees != undefined) {
        var data = {
          apiUrl: apiService.tomail,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status == true) {
          navigate("/gifthistory");
          toast.success(resp.Message);
        } else {
          toast.error(resp.Message);
        }
      }
    } catch {
      // console.log("------[[[[[[[")
    }
  };
  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover">
        <Header />
        <div class="container">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div class="my_fund_content">
              <div class="container">
                <div class="">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="bg_ash bg_card_section bg_card_super">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="title_section_funds">
                              <h2>Send Gift Card</h2>
                            </div>
                          </div>
                          <div className="col-lg-8"></div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                      <div className="img_clas_sss">
                        <img
                          src={require("../img/6543-ai.png")}
                          className="logo"
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-6">
                      <div className="my_fund_sectin_ bg_card_section buy-gift-cad">
                        <div className="form ">
                          <div className="form-group">
                            <label>Select currency</label>
                            <div className="swap_form_p">
                              <input
                                type="number"
                                class="form-control hidden"
                                id="exampleInputPassword1"
                                min="0"
                                placeholder={selectcurrency}
                                name="selectcurrency"
                                value={selectcurrency}
                              />

                              <select
                                onClick={choosecurrency}
                                placeholder="---Select Currency---"
                              >
                                {currencylist.map((item, i) => {
                                  return (
                                    <option value={item.currencySymbol}>
                                      {" "}
                                      {item.currencySymbol}{" "}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {/* <div
                              className="select_label_model"
                              data-toggle="modal"
                              data-target="#search_coin"
                            >
                              <div className="coin_text">
                                 <img
                                  src={require("../img/number1.png")}
                                  className="logo"
                                />
                              <span className="text_color">
                                  {selectcurrency}
                                  <small></small>
                                </span>
                              </div> 
                             <i class="bi bi-caret-down-fill"></i>
                            </div> */}
                          </div>
                          {defaulttwentifypercentage == false ? (
                            <div className="form-group">
                              <label>Enter Gift Amount</label>
                              <input
                                type="number"
                                class="form-control"
                                min="0"
                                placeholder="Enter the amount you want to create"
                                id="Amount"
                                name="amount"
                                value={amount}
                                onChange={handlechange}
                                onKeyPress={preventminus}
                              />
                              {amountErr == true ? (
                                <p className="text-danger">
                                  {" "}
                                  {vallidationErr.amount}{" "}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <>
                              <label>Enter Gift Amount</label>
                              <input
                                type="number"
                                class="form-control"
                                min="0"
                                placeholder="Enter the amount you want to create"
                                id="Amount"
                                name="amount"
                                value={twentyfivepercentage}
                                onChange={handlechange}
                                onKeyPress={preventminus}
                              />
                            </>
                          )}
                          <div className="chooste_percent">
                            <span
                              className={
                                currentpercentage == "25percent" && "active"
                              }
                              type="number"
                              onClick={percentage}
                            >
                              25%
                            </span>
                            <span
                              type="number"
                              className={
                                currentpercentage == "50percent" && "active"
                              }
                              onClick={fiftypercentage}
                            >
                              50%
                            </span>
                            <span
                              type="number"
                              className={
                                currentpercentage == "75percent" && "active"
                              }
                              onClick={seventyfivepercentage}
                            >
                              75%
                            </span>
                            <span
                              type="number"
                              className={
                                currentpercentage == "100percent" && "active"
                              }
                              onClick={hundredpercentage}
                            >
                              100%
                            </span>
                          </div>
                          <div className="balance_div">
                            <p>
                              <small>Spot wallet balance</small>
                              <span>
                                {balance}
                                {/* <i class="bi bi-arrow-clockwise"></i> */}
                              </span>
                            </p>
                            <Link to="/internaltransfer" className="transfer">
                              Tranfer <i class="bi bi-arrow-left-right"></i>
                            </Link>
                          </div>

                          <div className="warning_note">
                            <p>
                              After confirmation, --{totalamount}
                              {selectcurrency}will be deducted from your Spot
                              wallet.
                            </p>
                          </div>
                          <div className="form-group mt-4">
                            <label>User Email</label>
                            <input
                              type="Email"
                              class="form-control"
                              placeholder="Enter Email"
                              name="email"
                              value={email}
                              onChange={handleperchange}
                            />
                            {emailErr == true ? (
                              <p className="text-danger">
                                {" "}
                                {vallidationErr.email}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="form-group mt-4">
                            <label>Message</label>
                            <textarea
                              className="fornrr"
                              name="message"
                              value={message}
                              onChange={handleperchange}
                            ></textarea>
                            {messageErr == true ? (
                              <p className="text-danger">
                                {" "}
                                {vallidationErr.message}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="amount_price">
                            <div className="balace_fee">
                              <small>
                                Fees <span>{fees}</span>
                              </small>
                              <p>
                                Total Amount: <span>{totalamount}</span>
                              </p>
                            </div>
                            <Button onClick={sendgift}>Send</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div class="modal" id="search_coin">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <div className="search_coin">
                <input
                  type="text"
                  placeholder="Search Coin"
                  onChange={(e) => setsearch(e.target.value)}
                />
                <span onClick={() => searchWalletList()}>
                  <i class="bi bi-search"></i>
                </span>
              </div>
            </div>

            <div class="modal-body">
              <div className="coin_search_section">
                {currencylist.map((item, i) => {
                  return (
                    <div className="select_label_model">
                      <div className="coin_text">
                        <img src={item.currencyImage} />
                        <span className="text_color" onClick={choosecurrency}>
                          <option value={item.currencySymbol}>
                            {item.currencySymbol}{" "}
                          </option>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
