import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import {Button} from "@material-ui/core";

import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import Pagination from "react-js-pagination";
import {toast} from "react-toastify";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import OTPInput, {ResendOTP} from "otp-input-react";
import moment from "moment";

function Home() {
  const value = {
    Subject: "",
    Category: "Choose Category",
    text: "",
  };

  const [Formdata, setFormdata] = useState(value);

  const [SubjectErr, setSubjectErr] = useState(value);
  const [CategoryErr, setCategoryErr] = useState(value);
  const [textErr, settextErr] = useState(value);

  const [formErr, setformErr] = useState("");
  const [user, setuser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [chatData, setChatData] = useState("");
  const [viewChat, setViewChat] = useState(false);
  const [chatHistory, setChatHistory] = useState("");
  const [replyMessage, setreplyMessage] = useState();
  const {Subject, Category, text} = Formdata;
  const [supportCategories, setCategories] = useState([]);

  const getItem = (e) => {
    var {name, value} = e.target;
    let check = {...Formdata, ...{[name]: value}};
    setFormdata(check);
  };

const [sideLoader,setSiteLoader]=useState(true);

  useEffect(() => {
    viewData();
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      var api = {
        apiUrl: apiService.supportCategories,
      };
      var response = await getMethod(api);
      if (response.status) {
        setCategories(response.data);
      }
    } catch (error) {}
  };

  const validate = async (condition) => {
    var error = {};
    if (condition.Subject == "") {
      error.Subject = "Subject is a required field";
      setSubjectErr(true);
    } else if (condition.Subject.length <= 5) {
      error.Subject = "Minimum 5 Characters only allowed ";
      setSubjectErr(true);
    } else if (condition.Subject.length >= 50) {
      error.Subject = "Maximum 50 Characters only allowed ";
      setSubjectErr(true);
    } else {
      setSubjectErr(false);
    }

    if (condition.text == "") {
      error.text = "Message is a required field";
      settextErr(true);
    } else if (condition.text.length < 10) {
      error.text = "Minimum 10 Characters only allowed ";
      settextErr(true);
    } else if (condition.text.length >= 250) {
      error.text = "Maximum 250 Characters only allowed ";
      settextErr(true);
    } else {
      settextErr(false);
    }

    if (condition.Category == "Choose Category") {
      setCategoryErr(true);
      error.Category = "Category is a required field";
    } else {
      setCategoryErr(false);
    }
    setformErr(error);
  };

  const recordPerPage = 5;
  // total number of the records
  const totalRecords = 15;
  // range of pages in paginator
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    viewData(pageNumber);
    setCurrentPage(pageNumber);
    // setchoosepage()
  };

  const submit = async () => {
    validate(Formdata);
    // console.log("Formdata====", Formdata);
    // return false;
    if (
      Formdata.Subject != "" &&
      Formdata.Subject.length > 5 &&
      Formdata.Subject.length < 50 &&
      Formdata.Category != "Choose Category" &&
      Formdata.text != "" &&
      Formdata.text.length > 10 &&
      Formdata.text.length < 250
    ) {
      toast.success(
        "Your ticket created successfully, Please wait for admin reply"
      );
      var data = {
        apiUrl: apiService.createdSupport,
        payload: Formdata,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);

      const obj = {
        Subject: "",
        Category: "Choose Category",
        text: "",
      };
      setFormdata(obj);
      viewData();
    } else {
    }
  };

  const viewData = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      setSiteLoader(true)
      var api = {
        apiUrl: apiService.findedSupport,
        payload: payload,
      };
      var view = await postMethod(api);
      console.log(view, "===view===");
      setSiteLoader(false)
      if (view.status) setuser(view.data.data);
      console.table(view.data.data);
      setTotalpages(view.data.total);
    } catch (error) {}
  };

  const view = async (data) => {
    try {
      console.log("data", data);
      setChatData(data);
      setViewChat(true);
      var obj = {
        _id: data._id,
      };
      var data = {
        apiUrl: apiService.getSupportDetails,
        payload: obj,
      };
      var resp = await postMethod(data);
      setChatHistory(resp.Message.reply);
      console.log(resp);
    } catch (error) {}
  };

  const back = () => {
    setViewChat(false);
  };

  const closeTicket = async (data) => {
    try {
      var obj = {
        _id: data._id,
      };
      var data = {
        apiUrl: apiService.closeTicket,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        viewData();
        toast.success(resp.Message);
      }
    } catch (error) {}
  };

  const reply = async () => {
    try {
      if (Object.keys(replyMessage).length > 0) {
        var obj = {
          message: replyMessage,
          chatId: chatData._id,
          tag: "user",
          image: "",
          status: "0",
        };
        var data = {
          apiUrl: apiService.addUserSupport,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status == true) {
          toast.success(resp.Message);
          view(chatData);
        }
      }
    } catch (error) {}
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover height_100">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
                              <>

        {viewChat == false ? (
          <div className="container pt-5 padin_zero">
            <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
              <div className="container d-flex justify-content-center flexnew_center">
                <div className="col-lg-10 padin_zero">
                  <h1 className="title_blocc">Support Ticket</h1>
                  <div className="staking_title launcpad d-block fonrm_card_s need_meanissss">
                    <div class="row">
                      <div className="col-lg-6">
                        <div class="form-group">
                          <label>Subject</label>
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Subject"
                            name="Subject"
                            value={Subject}
                            onChange={getItem}
                          />
                        </div>
                        {SubjectErr == true ? (
                          <p style={{color: "red"}}>{formErr.Subject} </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-lg-6">
                        <div class="form-group ">
                          <label>Select Category</label>
                          <select
                            class="form-control"
                            name="Category"
                            value={Category}
                            onChange={getItem}
                          >
                            <option selected>Choose Category</option>
                            {supportCategories.length > 0 ? (
                              supportCategories.map((item, i) => (
                                <option selected>{item.category}</option>
                              ))
                            ) : (
                              <option selected>Choose Category</option>
                            )}
                          </select>
                        </div>
                        {CategoryErr == true ? (
                          <p style={{color: "red"}}>{formErr.Category} </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-lg-12">
                        <div class="form-group ">
                          <label>Message</label>
                          <textarea
                            name="text"
                            value={text}
                            onChange={getItem}
                            placeholder="Message"
                          ></textarea>
                        </div>
                        {textErr == true ? (
                          <p style={{color: "red"}}>{formErr.text} </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-lg-12">
                        <div className="submit_butn_s">
                          <span>
                            {buttonLoader == false ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={submit}
                              >
                                Submit
                              </button>
                            ) : (
                              <button type="button" className="btn btn-primary">
                                loading...
                              </button>
                            )}
                            {/* <button className="btn btn-primary w-100">
                                          Submit
                                        </button> */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
              <div className="container d-flex justify-content-center flexnew_center">
                <div className="col-lg-10 padin_zero">
                  <div className="staking_title launcpad d-block fonrm_card_s need_meanissss ">
                    <div class="fixTableHead">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Ticket Id</th>
                            <th scope="col">Subject</th>
                            <th scope="col">Message</th>
                            <th scope="col">Category</th>
                            <th scope="col">Date</th>
                            <th scope="col">Action</th>
                            <th scope="col">Ticket Close</th>
                          </tr>
                        </thead>
                        <tbody>
                          {user.length > 0 ? (
                            user.map((data, i) => (
                              <tr>
                                <td>#{data._id.substring(0, 8)}</td>
                                <td>{data.subject}</td>
                                <td>{data.message}</td>
                                <td>{data.category}</td>
                                <td>
                                  {" "}
                                  {moment(data.updated_at).format("lll")}
                                </td>
                                <td onClick={() => view(data)}>view</td>
                                {data.status == "0" ? (
                                  <td
                                    onClick={() => closeTicket(data)}
                                    style={{color: "green"}}
                                  >
                                    Close
                                  </td>
                                ) : (
                                  <td style={{color: "red"}}>Close</td>
                                )}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7 mt-3">No data's founds</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <Pagination
                        itemClass="page-item" // add it for bootstrap 4
                        linkClass="page-link" // add it for bootstrap 4
                        activePage={currentPage}
                        itemsCountPerPage={recordPerPage}
                        totalItemsCount={totalPage}
                        pageRangeDisplayed={pageRange}
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container pt-5 padin_zero">
            <div className=" padin_zero">
              <div className="container d-flex justify-content-center flexnew_center contecnt_ee_pading">
                <div className="card add_padin_card w-100">
                  <div className="row">
                    <div className="col-lg-12 "></div>

                    <Button onClick={back}>
                      <i class="fa fa-arrow-left" aria-hidden="true">
                        {" "}
                        Back{" "}
                      </i>
                    </Button>
                  </div>
                </div>
                <div className="col-lg-12 padin_zero">
                  <div className="staking_title launcpad d-block fonrm_card_s need_meanissss">
                    <div class="row justify-center">
                      <div className="col-lg-6">
                        <div className="chat-interface">
                          <h1 className="bard_tirlrr">Chat</h1>

                          <div className="admin_message">
                            {chatHistory &&
                              chatHistory.map((item, i) => {
                                return item.tag == "admin" ? (
                                  <div className="messamge_section admin">
                                    <p>
                                      Admin{" "}
                                      <span>
                                        {moment(item.posted_at).format("lll")}
                                      </span>
                                    </p>
                                    <div className="content">
                                      {item.message}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="messamge_section user">
                                    <p>
                                      My Chat{" "}
                                      <span>
                                        {moment(item.posted_at).format("lll")}
                                      </span>
                                    </p>
                                    <div className="content">
                                      {item.message}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          {chatData && chatData.status == "0" ? (
                            <div className="msg_send_section">
                              <input
                                type="text"
                                placeholder="Enter your message"
                                onChange={(e) =>
                                  setreplyMessage(e.target.value)
                                }
                              />
                              <div className="actionbutton">
                                <Button onClick={reply}>
                                  <i
                                    class="fa fa-paper-plane"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className="msg_send_section">
                              <p>Your ticket was already closed</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        </>
        }
        <Footer />
      </main>
    </div>
  );
}

export default Home;
