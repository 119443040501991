import React, { useEffect } from "react";
import useState from "react-usestateref";

import { Link, useNavigate } from "react-router-dom";

import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import { Button } from "@material-ui/core";
import { Card, Grid, Paper } from "@mui/material";

import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { setAuthToken, getAuthToken } from "../core/lib/localStorage";
import OTPInput, { ResendOTP } from "otp-input-react";

function Home() {
  const initialFormValue = {
    AccountHolderName: "",
    AccountNumber: "",
    IFSCCode: "",
    BankName: "",
    BranchName: "",
    BranchAddress: "",
    gpay_number: "",
    paytm_number: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [
    AccountHolderNameErr,
    SetAccountHolderNameErr,
    AccountHolderNameErrref,
  ] = useState(false);
  const [AccountNumberErr, SetAccountNumberErr, AccountNumberErrref] =
    useState(false);
  const [IFSCCodeErr, SetIFSCCodeErr, IFSCCodeErrref] = useState(false);
  const [BankNameErr, SetBankNameErr, BankNameErrref] = useState(false);
  const [BranchNameErr, SetBranchNameErr, BranchNameErrref] = useState(false);
  const [gpayNumber, setgpayNumber, gpayNumberref] = useState(false);
  const [paytmNumber, setpaytmNumber, paytmNumberref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [BranchAddressErr, SetBranchAddressErr, BranchAddressErrref] =
    useState(false);
  const [Bankdetails, SetBankdetails] = useState([]);
  const [loading, setloading] = useState(false);
  const [sideLoader,setSiteLoader]=useState(true);

  const {
    AccountHolderName,
    AccountNumber,
    IFSCCode,
    BankName,
    BranchName,
    BranchAddress,
    gpay_number,
    paytm_number,
  } = formValue;

  const navigate = useNavigate();

  const handleChange = async (e) => {
    // console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    // console.log("formData====", formData);
    setFormValue(formData);
  };

  const validate = async (values) => {
    const errors = {};
    if (values.AccountHolderName == "") {
      errors.AccountHolderName = "Account Holder Name is required";
      SetAccountHolderNameErr(true);
    } else if (
      values.AccountHolderName.length < 3 ||
      values.AccountHolderName.length > 30
    ) {
      errors.AccountHolderName =
        "Account Holder Name must hava an 3 to 30 characters";
      SetAccountHolderNameErr(true);
    } else {
      SetAccountHolderNameErr(false);
    }

    if (values.AccountNumber == "") {
      errors.AccountNumber = "Account Number is required";
      SetAccountNumberErr(true);
    } else if (
      values.AccountNumber.length < 3 ||
      values.AccountNumber.length > 30
    ) {
      errors.AccountNumber = "Account Number  must hava an 3 to 30 Digits";
      SetAccountNumberErr(true);
    } else if (values.AccountNumber <= 0) {
      errors.AccountNumber = "Enter only the positive Numbers";
      SetAccountNumberErr(true);
    } else {
      SetAccountNumberErr(false);
    }

    if (values.IFSCCode == "") {
      errors.IFSCCode = "IFSC Code is required";
      SetIFSCCodeErr(true);
    } else if (values.IFSCCode.length < 3 || values.IFSCCode.length > 30) {
      errors.IFSCCode = "IFSC Code must have an 3 to 30 characters";
      SetIFSCCodeErr(true);
    } else {
      SetIFSCCodeErr(false);
    }

    if (values.BankName == "") {
      errors.BankName = "Bank Name is required";
      SetBankNameErr(true);
    } else if (values.BankName.length < 3 || values.BankName.length > 30) {
      errors.BankName = "Bank Name must have an 3 to 30 characters";
      SetBankNameErr(true);
    } else {
      SetBankNameErr(false);
    }

    if (values.BranchName == "") {
      errors.BranchName = "Branch Name is required";
      SetBranchNameErr(true);
    } else if (values.BranchName.length < 3 || values.BranchName.length > 30) {
      errors.BranchName = "Branch Name must have an 3 to 30 ";
      SetBranchNameErr(true);
    } else {
      SetBranchNameErr(false);
    }

    if (values.BranchAddress == "") {
      errors.BranchAddress = "Branch Address is required";
      SetBranchAddressErr(true);
    } else if (
      values.BranchAddress.length < 3 ||
      values.BranchAddress.length > 150
    ) {
      errors.BranchAddress = "Branch Address must have an 5 to 150 characters";
      SetBranchAddressErr(true);
    } else {
      SetBranchAddressErr(false);
    }

    if (values.gpay_number == "") {
      errors.gpay_number = "Gpay Details is required!";
      setgpayNumber(true);
    } else if (
      values.gpay_number.length < 10 ||
      values.gpay_number.length > 15
    ) {
      errors.gpay_number = "Gpay Number must have an 10 to 15 characters";
      setgpayNumber(true);
    } else {
      setgpayNumber(false);
    }

    if (values.paytm_number == "") {
      errors.paytm_number = "Paytm Details is required!";
      setpaytmNumber(true);
    } else if (
      values.paytm_number.length < 10 ||
      values.paytm_number.length > 15
    ) {
      errors.paytm_number = "Paytm  Number must have an 10 to 15 characters";
      setpaytmNumber(true);
    } else {
      setpaytmNumber(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  useEffect(() => {
    getbankdetails();
  }, [0]);

  // const reset=()=>{
  //   console.log("00000")
  //     formValue.AccountNumber(""),
  //      formValue.IFSCCode(""),
  //      formValue.BankName(""),
  //      formValue.BranchName (""),
  //      formValue.BranchAddress ("")
  // }

  const submitID = async (e) => {
    e.preventDefault();

    try {
      validate(formValue);

      if (
        formValue.AccountHolderName != "" &&
        AccountHolderNameErrref.current != true &&
        formValue.AccountHolderName.length >= 3 &&
        formValue.AccountHolderName.length <= 30 &&
        formValue.AccountNumber != "" &&
        AccountNumberErrref.current != true &&
        formValue.AccountNumber.length >= 3 &&
        formValue.AccountNumber.length <= 30 &&
        formValue.IFSCCode != "" &&
        formValue.IFSCCode.length >= 3 &&
        formValue.IFSCCode.length <= 30 &&
        formValue.BankName != "" &&
        formValue.BankName.length >= 3 &&
        formValue.BankName.length <= 30 &&
        formValue.BranchName != "" &&
        formValue.BranchName.length >= 3 &&
        formValue.BranchName.length <= 30 &&
        formValue.BranchAddress != "" &&
        formValue.BranchAddress.length >= 3 &&
        formValue.BranchAddress.length <= 100 &&
        paytmNumberref.current === false &&
        gpayNumberref.current === false
      ) {
        var data = {
          apiUrl: apiService.Bankdetails,
          payload: formValue,
        };

        var resp = await postMethod(data);
        // console.log(resp, "=-=-resp=--=-=");
        getbankdetails();
        toast.success(resp.Message);
        if (resp.status) {
          formValue.AccountHolderName = "";
          formValue.AccountNumber = "";
          formValue.IFSCCode = "";
          formValue.BankName = "";
          formValue.BranchName = "";
          formValue.BranchAddress = "";
          formValue.gpay_number = "";
          formValue.paytm_number = "";
        } else {
          toast.error(resp.Message);
        }
      } else {
        console.log("something went wrong");
      }
    } catch (error) {}
  };

  const getbankdetails = async () => {
    try {
      setSiteLoader(true);

      var data = {
        apiUrl: apiService.Getbankdetails,
      };
      var resp = await getMethod(data);
      // console.log("getbankdetails===", resp);
      setSiteLoader(false);
      SetBankdetails(resp.obj);
      if (resp) {
        // console.log(resp.obj, "=-=-respobj-=-=");
        // console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      } else {
        // console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      }
    } catch (error) {
      // console.log(error, "=-=error=-=-=");
    }
  };

  const defaultBank = async (editData) => {
    // console.log(editData, "=-=-=-=defaultBank");
    try {
      var data = {
        apiUrl: apiService.defaultBanks,
        payload: editData,
      };
      // console.log(data, "=-=datap-0-");
      var resp = await postMethod(data);
      // console.log(resp, "=-=resp--=");
      if (resp.status) {
        toast.success(resp.Message);
        getbankdetails();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {}
  };
  const deletebank = async (deleteData) => {
    var result = window.confirm(
      "Are you sure you want to permanently delete this bank details?"
    );

    // console.log(deleteData, "=-=-=-=deleteData");
    if (result) {
      try {
        var data = {
          apiUrl: apiService.deletbank,
          payload: deleteData,
        };
        var resp = await postMethod(data);
        if (resp.status) {
          getbankdetails();
          toast.success(resp.Message);
        } else {
          toast.error(resp.Message);
        }
      } catch (error) {}
    } else {
    }
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover height_100">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5 padin_zero">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div className="container d-flex justify-content-center flexnew_center">
              <div className="col-lg-12 padin_zero">
                <div className="bg_ash bg_card_section bg_card_super">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="title_section_funds">
                        <h2>Bank Details</h2>
                      </div>
                    </div>
                    <div className="col-lg-8"></div>
                  </div>
                </div>
                <div className="staking_title launcpad d-block fonrm_card_s need_meanissss bg_card_super">
                  <div class="row">
                    <div className="col-lg-6">
                      <div class="form-group">
                        <label>Account Holder Name</label>
                        <input
                          className="form-control"
                          placeholder="Account Holder Name"
                          name="AccountHolderName"
                          value={AccountHolderName}
                          maxLength={30}
                          minLength={3}
                          onChange={handleChange}
                        />
                      </div>
                      {AccountHolderNameErrref.current == true ? (
                        <p className="text-danger mb-3">
                          {" "}
                          {validationnErr.AccountHolderName}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Account Number</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Account Number"
                          name="AccountNumber"
                          value={AccountNumber}
                          maxLength={30}
                          minLength={3}
                          onChange={handleChange}
                        />
                      </div>
                      {AccountNumberErrref.current == true ? (
                        <p className="text-danger mb-3">
                          {" "}
                          {validationnErr.AccountNumber}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>IFSC Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="IFSC Code"
                          name="IFSCCode"
                          value={IFSCCode}
                          maxLength={30}
                          minLength={3}
                          onChange={handleChange}
                        />
                      </div>
                      {IFSCCodeErrref.current == true ? (
                        <p className="text-danger mb-3">
                          {" "}
                          {validationnErr.IFSCCode}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Bank Name</label>

                        <input
                          className="form-control"
                          placeholder="Bank Name"
                          name="BankName"
                          value={BankName}
                          maxLength={30}
                          minLength={3}
                          onChange={handleChange}
                        />
                        {BankNameErrref.current == true ? (
                          <p className="text-danger my-3">
                            {" "}
                            {validationnErr.BankName}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Branch Name</label>
                        <input
                          className="form-control"
                          placeholder="Branch Name"
                          name="BranchName"
                          maxLength={30}
                          minLength={3}
                          value={BranchName}
                          onChange={handleChange}
                        />
                        {BranchNameErrref.current == true ? (
                          <p className="text-danger my-3">
                            {" "}
                            {validationnErr.BranchName}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Branch Address</label>
                        <input
                          className="form-control"
                          placeholder="Branch Address"
                          name="BranchAddress"
                          maxLength={150}
                          minLength={3}
                          value={BranchAddress}
                          onChange={handleChange}
                        />
                        {BranchAddressErrref.current == true ? (
                          <p className="text-danger my-3">
                            {" "}
                            {validationnErr.BranchAddress}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Gpay</label>
                        <input
                          className="form-control"
                          placeholder="Gpay"
                          name="gpay_number"
                          maxLength={15}
                          value={gpay_number}
                          onChange={handleChange}
                        />
                        {gpayNumberref.current == true ? (
                          <p className="text-danger mb-3">
                            {" "}
                            {validationnErr.gpay_number}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Paytm</label>
                        <input
                          className="form-control"
                          placeholder="Paytm"
                          name="paytm_number"
                          value={paytm_number}
                          maxLength={15}
                          onChange={handleChange}
                        />
                        {paytmNumberref.current == true ? (
                          <p className="text-danger mb-3">
                            {" "}
                            {validationnErr.paytm_number}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="submit_butn_s mx-auto d-block">
                        <Button onClick={submitID}>Submit</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div className="container d-flex justify-content-center flexnew_center">
              <div className="col-lg-12 padin_zero">
                <div className="staking_title launcpad d-block fonrm_card_s need_meanissss bg_card_super">
                  <div class="fixTableHead table_section_color">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Default</th>
                          <th scope="col">Account Number</th>
                          <th scope="col">IFSC Code</th>
                          <th scope="col">Bank Name</th>
                          <th scope="col">Branch Name</th>
                          <th scopr="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Bankdetails && Bankdetails.length > 0 ? (
                          Bankdetails.map((item, i) => {
                            return (
                              <tr>
                                <td className="form-check">
                                  <input
                                    className=""
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                    value={item}
                                    checked={item.Status == true}
                                    onClick={() => {
                                      defaultBank(item);
                                    }}
                                  />{" "}
                                  <label
                                    className="form-check-label"
                                    for="flexRadioDefault2"
                                  ></label>
                                </td>
                                <td> {item && item.Accout_HolderName} </td>
                                <td> {item && item.Account_Number}</td>
                                <td> {item && item.IFSC_code}</td>
                                <td> {item && item.Bank_Name} </td>
                                <td>
                                  <div>
                                    <span>
                                      <a
                                        className="text-red"
                                        onClick={() => {
                                          deletebank(item);
                                        }}
                                      >
                                        <i class="bi bi-trash-fill"></i>
                                      </a>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <span className="w-100 text-center d-block">
                                No History found!
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
