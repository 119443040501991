import React, { useEffect } from "react";
import useState from "react-usestateref";
import "react-dropdown/style.css";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { CSVLink, CSVDownload } from "react-csv";
import Moment from "moment";

function Home() {
  Moment.locale("en");
  const [launchcurrentpage, setlaunchcurrentpage] = useState(1);
  const [launchtotalpage, setlaunchtotalpage] = useState(0);
  const navigate = useNavigate();
  const [records, setrecords] = useState([]);

  const [launchcsvfile, Setlaunchcsvfile] = useState([]);
  const [testlaunchcsvfile, Settestlaunchcsvfile] = useState([]);
  const [sideLoader,setSiteLoader]=useState(true);

  const launchrecordpage = 5;
  const launchpagerange = 5;
  const handlepage = (launchpage) => {
    // console.log(launchpage, "==-=-=-launchpage==-==-=");
    getLaunchpadHisoty(launchpage);
    setlaunchcurrentpage(launchpage);
  };
  const getLaunchpadHisoty = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      // console.log(payload, "-=-=paylaod=-=-=");
      var data = {
        apiUrl: apiService.lauchPadHistory,
        payload: payload,
      };
      setSiteLoader(true)
      // console.log(data, "-=-=data-=-=");
      var resp = await postMethod(data);
      setSiteLoader(false)
      if (resp) {
        // console.log(resp);
        var data = resp.data.data;
        // console.log(data, "--===data======-");
        setrecords(data);
        Settestlaunchcsvfile(resp.launchcsvdata);
        // console.log(resp.launchcsvdata, "-=-=data.launchcsvdata-=-=-");

        var datas = resp.data.total;
        // console.log(datas, "-=-=datas-=-=-");
        setlaunchtotalpage(datas);
        // console.log(resp.data.total, "-=-=-=data.total=-=-=");
      }
    } catch (error) {}
  };
  const launchcsv = async () => {
    Setlaunchcsvfile(testlaunchcsvfile);
  };

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getLaunchpadHisoty();
  }, [0]);
  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5 padinf_ mb-5">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padinf_">
            <div className="container d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="bg_ash bg_card_section bg_card_super">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="title_section_funds">
                        <h2>Lanchpad History</h2>
                      </div>
                    </div>
                    <div className="col-lg-8"></div>
                  </div>
                </div>
                <div className="staking_optoikn">
                  {/* //======================Staking Details==============// */}

                  <div class="">
                    <div id="LockedStaking" class="">
                      <div className="staking_title bg_card_super">
                        <div class="fixTableHead table_section_color">
                          {records && records.length > 0 ? (
                            <CSVLink
                              className="btn btn-primary float-right mr-2 mt-2 mb-3"
                              filename="Launchpad History"
                              onClick={launchcsv}
                              data={launchcsvfile}
                            >
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </CSVLink>
                          ) : (
                            <div className="btn yellow float-right mr-2 mt-2  mb-3">
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </div>
                          )}
                          <table className="table text-left ">
                            <thead>
                              <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Currency</th>
                                <th scope="col">Total Amount</th>
                                <th scope="col">Purchase ID</th>
                                <th scope="col">Fees</th>
                                <th scope="col">Sell Currency</th>
                                <th scope="col">Purchase Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {records && records.length > 0 ? (
                                records.map((item, i) => {
                                  var startdate = Moment(
                                    item.createdDate
                                  ).format("lll");
                                  return (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{item.tokenSymbol}</td>
                                      <td>
                                        {item.tokenAmount} {item.tokenSymbol}
                                      </td>
                                      <td>{item.orderid}</td>
                                      <td>
                                        {parseFloat(item.fees).toFixed(8)}{" "}
                                      </td>
                                      <td>{item.sellCurrency}</td>
                                      <td>{startdate}</td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="7">
                                    <span className="w-100 text-center d-block">
                                      No Launchpad orders found!
                                    </span>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          <div className="mt-5">
                            <Pagination
                              itemClass="page-item" // add it for bootstrap 4
                              linkClass="page-link" // add it for bootstrap 4
                              activePage={launchcurrentpage}
                              itemsCountPerPage={launchrecordpage}
                              totalItemsCount={launchtotalpage}
                              pageRangeDisplayed={launchpagerange}
                              onChange={handlepage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
