const service = {
  signup: "users/register",
  emailotpverify: "users/emailotpverify",
  resendCode: "users/resendCode",
  signin: "users/login",
  changepswdlink: "users/changepswdlink",
  verifyForgotpasslink: "users/verifyForgotpasslink",
  resetpassword: "users/forgotpassword",
  profileUpdate: "/user/profileAdd",
  getuserProfile: "users/getUserProfile",
  getSessionHisotry: "users/getSessionHisotry",
  getnotification: "users/getnotification",
  csvfile: "users/csvfile",
  getKYC: "kyc/getkyc",
  savekyc_id: "kyc/updatekyc",
  generateAddress: "address/getUserAddress",
  sendWithdrawOtp: "withdraw/sendWithdrawOtp",
  submitWithdraw: "withdraw/withdraw_submit",
  getdeposit: "withdraw/getdeposit",
  confirmWithdraw: "withdraw/withdraw_user_action",
  withdrawcsvfile: "withdraw/withdrawcsvfile",
  bankwire: "users/getbankwire",
  fiatdepositSubmit: "withdraw/fiatdeposit_submit",
  createdSupport: "support/create_ticket",
  supportCategories: "support/get_support_category",
  findedSupport: "support/ticket_list",
  closeTicket: "support/closeTicket",
  getSupportDetails: "support/getSupportDetails",
  addUserSupport: "support/addUserSupport",
  getP2Pcurrency: "p2p/p2p_currencies",
  p2pOrder: "p2p/postAddOrder",
  p2pGetOrder: "p2p/getAllp2pOrder/",
  getp2pOrder: "p2p/getp2pOrder/",
  p2pchat: "p2p/chat/",
  getp2pchat: "p2p/getp2pchat/",
  buyer_confirm: "p2p/buyer_confirm_payment/",
  seller_confirm: "p2p/seller_confirm",
  buyer_cancel: "p2p/buyer_cancel/",
  seller_cancel: "p2p/seller_cancel",
  submitfiatWithdraw: "withdraw/fiatwithdraw_submit",
  p2pOrders: "p2p/myOrders",
  getnotifyOrder: "p2p/getnotifyOrder/",
  get_site_data: "home/get_site_data",
  giftcurrency: "giftcard/giftcurrency",
  redeem: "giftcard/redeem",
  tomail: "giftcard/tomail",
  searchlist: "giftcard/searchlist",
  choosecurrency: "giftcard/choosecurrency",
  currencybalance: "users/currencybalance",
  zerobalance: "users/zerobalance",
  p2pzerobalance: "users/p2pzerobalance",
  dashboardzerobalance: "users/dashboardzerobalance",
  getCurrpairData: "trade/getCurrpairData",
  getUserBalance: "users/getUserBalance",
  getUserp2pBalance: "users/getUserp2pBalance",
  getUserstakingBalance: "users/getUserstakingBalance",
  dashboardgetUserBalance: "users/dashboardgetUserBalance",
  getparUserBalance: "trade/getparUserBalance",
  orderPlaceapi: "orderPlaceApi/orderPlace",
  addBank: "/user/addBank",
  getBank: "/user/getBank",
  defaultBanks: "users/defaultBankChoose",
  Bankdetails: "users/bankdetails",
  deletbank: "users/deletbankDetails",
  Getbankdetails: "users/Getbankdetails",
  savekyc_address: "/kyc/savekyc_Address",
  userLoginHistory: "/user/getLoginHistory",
  p2pCompareAPI: "p2p/cryptoCompare",
  buy_sell_Express: "p2p/buy_sell_Express_api",
  changePassword: "users/changePassword",
  getTfaDetials: "users/tfaDetials",
  changeTfaStatus: "users/changeTfaStatus",
  tfaVerify: "users/tfa_login",
  getBalance: "/user/getAllBalances",
  checkwallet: "/checkwallet",
  transactionHistory: "/withdraw/transactionHistory",
  resetPass: "/user/retetPassword",
  forgotepassword: "/user/forgotepassword",
  notifications: "p2p/notifications",
  p2pMyorders: "/p2p/p2pMyorders",
  p2pExpress: "/p2p/p2pExpressOrders",
  getSupportData: "/adminapi/support_category_list",
  createSupport: "/user/create_ticket",
  get_Ticket: "/user/get_Ticket",
  updateTickets: "/user/updateTicket",
  viewChat: "/user/viewChat",
  pairbycurrency: "adminapi/tradepair/viewbycurrency",
  fetch_tickers: "users/fetch_tickers",
  withdraw_history: "withdraw/withdraw_history",
  deposit_history: "withdraw/get_user_deposit",
  Depositfile: "withdraw/Depositfile",
  getActiveOrders: "users/getActiveOrders",
  marketTrades: "marketTrades/getMarketTrades",
  tradeHistory: "users/tradeHistory",
  getCancelOrders: "users/getCancelOrders",
  getCurrency: "users/getcurrency",
  getSiteDatas: "adminapi/get_sitedata",
  getStatkingDetails: "staking/getStatkingDetails",
  getUserBalanceAll: "withdraw/getUserBalanceStaking",
  confirmStaking: "staking/confirmStaking",
  getAllstakingHistory: "staking/getAllstakingHistory",
  getStakingTotal: "staking/getStakingTotal",
  claimNowapi: "staking/claimNowapi",
  submitForm: "launchPad/submitForm",
  getAllLaunchpad: "launchPad/getAllLaunchpad",
  getTokenDetails: "launchPad/getTokenDetails",
  launchPadcurrency: "launchPad/launchPadcurrency",
  getCurrencyConvertion: "launchPad/getCurrencyConvertion",
  tokenPurchase: "launchPad/tokenPurchase",
  lauchPadHistory: "launchPad/lauchPadHistory",
  cancelOrder: "orderCancelApi/cancelOrder",
  claimNowapiFlexible: "staking/claimNowapiFlexible",
  portfolioBalance: "users/portfolio_balance",
  portfolioBalancep2p: "users/portfolio_balancep2p",
  portfolio_balancestake: "users/portfolio_balancestake",
  fiatdeposit_history: "users/fiatdeposit_history",
  transfer_balance: "users/transfer_balance",
  getKYCDetails: "kyc/getKYCDetails",
  currencylist: "swap/currencylist",
  fromcurrency: "swap/fromcurrency",
  Swaphistory: "swap/Swaphistory",
  marketprice: "swap/marketprice",
  balance: "swap/balance",
  price: "swap/price",
  tomarketprice: "swap/tomarketprice",
  tocurrency: "swap/tocurrency",
  Swap: "swap/Swap",
  exchangeswap: "swap/exchangeswap",
  withdrawhistoryBycurrency: "withdraw/withdraw_history_bycurrency",
  deposithistoryBycurrency: "withdraw/deposit_history_bycurrency",
  p2p_confirm_order: "p2p/p2p_confirm_order",
  p2p_confirm_sellorder: "p2p/p2p_confirm_sellorder",
  p2p_confirm_check: "p2p/p2p_confirm_check",
  p2pHistory: "p2p/p2p_history",
  giftcardhistory: "giftcard/giftcardhistory",
  getP2Pbalance: "p2p/p2p_balance",
  allpairs: "adminapi/allpairs",
  getContent: "adminapi/getContent",
  update_profile: "users/update_profile",
  userDetails: "users/userDetails",
  raise_dispute: "p2p/dispute_order",
  getDetails: "users/getDetails",
  getReward: "users/getReward",
  getrefferal: "users/getrefferal",
  antiphishingcode: "users/antiphishingcode",
  verificationOtp: "users/verificationOtp",
  changeAntiPhising: "users/changeAntiPhising",
  Paymentgateway: "users/Paymentgateway",
  findDetails: "users/findDetails",
  EnableAnticode: "users/EnableAnticode",
  Internaltranfer: "users/confirmTranfers",
  Currencydata: "users/Currencydata",
  addBalance: "users/paypalBalance",
  getUserDetails: "users/getUserDetails",
  transferhistory: "users/transferhistory",
};
export default service;
