import React, {useEffect} from "react";
import useState from "react-usestateref";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Pagination from "react-js-pagination";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {CSVLink, CSVDownload} from "react-csv";

import Moment from "moment";

function Home() {
  Moment.locale("en");
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const navigate = useNavigate();
  const [withdrawcurrentpage, setwithdrawcurrentpage] = useState(1);
  const [withdrawtotalpage, setwithdrawTotalpages] = useState(0);
  const [withdrawcsvfile, Setwithdrawcsvfile] = useState([]);
  const [testwithdrawcsvfile, Settestwithdrawcsvfile] = useState([]);
const [sideLoader,setSiteLoader]=useState(true);

  async function fetchwithdrawcsv() {
    var data = {
      apiUrl: apiService.withdrawcsvfile,
    };
    var resp = await postMethod(data);
    await Settestwithdrawcsvfile(resp.data);
  }
  const withdrawcsv = async () => {
    await Setwithdrawcsvfile(testwithdrawcsvfile);
  };
  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: {FilPerpage: 5, FilPage: page},
    };
    setSiteLoader(true)
    var withdraw_history_list = await postMethod(data);
    setSiteLoader(false)
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
      setwithdrawTotalpages(withdraw_history_list.count);
    }
  };
  const withdrawrecordpage = 5;
  const withdrawpagerange = 5;
  const handlepagewithdraw = (page) => {
    // console.log(page, "==-=-=-p2ppage==-==-=");
    getwithdrawHistory(page);
    setwithdrawcurrentpage(page);
  };
  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    fetchwithdrawcsv();
    getwithdrawHistory(1);
    // console.log(checked);
  }, [0]);
  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };
  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5 padinf_ mb-5">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padinf_">
            <div className="container d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="bg_ash bg_card_section bg_card_super">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="title_section_funds">
                        <h2> Withdraw History</h2>
                      </div>
                    </div>
                    <div className="col-lg-8"></div>
                  </div>
                </div>
                <div className="staking_optoikn">
                  {/* //======================Staking Details==============// */}

                  <div class="">
                    <div id="LockedStaking" class="">
                      <div className="staking_title bg_card_super">
                        <div class="fixTableHead table_section_color">
                          {withdrawHistory && withdrawHistory.length > 0 ? (
                            <CSVLink
                              className="btn btn-primary float-right mr-2 mt-2 mb-3"
                              filename="Withdraw History"
                              onClick={withdrawcsv}
                              data={withdrawcsvfile}
                            >
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </CSVLink>
                          ) : (
                            <div className="btn yellow float-right mr-2 mt-2 mb-3">
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </div>
                          )}

                          <div className="table-responsive transaction">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">S.No</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Time</th>
                                  <th scope="col">Currency</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Transaction Id</th>
                                  <th scope="col">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {withdrawHistory &&
                                withdrawHistory.length > 0 ? (
                                  withdrawHistory.map((item, i) => {
                                    return (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                          {Moment(item.created_at).format(
                                            "YYYY-MM-DD"
                                          )}
                                        </td>
                                        <td>
                                          {Moment(item.created_at).format(
                                            "hh:mm a"
                                          )}
                                        </td>
                                        <td>{item.currency}</td>
                                        <td>
                                          {parseFloat(item.amount).toFixed(8)}
                                        </td>
                                        <td>
                                          <div className="copy_iconss">
                                            <span className="text_wrapaa">
                                              {item.txn_id}
                                            </span>
                                            <div className="copyicon">
                                              <span
                                                onClick={() =>
                                                  copy(item.txn_id)
                                                }
                                              >
                                                <i
                                                  class="fa fa-clone"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          {item.status == "Completed" ? (
                                            <span className="text-green">
                                              {item.status}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {item.status !== "Completed" ? (
                                            <span className="text-red">
                                              {item.status}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <td colSpan="7">
                                    <span className="w-100 text-center d-block">
                                      No Withdraw History Found!
                                    </span>
                                  </td>
                                )}
                              </tbody>
                            </table>
                          </div>

                          <div className="mt-5">
                            <Pagination
                              itemClass="page-item" // add it for bootstrap 4
                              linkClass="page-link" // add it for bootstrap 4
                              activePage={withdrawcurrentpage}
                              itemsCountPerPage={withdrawrecordpage}
                              totalItemsCount={withdrawtotalpage}
                              pageRangeDisplayed={withdrawpagerange}
                              onChange={handlepagewithdraw}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
