import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import useState from "react-usestateref";
import Dropdown from "react-dropdown";
import "rc-slider/assets/index.css";
import Header from "./Header";
import {Button} from "@material-ui/core";
import axios from "axios";
import apiService from "../core/service/detail";
import {getMethod, postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import OTPInput, {ResendOTP} from "otp-input-react";

import Pagination from "react-js-pagination";
import "react-dropdown/style.css";
var moment = require("moment");

function Home() {
  const [authToken, setauthToken] = useState(false);
  const [upcommingToken, setupcommingToken] = useState([]);
  const [inprogressToken, setinprogressToken] = useState([]);
  const [expiredToken, setexpiredToken] = useState([]);
  const [kycstatus, setkycstatus] = useState(0);
  const [allpairs, Setallpairs] = useState([]);
  const [firstcurrency, Setfirstcurrency] = useState([]);
  const [fromcurrency, Setfromcurrency] = useState([]);
  const [tocurrency, Settocurrency] = useState([]);
  const [exchangetocuurency, Setexchangetocuurency] = useState([]);
  const [defaulttocurrency, Setdefaulttocurreny] = useState([]);
  const [defaultfromcurrency, Setdefaultfromcurrency] = useState([]);
  const [marketprice, Setmarketprice] = useState([]);
  const [fromcurrencymarketprice, Setfromcurrencymarketprice] = useState([]);
  const [exchange, Setexchange] = useState(true);
  const [currency, Setcurrency] = useState(false);
  const [currentprice, Setcurrentprice] = useState();
  const [inputprice, Setinputprice] = useState();
  const [swapfee, Setswapfee] = useState(0);
  const [fromobj, setfromobj] = useState();
  const [toobj, Settoobj] = useState();
  const [deafultfromcurrency, Setdeafultfromcurrency] = useState();
  const [defaulttoobj, Setdefaulttoobj, defaulttoobjref] = useState();
  const [summary, Setsummary, summaryref] = useState();
  const [exchangefromobj, Setexchangefromobj] = useState();
  const [exchangetoobj, Setexchangetoobj] = useState();
  const [currencybalance, Setcurrencybalance] = useState(0);
  const [enterAmount, SetenterAmount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const [swapinghistory, Setswaphistory] = useState();
  const [validationnErr, setvalidationnErr] = useState("");
  const [inputvallid, setinputvallid] = useState();
  const [fees, Setfees] = useState(0);
  const [data, Setdata] = useState(0);
  const [sideLoader,setSiteLoader]=useState(true);

  const initialFormValue = {
    number: 0,
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const {number} = formValue;
  const navigate = useNavigate();

  const handleChange = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    setFormValue(formData);
    var currencyprice = parseFloat(value * currentprice).toFixed(8);
    Setinputprice(currencyprice);
    SetenterAmount(value);
    var price = parseFloat((value * 0.2) / 100);
    Setfees(price);
    var swapfee = parseFloat((currencyprice * 0.2) / 100).toFixed(8);
    Setswapfee(swapfee);
  };

  useEffect(() => {
    getAllLaunchpad();
    let getToken = localStorage.getItem("user_token");
    if (getToken != "" && getToken != undefined && getToken != null) {
      getKycData();
      setauthToken(true);
    } else {
      setauthToken(false);
    }
  }, [0]);

  const getAllLaunchpad = async () => {
    try {
      var data = {
        apiUrl: apiService.getAllLaunchpad,
      };
      var resp = await getMethod(data);
      var responseData = resp.data;
      if (resp.status == true) {
        setupcommingToken(responseData.UpcomingTokens);
        setinprogressToken(responseData.inprogressToken);
        setexpiredToken(responseData.expiredTokens);
      } else {
      }
    } catch (error) {}
  };

  const getKycData = async () => {
    try {
      var data = {
        apiUrl: apiService.getKYCDetails,
      };
      var resp = await getMethod(data);
      if (resp.status == true) {
        var responseData = resp.data.kycstatus;
        setkycstatus(responseData);
      } else {
        var responseData = 0;
        setkycstatus(responseData);
      }
    } catch (error) {
      var responseData = 0;
      setkycstatus(responseData);
    }
  };

  const buyToken = async (data) => {
    try {
      if (kycstatus == 1) {
        navigate("/launchbuy/" + data._id + "_symbol_" + data.symbol);
      } else {
        navigate("/kyc");
      }
    } catch (error) {
      toast.error("Please try later");
    }
  };

  const kycCheck = async (data) => {
    try {
      if (kycstatus == 1) {
        navigate("/launchpadapplay");
      } else {
        navigate("/kyc");
      }
    } catch (error) {
      toast.error("Please try later");
    }
  };

  useEffect(() => {
    Fromcurrency();
    // price();
    // Balance();
    swaphistory();
    choosetocurrency();
    // market price();
    //  Tocurrency();
    currencies();
    currencylist();
    marketPrices();
  }, []);

  // const Inputexchange = async () => {
  //   setfromobj(toobj.paircurrency)
  //   Settoobj(fromobj.pair)

  // };

  const exputchange = async () => {
    marketPrices();
    Tomarketprice();
    Fromcurrency();
    Setexchange(true);
  };

  const currencylist = async () => {
    try {
      var data = {
        apiUrl: apiService.currencylist,
      };
      var resp = await getMethod(data);
      Setallpairs(resp.data);

      // console.log(resp.data);
    } catch {}
  };

  const Fromcurrency = async () => {
    try {
      var data = {
        apiUrl: apiService.fromcurrency,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      Setfromcurrency(resp.data);
      marketPrices(resp.data[0].fromcurrency);
      // marketPrices(tocurrency !== '' ? tocurrency : resp.data[0].tocurrency)
      // console.log(resp.data[0].tocurrency,"=-=-=-=-=-=-=resp.data[0].-------------fromcurrency=-=-=-=-=-=-=-=-=-=-=")
    } catch {}
  };

  const Tocurrency = async () => {
    try {
      var data = {
        apiUrl: apiService.tocurrency,
      };
      var resp = await getMethod(data);
      const datas = resp.data;
      Setdefaulttocurreny(datas);
      // console.log(resp.data, "respdata");
      // console.log(datas, "respdata");
      Tomarketprice(resp.data1[0].tocurrency);
      // console.log(defaulttocurrency, "=-=-=-=");
    } catch {}
  };
  const choosedefaultfromcurrency = async (dataset) => {
    try {
      var obj = {
        paircurrency:
          dataset.target.value !== "" ? dataset.target.value : dataset,
      };
      Settoobj(obj);
      // console.log(
      //   obj,
      //   "=-=-=++++++++++++====-=-=-=++========-=-=-=+++++++++++++++++=-=-="
      // );
      var API = axios
        .get(
          "https://min-api.cryptocompare.com/data/pricemulti?fsyms=" +
            exchangefromobj.pair +
            "&tsyms=" +
            obj.paircurrency
        )
        .then((response) => {
          const prices = response.data;
          if (prices.Data) {
            toast.error("The Market Is Not Available For The Swaping");
          }
          Setcurrentprice(prices[exchangefromobj.pair][obj.paircurrency]);
        })

        .catch((error) => console.log(error));
    } catch {}
  };

  const choosetocurrency = async (dataset) => {
    try {
      var obj = {
        paircurrency: dataset.target !== "" ? dataset.target.value : dataset,
      };

      Setexchangetoobj(obj);

      var API = axios
        .get(
          "https://min-api.cryptocompare.com/data/pricemulti?fsyms=" +
            fromobj.pair +
            "&tsyms=" +
            obj.paircurrency
        )
        .then((response) => {
          const prices = response.data;
          if (prices.Data) {
            toast.error("The Market Is Not Available For The Swaping");
          }
          Setcurrentprice(prices[fromobj.pair][obj.paircurrency]);
          var currencyprice = parseFloat(
            enterAmount * prices[fromobj.pair][obj.paircurrency]
          ).toFixed(8);
          Setinputprice(currencyprice);
        })
        .catch((error) => console.log(error));
    } catch {}
  };

  const marketPrices = async (dataset) => {
    try {
      var obj = {
        pair: dataset.target ? dataset.target.value : dataset,
      };

      var data = {
        apiUrl: apiService.marketprice,
        payload: obj,
      };

      var resp = await postMethod(data);

      if (resp.status == true) {
        Setdata(resp.data[0].tocurrency);

        setfromobj(obj);

        var obj1 = {
          pair1: resp.data[0].tocurrency,
          //  pair1:  dataset
        };
        Setdefaulttoobj(obj1);
        Setsummary(defaulttoobjref.current.pair1);

        Setfromcurrencymarketprice(resp.data);
        Settocurrency(resp.data);
        Setcurrencybalance(resp.Balance);

        axios
          .get(
            "https://min-api.cryptocompare.com/data/pricemulti?fsyms=" +
              obj.pair +
              "&tsyms=" +
              obj1.pair1
          )
          .then((response) => {
            const prices = response.data;
            if (prices.Data) {
              console.log(response.data, "response.data");
              toast.error("The Market Is Not Available For The Swaping");
            }
            Setcurrentprice(prices[obj.pair][obj1.pair1]);
            var currencyprice = parseFloat(
              enterAmount * prices[obj.pair][obj1.pair1]
            ).toFixed(8);
            Setinputprice(currencyprice);
            console.log(prices[obj.pair][obj1.pair1], "logs");
          })
          .catch((error) => console.log(error));
      }
    } catch {}
  };

  const Tomarketprice = async (dataset) => {
    var obj = {
      pair: dataset.target ? dataset.target.value : dataset,
    };
    Setexchangefromobj(obj);

    var data = {
      apiUrl: apiService.tomarketprice,
      payload: obj,
    };

    var resp = await postMethod(data);
    if (resp.status == true) {
      var obj1 = {
        pair1: resp.data[0].fromcurrency,
      };
      Setdeafultfromcurrency(obj1);
      Setcurrencybalance(resp.Balance);

      Setdefaultfromcurrency(resp.data);
      console.log(resp.data, "=-=-=-=-=-=-=respdatapopop=-=--=-=-=-=-=-=-=");
      var API = axios
        .get(
          "https://min-api.cryptocompare.com/data/pricemulti?fsyms=" +
            obj.pair +
            "&tsyms=" +
            obj1.pair1
        )
        .then((response) => {
          const prices = response.data;
          if (prices.Data) {
            toast.error("The Market Is Not Available For The Swaping");
          }
          Setcurrentprice(prices[obj.pair][obj1.pair1]);
          var currencyprice = parseFloat(enterAmount * currentprice).toFixed(8);
          Setinputprice(currencyprice);
        })
        .catch((error) => console.log(error));
    }
  };

  const currencies = async () => {
    if (currency == true) {
      Setcurrency(true);
    } else {
      Setcurrency(false);
    }
  };

  const recordPerPage = 5;
  const pageRange = 5;

  const handlePageChange = async (PageNumber) => {
    swaphistory(PageNumber);
    setCurrentPage(PageNumber);
  };

  const swap = async () => {
    try {
      var obj = {
        fromcurrency: fromobj.pair,
        tocurrency: exchangetoobj
          ? exchangetoobj.paircurrency
          : defaulttoobj.pair1,
        Amount: inputprice,
        fees: fees.toFixed(4),
        SwapFee: swapfee,
        TotalAmount: enterAmount,
      };
      console.log(obj, "obj");
      var balance = Number(obj.TotalAmount);
      if (obj.TotalAmount) {
        if (balance > 0.0001) {
          if (obj.Amount != 0) {
            var data = {
              apiUrl: apiService.Swap,
              payload: obj,
            };

            var resp = await postMethod(data);
            if (resp.status == true) {
              toast.success(resp.Message);
              navigate("/swaphistory");
              swaphistory();
              Setcurrencybalance(resp.data);
            } else {
              toast.error(resp.Message);
            }
          } else {
            toast.error("Invalid Fields");
          }
        } else {
          toast.error("Yours Enter Amount has been not Exceed 0.0001");
        }
      } else {
        toast.error("Invalid Fields");
      }
    } catch (error) {
      toast.error("Invalid Fields");
    }
  };
  const swaphistory = async (page) => {
    var obj = {
      perpage: 5,
      page: page,
    };
    var data = {
      apiUrl: apiService.Swaphistory,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      Setswaphistory(resp.Swapdata.data);
      setTotalpages(resp.Swapdata.total);
    }
  };
  const exchangeswap = async () => {
    try {
      var obj = {
        fromcurrency: exchangefromobj.pair,
        tocurrency: toobj ? toobj.paircurrency : deafultfromcurrency.pair1,
        Amount: inputprice,
        fees: fees,
        SwapFee: swapfee,
        TotalAmount: enterAmount,
      };
      console.log(obj, "obj");
      var balance = Number(obj.TotalAmount);
      console.log(obj, "obj");
      if (obj.TotalAmount) {
        if (balance > 0.0001) {
          if (obj.Amount != 0) {
            var data = {
              apiUrl: apiService.exchangeswap,
              payload: obj,
            };
            var resp = await postMethod(data);
            if (resp.status == true) {
              toast.success(resp.Message);
              swaphistory();
              Setcurrencybalance(resp.data);
              navigate("/swaphistory");
            }
          } else {
            toast.error("Invalid Fields");
            navigate("/Swap");
          }
        } else {
          toast.error("Yours Enter Amount has been not Exceed 0.0001");
        }
      } else {
        toast.error("Invalid Fields");
      }
    } catch (error) {}
  };

  const options = ["one", "two", "three"];
  return (
    <div className="">
      <div class="modal" id="swapmodel">
        <div class="modal-dialog modal-lg  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Swap Rewards of Provided Pairs</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              <div className="row">
                <div className="col-lg-5">
                  <Dropdown options={options} placeholder="Select an Pair" />
                </div>
                <div className="col-lg-7"></div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-6">
                  <div class="fixTableHead">
                    <table>
                      <thead>
                        <tr>
                          <th>Pair</th>
                          <th>Rate</th>
                        </tr>
                      </thead>

                      <tbody>
                        {allpairs && allpairs.length > 0
                          ? allpairs.map((item, i) => {
                              return (
                                <tr>
                                  <td>{item.currencysymbol}</td>
                                  <td>45%</td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div className="container d-flex justify-content-center padin_zero">
              <div className="col-lg-8">
                <div className="staking_title launcpad d-block">
                  <div>
                    <h1 className="text-center mb-3">Swap</h1>
                    {/* <p>A Token Launch Platform For Transfermative project</p> */}
                  </div>
                  <div className="form-swap register_login ">
                    <div className="row">
                      <div className="col-lg-6 ">
                        {/* <h1>Swap</h1> */}

                        <form>
                          {exchange == true ? (
                            <>
                              <div>
                                <div class="form-group ">
                                  <label className="mb-5">
                                    From{" "}
                                    <span>
                                      Balance:{currencybalance.toFixed(4)}
                                    </span>
                                  </label>
                                  <div className="swap_form_s">
                                    <input
                                      type="number"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      min="0"
                                      aria-describedby="emailHelp"
                                      placeholder="From Currency"
                                      name="number"
                                      value={number}
                                      onChange={handleChange}
                                    />
                                    <select onChange={marketPrices}>
                                      {fromcurrency && fromcurrency.length > 0
                                        ? fromcurrency.map((item, i) => {
                                            // console.log(fromcurrency,"=-=-=-=-=-=-=-=-=")
                                            return (
                                              <option value={item.fromcurrency}>
                                                {item.fromcurrency}
                                              </option>
                                            );
                                          })
                                        : ""}
                                      {inputvallid == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.enterAmount}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div>
                                  <div className="span_swap">
                                    <i
                                      // onClick={Inputexchange}
                                      class="bi bi-arrow-left-right"
                                    ></i>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>To</label>
                                  <div className="swap_form_s">
                                    <input
                                      type="number"
                                      class="form-control"
                                      id="exampleInputPassword1"
                                      placeholder="To Currency"
                                      name="number"
                                      value={inputprice}
                                    />
                                    <select onChange={choosetocurrency}>
                                      {tocurrency.map((item, i) => {
                                        return (
                                          <option value={item.tocurrency}>
                                            {item.tocurrency}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="buton">
                                <Button
                                  className="btn btn-primary w-100"
                                  onClick={swap}
                                >
                                  Swap
                                </Button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <div class="form-group">
                                  <label>
                                    From <span>Balance:{currencybalance}</span>
                                  </label>
                                  <div className="swap_form_s">
                                    <input
                                      type="number"
                                      class="form-control"
                                      id="exampleInputPassword1"
                                      min="0"
                                      placeholder="From Currency"
                                      name="number"
                                      value={number}
                                      onChange={handleChange}
                                    />

                                    <select onChange={Tomarketprice}>
                                      {defaulttocurrency.map((item, i) => {
                                        return (
                                          <option value={item}>{item}</option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>

                                <div>
                                  <div className="span_swap">
                                    <i
                                      onClick={exputchange}
                                      class="bi bi-arrow-left-right"
                                    ></i>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>
                                    To
                                    {/* <span>Balance:0000</span> */}
                                  </label>
                                  <div className="swap_form_s">
                                    <input
                                      type="number"
                                      class="form-control"
                                      id="exampleInputPassword1"
                                      placeholder="TO Currency"
                                      name="number"
                                      value={inputprice}
                                    />
                                    <select
                                      onChange={choosedefaultfromcurrency}
                                    >
                                      {defaultfromcurrency &&
                                      defaultfromcurrency.length > 0 ? (
                                        defaultfromcurrency.map((item, i) => {
                                          return (
                                            <option value={item.fromcurrency}>
                                              {item.fromcurrency}
                                            </option>
                                          );
                                        })
                                      ) : (
                                        <option>SHIB</option>
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="buton">
                                <button
                                  className="w-100"
                                  onClick={exchangeswap}
                                >
                                  Swap
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                      <div className="col-lg-6">
                        <div className="summer_swap">
                          <h4>Summary</h4>
                          <p>
                            <span>Price</span>
                            <span>
                              {" "}
                              1 {fromobj ? fromobj.pair : "ETH"} ={" "}
                              {currentprice}{" "}
                              {exchangetoobj
                                ? exchangetoobj.paircurrency
                                : summaryref.current}{" "}
                            </span>
                          </p>
                          <p>
                            <span>Swap fee</span>
                            <span>0.002 %</span>
                          </p>
                          <p>
                            <span> Estimation fee</span>
                            <span> {fees.toFixed(4)} </span>
                          </p>
                          <p>
                            <span>Total</span>
                            <span>
                              {" "}
                              {swapfee == 0
                                ? "-"
                                : (+formValue.number + +fees).toFixed(4)}
                            </span>
                          </p>
                          <p>
                            <span>Receive</span>
                            <span>
                              {" "}
                              {(+formValue.number * currentprice).toFixed(
                                4
                              )}{" "}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
      </main>
    </div>
  );
}

export default Home;
