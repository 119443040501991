import React, {useEffect, useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "rc-slider/assets/index.css";
import Header from "./Header";
import {Button} from "@material-ui/core";
import {Card, Grid, Paper} from "@mui/material";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import OTPInput, {ResendOTP} from "otp-input-react";
import useState from "react-usestateref";
import {format} from "date-fns";
import Moment from "moment";
import {getMethod} from "../core/service/common.api";
import {socket} from "./context/socket";
import {env} from "../core/service/envconfig";
function P2pView() {
  const initialFormValue = {
    message: "",
    file: "",
    type: "",
    orderId: "",
    p2porderId: "",
  };

  const p2pFormValue = {
    qty: "",
    total: "",
  };

  const [p2pData, setp2pData, p2pDataref] = useState("");
  const [orderType, setorderType, orderTyperef] = useState("");
  const [p2pdate, setp2pDate, p2pdateref] = useState("");
  const [profileData, setprofileData, profileDataref] = useState("");
  const [formValue, setFormValue, formValueref] = useState(initialFormValue);
  const [p2pfile, setp2pfile, p2pfileref] = useState("");
  const [chatloading, setchatloading] = useState(false);
  const {message, file, type, orderId, p2porderId} = formValue;
  const [p2pchat, setp2pchat, p2pchatref] = useState("");
  const [interval, setintervalchat, intervalref] = useState("");
  const [runningTimer, setRunningTimer] = useState(false);
  const [bankData, setbankData, bankDataref] = useState("");
  const [socket_token, set_socket_token, socketref] = useState("");
  const [notifyp2pData, setnotifyp2pData, notifyp2pDataref] = useState("");
  const [kycstatus, setkycstatus] = useState(0);
  const [p2pformValue, setp2pFormValue, p2pformValueref] = useState(
    p2pFormValue
  );
  const [notifymessage, setnotifymessage, notifymessageref] = useState("");
  const [confirmp2pcheck, setconfirmp2pcheck, confirmp2pcheckref] = useState(
    []
  );
  const {qty, total} = p2pformValue;
  const [p2pbalance, setp2pbalance, p2pbalanceref] = useState("");
  const [chattype, setchattype, chattyperef] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    let socket_token = localStorage.getItem("socket_token");
    let socketsplit = socket_token.split("_");
    socket.connect();
    getProfile();
    getKycData();
    socket.off("socketResponse");
    socket.on("socketResponse" + socketsplit[0], function (res) {
      if (res.Reason == "p2pchat") {
        getp2pChat();
      } else if (res.Reason == "notify") {
        setnotifymessage(res.Message);
        // toast.success(res.Message, {
        //   toastId: "3",
        // });
        getp2pOrder();
        getp2pconfirmOrder();
      }
    });

    // socket.once("socketResponse" + socketsplit[0], function (res) {
    //   console.log("p2p socket response====",res);
    //   if (res.Reason == "notify") {

    //   setnotifymessage(res.Message);
    //     toast.success(res.Message);
    //   // if (document.getElementById("success_msg")) {
    //   //   document.getElementById("success_msg").addEventListener(
    //   //     "click",
    //   //     (e) => {
    //   //       console.log("success_msg");
    //   //     },
    //   //     false
    //   //   );
    //   // }
    //     getp2pOrder();
    //   }
    // });
  }, []);

  useEffect(() => {
    return () => {
      socket.disconnect();
    };
  }, []);

  const closePopup = async () => {};

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setprofileData(resp.data);
        getp2pOrder();
        getp2pconfirmOrder();
        getp2pChat();
      }
    } catch (error) {}
  };

  const getp2pOrder = async () => {
    console.log(window.location.href.split("/").pop(), "p2pview");
    var onj = {
      orderId: window.location.href.split("/").pop(),
    };
    var data = {
      apiUrl: apiService.getp2pOrder,
      payload: onj,
    };
    var resp = await postMethod(data);
    // console.log(resp.Message, "-=-=-resp=-=-");
    //console.log("profileDataref.current._id====",profileDataref.current._id.toString());
    //  console.log("resp.Message.userId.toString()====",resp.Message.userId.toString());
    if (resp) {
      var data = resp.Message;
      setp2pData(resp.Message);
      setp2pbalance(resp.p2pbalance);
      if (resp.bank) {
        setbankData(resp.bank);
        // console.log("bankdata", bankDataref.current.gpay);
      }

      if (resp.Message.orderType == "buy") {
        if (
          profileDataref.current._id.toString() ==
          resp.Message.userId.toString()
        ) {
          setorderType("Buy");
        } else {
          setorderType("Sell");
        }
      } else {
        if (
          profileDataref.current._id.toString() ==
          resp.Message.userId.toString()
        ) {
          setorderType("Sell");
        } else {
          setorderType("Buy");
        }
      }
      // console.log("orderTyperef====", orderTyperef.current);
      var dates = Moment(resp.Message.createdAt).format("DD.MM.YYYY h:m a");
      setp2pDate(dates);
    }
  };

  const getp2pconfirmOrder = async () => {
    var onj = {
      orderId: window.location.href.split("/").pop(),
    };
    var data = {
      apiUrl: apiService.p2p_confirm_check,
      payload: onj,
    };
    var resp = await postMethod(data);
    // console.log(resp.Message, "-=-=-resp=-=-");
    if (resp) {
      var data = resp.Message;
      setconfirmp2pcheck(resp.Message);
    }
  };
  const handleChange = async (e) => {
    // console.log("e====", e);
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    // console.log("formData====", formData);
    setFormValue(formData);
  };

  const handleChange_confirm = async (e) => {
    // console.log("e====", e);
    e.preventDefault();
    // console.log("e.target===", e.target.innerText);
    if (e.target.innerText == "Confirm Payment") {
      buyer_confirmation("Completed");
    } else if (e.target.innerText == "Confirm Release") {
      seller_confirmation("Completed");
    }
  };

  const handleChange_buycancel = async (e) => {
    // console.log("e====", e);
    e.preventDefault();
    // console.log("e.target===", e.target.innerText);
    buyer_cancel();
    navigate("/p2p");
  };

  const handleChange_sellcancel = async (e) => {
    // console.log("e====", e);
    e.preventDefault();
    seller_cancel();
  };
  const imageUpload = (type, val) => {
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    if (
      fileExtension != "png" &&
      fileExtension != "jpg" &&
      fileExtension != "jpeg" &&
      fileExtension != "pdf" &&
      fileExtension != "doc" &&
      fileExtension != "docx"
    ) {
      toast.error(
        "File does not support. You must use .png, .jpg,  .jpeg,  .pdf,  .doc,  .docx "
      );
      return false;
    } else if (fileSize > 1000000) {
      toast.error("Please upload a file smaller than 1 MB");
      return false;
    } else {
      const data = new FormData();
      data.append("file", val);
      data.append("upload_preset", "mz5oapvg");
      data.append("cloud_name", "dxknk0rio");
      // console.log("formdata===", data);
      fetch(
        "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
        {
          method: "post",
          body: data,
        }
      )
        .then((resp) => resp.json())
        .then((data) => {
          // console.log("cloudinary upload===", data);
          if (type == "file") {
            setp2pfile(data.secure_url);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  const submitChat = async () => {
    try {
      formValue.file = p2pfileref.current;
      formValue.orderId = window.location.href.split("/").pop();
      formValue.p2porderId = p2pDataref.current._id;
      formValue.type =
        profileDataref.current._id.toString() ==
        p2pDataref.current.userId.toString()
          ? "advertiser"
          : "user";

      if (formValue.message != "" || formValue.file != "") {
        setchatloading(true);
        var data = {
          apiUrl: apiService.p2pchat,
          payload: formValue,
        };
        var resp = await postMethod(data);
        if (resp.status) {
          setchatloading(false);
          getp2pChat();
          setRunningTimer(true);
          formValue.message = "";
          setp2pfile("");
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter message or attach file");
      }
    } catch (error) {}
  };

  const getp2pChat = async () => {
    var onj = {
      orderId: window.location.href.split("/").pop(),
    };
    var data = {
      apiUrl: apiService.getp2pchat,
      payload: onj,
    };
    var resp = await postMethod(data);
    // console.log(resp.Message, "-=-=-resp=-=-");
    if (resp) {
      setchattype(resp.data);
      setp2pchat(resp.Message);
      console.log(p2pchatref.current, "p2pchatref");
      console.log(chattyperef.current, "type");
    }
  };

  const buyer_confirmation = async (status) => {
    try {
      var obj = {
        orderId: window.location.href.split("/").pop(),
        status: status,
      };
      var data = {
        apiUrl: apiService.buyer_confirm,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getp2pChat();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {}
  };

  const seller_confirmation = async (status) => {
    try {
      var obj = {
        orderId: window.location.href.split("/").pop(),
        status: status,
      };
      var data = {
        apiUrl: apiService.seller_confirm,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getp2pOrder();
        getp2pconfirmOrder();
        setRunningTimer(false);
        clearInterval(intervalref.current);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {}
  };

  const buyer_cancel = async (status) => {
    try {
      var obj = {
        orderId: window.location.href.split("/").pop(),
        status: status,
      };
      var data = {
        apiUrl: apiService.buyer_cancel,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getp2pChat();
        navigate("/p2p");
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {}
  };

  const seller_cancel = async (status) => {
    try {
      var obj = {
        orderId: window.location.href.split("/").pop(),
        status: status,
      };
      var data = {
        apiUrl: apiService.seller_cancel,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getp2pOrder();
        getp2pconfirmOrder();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {}
  };

  const copy_to_clipboard = async (type, text) => {
    navigator.clipboard.writeText(text);
    toast.success(type + " copied successfully");
  };

  const getKycData = async () => {
    try {
      var data = {
        apiUrl: apiService.getKYCDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        if (resp.data.kycstatus == 1) {
        } else {
          navigate("/kyc");
        }
      }
    } catch (error) {
      var responseData = 0;
      setkycstatus(responseData);
    }
  };

  const confirm_handleChange = async (e) => {
    // console.log("e====", e);
    e.preventDefault();
    const {name, value} = e.target;
    let p2pformData = {...formValue, ...{[name]: value}};
    setp2pFormValue(p2pformData);
    // console.log("p2pformValueref.current.qty===", p2pformValueref.current.qty);
    if (p2pformValueref.current.qty > 0) {
      var order_qty = p2pformValueref.current.qty;
      var min_qty = p2pDataref.current.fromLimit;
      var max_qty = p2pDataref.current.toLimit;
      // console.log("min_qty===", min_qty);
      // console.log("max_qty===", max_qty);
      // console.log("order_qty===", order_qty);
      if (
        order_qty < p2pDataref.current.fromLimit ||
        order_qty > p2pDataref.current.toLimit
      ) {
        toast.error(
          "Please enter quantity between " + min_qty + " and " + max_qty + ""
        );
      } else {
        var total = order_qty * p2pDataref.current.price;
        p2pformValueref.current.total = parseFloat(total).toFixed(2);
        // console.log("p2pformData====", p2pformValueref.current);
      }
    } else {
      toast.error("Please enter valid quantity");
    }
  };

  const confirm_order_buy = async () => {
    try {
      var obj = {};
      obj.qty = p2pformValueref.current.qty;
      obj.total = p2pformValueref.current.total;
      obj.orderId = window.location.href.split("/").pop();
      obj.p2porderId = p2pDataref.current._id;
      obj.type = "buy";

      if (obj.qty != "" || obj.total != "") {
        var data = {
          apiUrl: apiService.p2p_confirm_order,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status) {
          toast.success(resp.Message);
          getp2pOrder();
          getp2pconfirmOrder();
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter quantity");
      }
    } catch (error) {}
  };

  const confirm_order_sell = async () => {
    try {
      var obj = {};
      obj.qty = p2pformValueref.current.qty;
      obj.total = p2pformValueref.current.total;
      obj.orderId = window.location.href.split("/").pop();
      obj.p2porderId = p2pDataref.current._id;
      obj.type = "sell";

      if (obj.qty != "" || obj.total != "") {
        var data = {
          apiUrl: apiService.p2p_confirm_sellorder,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status) {
          toast.success(resp.Message);
          getp2pOrder();
          getp2pconfirmOrder();
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter quantity");
      }
    } catch (error) {}
  };

  const maximum_buy = async () => {
    var order_qty = p2pDataref.current.filledAmount;
    // console.log("order_qty====",order_qty)
    p2pformValueref.current.qty = order_qty;
    var total = order_qty * p2pDataref.current.price;
    p2pformValueref.current.total = parseFloat(total).toFixed(2);
    var obj = {
      qty: order_qty,
      total: parseFloat(total).toFixed(2),
    };
    setp2pFormValue(obj);
    // console.log("p2pformValueref.current====",p2pformValueref.current)
  };

  const maximum_sell = async () => {
    var order_qty =
      p2pDataref.current.filledAmount > p2pbalanceref.current
        ? p2pbalanceref.current
        : p2pDataref.current.filledAmount;
    // console.log("order_qty====",order_qty)
    p2pformValueref.current.qty = order_qty;
    var total = order_qty * p2pDataref.current.price;
    p2pformValueref.current.total = parseFloat(total).toFixed(2);
    var obj = {
      qty: order_qty,
      total: parseFloat(total).toFixed(2),
    };
    setp2pFormValue(obj);
    // console.log("p2pformValueref.current====",p2pformValueref.current)
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        <div className="container pt-5 padin_zero">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div className="container d-flex justify-content-center padin_zero">
              <div className="col-lg-10">
                <div className="p2ppost p2plist font_pading_char">
                  <div className="margin_top_s peer2peer_section">
                    <div className="container">
                      <div className="dashboard-card">
                        <Grid item xs={12} sm={12} md={6} lg={7}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <div className="order_type">
                                <h3>
                                  <Link to="/p2p">
                                    <i class="fa-solid fa-arrow-left-long"></i>
                                  </Link>
                                </h3>
                                <div className="address_content">
                                  <p>
                                    <span>Created Time : </span>
                                    {p2pdateref.current}
                                  </p>
                                </div>
                              </div>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div className="order_type_details">
                                  <div>
                                    <label>Quantity</label>
                                    <h4 className="color-green">
                                      {p2pDataref.current.firstCurrency}{" "}
                                      {p2pDataref.current.filledAmount}
                                    </h4>
                                  </div>
                                  <div>
                                    <label>Price</label>
                                    <p>
                                      {p2pDataref.current.price}{" "}
                                      {p2pDataref.current.secondCurrnecy}
                                    </p>
                                  </div>
                                  {/* <div>
                                    <label>Quantity</label>
                                    <p>13.00 USDT</p>
                                  </div> */}
                                </div>

                                <div className="payment_method_card_view">
                                  <h5>My Payment Method</h5>
                                  {p2pDataref.current.paymentMethod ==
                                  "All payments" ? (
                                    <div>
                                      <label>All payments</label>
                                      <p>Bank Transfer</p>
                                      <p>Gpay</p>
                                      <p>Paytm</p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p>{p2pDataref.current.paymentMethod}</p>
                                    </div>
                                  )}
                                  {/* <div>
                                    <label>Name</label>
                                    <p>
                                      UserName
                                      <span>
                                        <i class="fas fa-copy"></i>
                                      </span>
                                    </p>
                                  </div> */}
                                  {bankDataref.current === "" ? (
                                    ""
                                  ) : (
                                    <div className="row px-3">
                                      <div className="col-md-6">
                                        {bankDataref.current.Gpay_Number !=
                                        undefined ? (
                                          <div>
                                            <label>Gpay</label>
                                            <p>
                                              {bankDataref.current.Gpay_Number}
                                              <span>
                                                <i
                                                  class="fas fa-copy"
                                                  onClick={() =>
                                                    copy_to_clipboard(
                                                      "Gpay",
                                                      bankDataref.current
                                                        .Gpay_Number
                                                    )
                                                  }
                                                  style={{cursor: "pointer"}}
                                                ></i>
                                              </span>
                                            </p>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-md-6">
                                        {bankDataref.current.Paytm_Number !=
                                        undefined ? (
                                          <div>
                                            <label>Paytm</label>
                                            <p>
                                              {bankDataref.current.Paytm_Number}
                                              <span>
                                                <i
                                                  class="fas fa-copy"
                                                  onClick={() =>
                                                    copy_to_clipboard(
                                                      "Paytm",
                                                      bankDataref.current
                                                        .Paytm_Number
                                                    )
                                                  }
                                                  style={{cursor: "pointer"}}
                                                ></i>
                                              </span>
                                            </p>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div className="col-md-6">
                                        <label>Account Number</label>
                                        <p>
                                          {bankDataref.current.Account_Number}
                                          <span>
                                            <i
                                              class="fas fa-copy"
                                              onClick={() =>
                                                copy_to_clipboard(
                                                  "Account Number",
                                                  bankDataref.current
                                                    .Account_Number
                                                )
                                              }
                                              style={{cursor: "pointer"}}
                                            ></i>
                                          </span>
                                        </p>
                                      </div>

                                      <div className="col-md-6">
                                        <label>Account Holder</label>
                                        <p>
                                          {
                                            bankDataref.current
                                              .Accout_HolderName
                                          }
                                          <span>
                                            <i
                                              class="fas fa-copy"
                                              onClick={() =>
                                                copy_to_clipboard(
                                                  "Account Holder",
                                                  bankDataref.current
                                                    .Accout_HolderName
                                                )
                                              }
                                              style={{cursor: "pointer"}}
                                            ></i>
                                          </span>
                                        </p>
                                      </div>

                                      <div className="col-md-6">
                                        <label>Bank Name</label>
                                        <p>
                                          {bankDataref.current.Bank_Name}
                                          <span>
                                            <i
                                              class="fas fa-copy"
                                              onClick={() =>
                                                copy_to_clipboard(
                                                  "Bank Name",
                                                  bankDataref.current.Bank_Name
                                                )
                                              }
                                              style={{cursor: "pointer"}}
                                            ></i>
                                          </span>
                                        </p>
                                      </div>

                                      <div className="col-md-6">
                                        <label>Branch Name</label>
                                        <p>
                                          {bankDataref.current.Branch_Name}
                                          <span>
                                            <i
                                              class="fas fa-copy"
                                              onClick={() =>
                                                copy_to_clipboard(
                                                  "Branch Name",
                                                  bankDataref.current
                                                    .Branch_Name
                                                )
                                              }
                                              style={{cursor: "pointer"}}
                                            ></i>
                                          </span>
                                        </p>
                                      </div>

                                      <div className="col-md-6">
                                        <label>IFSC Code</label>
                                        <p>
                                          {bankDataref.current.IFSC_code}
                                          <span>
                                            <i
                                              class="fas fa-copy"
                                              onClick={() =>
                                                copy_to_clipboard(
                                                  "IFSC Code",
                                                  bankDataref.current.IFSC_code
                                                )
                                              }
                                              style={{cursor: "pointer"}}
                                            ></i>
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div id="confirm_p2p_buy" class="modal launchpad_doce fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-body model_confirms">
              <h1> Confirm Order </h1>
              <form className="launch_card new_table  pt-5 tranbg">
                {/* <p className="balance">
                          {" "}
                          Your Wallet Balance: {(+currentBalance).toFixed(8)}
                        </p> */}
                <div className="input_section">
                  <p>
                    <span>Enter quantity to buy</span>
                  </p>
                  <div className="input_select_s newbtind">
                    <input
                      type="text"
                      name="qty"
                      value={qty}
                      onChange={confirm_handleChange}
                    />
                    <div className="select_option">
                      <Button onClick={maximum_buy}>Max</Button>
                    </div>
                  </div>
                </div>
                <div className="input_section">
                  <p>
                    <span>You Will Pay</span>
                  </p>
                  <div className="input_select_s">
                    <input
                      type="text"
                      name="total"
                      value={total}
                      onChange={confirm_handleChange}
                    />
                  </div>
                </div>
                <div className="submiot">
                  <Button
                    data-toggle="modal"
                    data-target="#launchpad_doce"
                    onClick={confirm_order_buy}
                  >
                    Confirm
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="success_msg" class="modal launchpad_doce fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-body model_confirms">
              <h1> {notifymessageref.current}</h1>
            </div>
            <div class="modal-footer">
              <Button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
                onClick={closePopup}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default P2pView;
