import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import { Button } from "@material-ui/core";

import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { setAuthToken, getAuthToken } from "../core/lib/localStorage";
import Pagination from "react-js-pagination";
import { getMethod } from "../core/service/common.api";
var moment = require("moment");

function Home() {
  const [userBalance, setuserBalance] = useState([]);
  const [fromDates, setfromData] = useState("");

  const [authToken, setauthToken] = useState(false);
  const [stakeHistory, setstakeHistory] = useState([]);
  const [flexibleStartDate, setflexibleStartDate] = useState("");

  const [stakecurrentpage, setstakecurrentpage] = useState(1);
  const [staketotalpage, setstaketotalpage] = useState(0);
  const [sideLoader,setSiteLoader]=useState(true);



  useEffect(() => {
    let getToken = localStorage.getItem("user_token");
    if (getToken != "" && getToken != undefined && getToken != null) {
      setauthToken(true);
      getStakingHistory();
    } else {
      setauthToken(false);
    }
  }, [0]);

  const stakerecordpage = 10;
  const stakepagerange = 10;
  const handlepage = (stakepage) => {
    // console.log(stakepage, "==-=-=-stakepage==-==-=");
    getStakingHistory(stakepage);
    setstakecurrentpage(stakepage);
  };

  const getStakingHistory = async (page) => {
    try {

      var payload = {
        perpage: 10,
        page: page,
      };
      setSiteLoader(true)
      var data = {
        apiUrl: apiService.getAllstakingHistory,
      };
      var resp = await postMethod(data);
      setSiteLoader(false)
      if (resp.status) {
        // setuserBalance(resp.data);
        setstakeHistory(resp.data.data);
        setstaketotalpage(resp.data.total);

      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };
  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5 padinf_ mb-5">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padinf_">
            <div className="container d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="bg_ash bg_card_section bg_card_super">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="title_section_funds">
                        <h2>Staking History</h2>
                      </div>
                    </div>
                    <div className="col-lg-8"></div>
                  </div>
                </div>
                <div className="staking_optoikn">
                  {/* //======================Staking Details==============// */}

                  <div class="">
                    <div id="LockedStaking" class="">
                      <div className="staking_title bg_card_super">
                        <div class="fixTableHead table_section_color">
                          <table>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Package</th>
                                <th>Total Amount</th>

                                <th>APY / APR</th>
                                <th>Stake Date</th>
                                <th>Locked Days</th>
                                <th>Type</th>

                                <th>Interest End Date</th>
                                {/* <th>Accurue Days</th> */}
                                <th>Estimated Interests</th>
                              </tr>
                            </thead>

                            <tbody>
                              {stakeHistory && stakeHistory.length > 0 ? (
                                stakeHistory.map((item, i) => {
                                  var startdate = moment(item.startDate).format(
                                    "MM-DD-YYYY"
                                  );
                                  var endtdate = moment(item.endDate).format(
                                    "MM-DD-YYYY"
                                  );

                                  return (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>
                                        <div className="price">
                                          {" "}
                                          <h1>
                                            <img src={item.currencyImage} />
                                            {item.stakeCurrencsymbol}
                                          </h1>
                                        </div>
                                      </td>
                                      <td>
                                        {item.stakeAmont}{" "}
                                        {item.stakeCurrencsymbol}
                                      </td>
                                      <td>
                                        <div className="aPY">
                                          {item.currentAPY}%{" "}
                                        </div>
                                      </td>
                                      <td>{startdate} </td>
                                      <td>
                                        {item.type == "fixed"
                                          ? item.stakingPlan + " days"
                                          : "-"}{" "}
                                      </td>
                                      <td>
                                        <div className="type">
                                          <p>{item.type} </p>
                                        </div>
                                      </td>

                                      <td>
                                        {" "}
                                        {item.type == "fixed" ? endtdate : "-"}
                                      </td>
                                      {/* <td>10 Days {item.stakingPlanstakingPlan}</td> */}

                                      <td>
                                        {item.type == "fixed" ? (
                                          <div className="minimum">
                                            <p>
                                              {parseFloat(
                                                item.totalInterest
                                              ).toFixed(4)}{" "}
                                              {item.stakeCurrencsymbol}
                                            </p>
                                          </div>
                                        ) : (
                                          <div className="minimum">
                                            <p>
                                              {parseFloat(
                                                item.totalInterest
                                              ).toFixed(8)}{" "}
                                              {item.stakeCurrencsymbol}
                                            </p>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  {" "}
                                  <td colSpan="9">
                                    {" "}
                                    No Staking History Found!{" "}
                                  </td>{" "}
                                </tr>
                              )}
                            </tbody>
                          </table>
                          <Pagination
                            itemClass="page-item" // add it for bootstrap 4
                            linkClass="page-link" // add it for bootstrap 4
                            activePage={stakecurrentpage}
                            itemsCountPerPage={stakerecordpage}
                            totalItemsCount={staketotalpage}
                            pageRangeDisplayed={stakepagerange}
                            onChange={handlepage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
