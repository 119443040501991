import React, {useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Chart from "./chart/Chart";
import ReactPlayer from "react-player";
import {Link, useNavigate} from "react-router-dom";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import {socket} from "./context/socket";

function Home() {
  const options = ["one", "two", "three"];
  const navigate = useNavigate();
  const [currencylistData, setcurrencylistData] = useState([]);
  const [currencyCount, setcurrencyCount] = useState(0);
  const [siteData, setsiteData] = useState("");
  const [withoutRef, setwithoutRef] = useState(false);
  const [authToken, setauthToken] = useState(false);

  const navpage = async () => {
    if (localStorage.getItem("user_token") !== null) {
      navigate("trade/BTC_USDT");
      setwithoutRef(true);
    } else {
      navigate("register");
      setwithoutRef(false);
    }
  };

  useEffect(() => {
    socket.connect();
    socket.removeListener("homepagemarketprice");
    socket.emit("homepagemarketprice");
    viewMoreCurrency(25);
    get_site_data();
    let getToken = localStorage.getItem("user_token");
    if (getToken != "" && getToken != undefined && getToken != null) {
      setauthToken(true);
    } else {
      setauthToken(false);
    }
  }, []);
  const navtradepage = async (symbol) => {
    if (symbol == "USDT") {
      navigate("trade/USDT_AIEC");
    } else {
      navigate("trade/" + symbol + "_USDT");
    }
  };

  const viewMoreCurrency = async (limit) => {
    var data = {
      apiUrl: apiService.getCurrency,
      payload: {limit: limit},
    };
    var resp = await postMethod(data);
    if (resp) {
      setcurrencyCount(resp.countDocs);
      socket.on("gethomemarketprice", async (response) => {
        for (let i = 0; i < resp.data.length; i++) {
          const element = resp.data[i];
          element["marketprice"] = response.data[element["currencySymbol"]].INR;
        }
        setcurrencylistData(resp.data);
      });
    } else {
    }
  };

  const get_site_data = async () => {
    var data = {
      apiUrl: apiService.get_site_data,
    };
    var resp = await getMethod(data);
    if (resp.status) {
      setsiteData(resp.data);
    } else {
    }
  };
  return (
    <>
      <div className="home">
        <Header />

        <div className="section homepage-section-banner">
          <div className="home_vedio-new">
            <div className="container ">
              <div className="row justify-center posiieee">
                <div className="col-lg-6">
                  <div className="first_content">
                    <h1>
                      Begin <span>Trading</span> with Your <span>Favorite</span>{" "}
                      Coins
                    </h1>
                    <p>
                      Bitbuyerx is on its way to becoming one of the industry's
                      largest cryptocurrency exchange platforms in terms of the
                      amount of bitcoin spot trades. Bitbuyerx, which was
                      founded lately, has incredible features like as spot
                      trading, the option to acquire cryptocurrency assets using
                      debit and credit cards, P2P, 50+ Cryptocurrency exchange,
                      Staking, Live Crypto trading charts, and Launchpad.
                    </p>
                    {/* <div className="social justify-centernew">
                        <a
                          href="https://www.instagram.com/Bitbuyerx.e/"
                          target="_blank"
                        >
                          <img
                            src={require("../img/insta.png")}
                            className="logo"
                          />
                        </a>
                        <a
                          href="https://medium.com/@Bitbuyerxexchange"
                          target="_blank"
                        >
                          <img
                            src={require("../img/medium.png")}
                            className="logo"
                          />
                        </a>

                        <Link to="https://twitter.com/Bitbuyerx_e" target="_blank">
                          <img
                            src={require("../img/twiter.png")}
                            className="logo"
                          />
                        </a>

                        <Link to="mailto:info@Bitbuyerx.com" target="_blank">
                          <img
                            src={require("../img/email.png")}
                            className="logo"
                          />
                        </a>
                      </div> */}
                    <div className="airdrop_btn">
                      <Button className="btn btn-primary-alta connectBtn">
                        <Link to="/trade/BTC_USDT">Get start</Link>
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 pr-0">
                  <img
                    src={require("../img/New/banner_new.png")}
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section section-2-s">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="why-chooks">
                  <h3 className="text-center">
                    Why <span>Bitbuyerx ?</span>{" "}
                  </h3>
                </div>
                <div className="why-card row justify-center">
                  <div className="col-lg-10">
                    <div className="row justify-center">
                      <div className="col-sm-6 col-lg-4 col-md-6">
                        <div className="card-transparant">
                          <img
                            src={require("../img/block.png")}
                            className="logo"
                          />
                          <h5>Fast Transactional Speeds</h5>
                          <p>
                            Bitbuyerx has the potential to execute millions of
                            transactions every second, and the system's
                            infrastructure can be extended in seconds to match
                            growing demand.
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-md-6">
                        <div className="card-transparant">
                          <img
                            src={require("../img/magnifier.png")}
                            className="logo"
                          />
                          <h5>Super Quick Kyc</h5>
                          <p>
                            The top identity verification systems complete your
                            KYC within a few hours of signing up while ensuring
                            appropriate KYC process.
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-md-6">
                        <div className="card-transparant">
                          <img
                            src={require("../img/block3.png")}
                            className="logo"
                          />
                          <h5>Exceptional Security</h5>
                          <p>
                            The organisation has done all necessary to make
                            Bitbuyerx the most secure exchange. Bitbuyerx
                            invests in recurrent security audits to provide a
                            highly secure trading platform.
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-md-6">
                        <div className="card-transparant">
                          <img
                            src={require("../img/design.png")}
                            className="logo"
                          />
                          <h5>Effective & Simple Theme</h5>
                          <p>
                            Trading on the Bitbuyerx platform is a
                            lightning-fast experience that you'll love. Our
                            expertise developing technologies used by millions
                            of people has benefited us in developing this
                            powerful interaction.
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-md-6">
                        <div className="card-transparant">
                          <img
                            src={require("../img/block2.png")}
                            className="logo"
                          />
                          <h5>Built By Adherents To The Blockchain</h5>
                          <p>
                            We are a group of dedicated traders and blockchain
                            enthusiasts. We know exactly what you require.
                            Bitbuyrex incorporates powerful graphs, mobile apps,
                            and other capabilities from the start.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section_4">
          <div className="container">
            <div className="row justify-center">
              <div className="col-lg-12 col-md-12 col-xl-10">
                <div className="card_">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="content">
                        <h2>Bitbuyerx Crypto Exchange Platform</h2>
                        <p>
                          The Bitbuyerx crypto exchange platform is one of the
                          greatest platforms for trading or purchasing
                          cryptocurrencies at the lowest possible cost. It, like
                          other leading cryptocurrency exchanges such as
                          Binance, provides high liquidity and services relating
                          to trading, fundraising, listing, withdrawal, and
                          delisting of cryptocurrencies. The Bitbuyerx
                          cryptocurrency trading platform allows users to trade
                          cryptocurrencies. The site presently supports more
                          than 50 popular coins. Traders can store their digital
                          money in the crypto wallet provided by Bitbuyerx.
                        </p>
                        <div className="button_section">
                          <Button onClick={navpage}>Get Started</Button>
                          {/* <Button>Learn More</Button> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <img src={require("../img/phone.png")} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_6 pt-5">
          <div className="container">
            <div className="row justify-center">
              <div className="col-lg-10">
                <div className="why-chooks mb-5">
                  <h3>
                    Available <span>Currencies</span>{" "}
                  </h3>
                </div>
                <div className="row align-items-center justify-center">
                  <div className="col-lg-12">
                    <div className="table_ table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Currency</th>
                            <th>Price</th>
                            <th className="text-right">Trade</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr>
                          <th>
                            <div className="coin_devlob">
                              <h1>
                                <img src={require("../img/AIEC.png")} />
                                AIEC
                              </h1>
                            </div>
                          </th>
                          <td>
                            <span>AIEC</span>
                          </td>

                          <td>
                            <Button className="btn btn-primary">
                              <Link to="/trade">buy</a>
                            </Button>
                          </td>
                        </tr> */}
                          {currencylistData &&
                            currencylistData.map((obj, i) => {
                              return (
                                <tr className="hoverbg">
                                  <th>
                                    <div className="coin_devlob">
                                      <h1>
                                        <img src={obj.Currency_image} />
                                        {obj.currencySymbol}
                                      </h1>
                                    </div>
                                  </th>
                                  <td>
                                    <span>{obj.currencyName}</span>
                                  </td>
                                  <td>
                                    {obj.marketprice <= 0 ? (
                                      <div className="text-red">
                                        {obj.currencySymbol == "SHIB"
                                          ? parseFloat(obj.marketprice).toFixed(
                                              8
                                            )
                                          : parseFloat(obj.marketprice).toFixed(
                                              2
                                            )}
                                      </div>
                                    ) : (
                                      <div className="text-green">
                                        {obj.currencySymbol == "SHIB"
                                          ? parseFloat(obj.marketprice).toFixed(
                                              8
                                            )
                                          : parseFloat(obj.marketprice).toFixed(
                                              2
                                            )}
                                      </div>
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {/* <div className="button_section"> */}
                                    <Button
                                      className="btn btn-primary button_trade"
                                      // onClick={navtradepage}
                                      onClick={() =>
                                        navtradepage(obj.currencySymbol)
                                      }
                                    >
                                      buy
                                    </Button>
                                    {/* </div> */}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    {currencylistData.length == 25 ? (
                      <a
                        href="javascript:void(0)"
                        className="link_more pt-5"
                        onClick={() => viewMoreCurrency(currencyCount)}
                      >
                        View more prices <i className="fas fa-arrow-right"></i>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_4 section_app">
          <div className="container">
            <div className="row justify-center">
              <div className="col-lg-12 col-md-12 col-xl-11">
                <div className="card_">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="abute">
                        <h4>
                          Download Our <span>Bitbuyerx App</span>{" "}
                        </h4>

                        <p>
                          Now is your opportunity.Bitbuyerx allows you to
                          purchase, sell, and trade cryptocurrencies with
                          incredible convenience, confidence, and trust. Whether
                          you're a first-time investor or a seasoned trader,
                          Bitbuyerx has you covered!
                        </p>
                        <div className="social_app">
                          <a
                            href={
                              siteData && siteData.appstore_link
                                ? siteData.appstore_link
                                : ""
                            }
                          >
                            <img
                              src={require("../img/download_appstore.png")}
                            />
                          </a>
                          <a
                            href={
                              siteData && siteData.playstore_link
                                ? siteData.playstore_link
                                : ""
                            }
                            target="_blank"
                          >
                            <img src={require("../img/global-playstore.png")} />
                          </a>
                          <a
                            href={
                              siteData && siteData.apk_link
                                ? siteData.apk_link
                                : ""
                            }
                            target="_blank"
                          >
                            <img src={require("../img/downloadapk.png")} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <img src={require("../img/iPhone12.png")} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="section section-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img
                  src={require("../img/New/4538292-ai.png")}
                  className="logo boiconi_min"
                />
              </div>
              <div className="col-md-6">
                <div className="abute">
                  <h4>
                    About <span>Bitbuyerx ?</span>{" "}
                  </h4>
                  <h5>
                    We're constructing an exchange that will allow anybody who
                    believes in cryptocurrencies to participate in the digital
                    cryptocurrency revolution. The world is accelerating toward
                    this change at an unparalleled rate.
                  </h5>
                  <p>
                    Now is your opportunity.Bitbuyerx allows you to purchase,
                    sell, and trade cryptocurrencies with incredible
                    convenience, confidence, and trust. Whether you're a
                    first-time investor or a seasoned trader, Bitbuyerx has you
                    covered!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-8">
          <div className="container">
            <div className="row justify-center">
              <div className="col-md-12 col-lg-10">
                <div className="why-chooks">
                  <h3 className="text-center">
                    Our<span> Partners</span>
                  </h3>
                </div>
                <img
                  src={require("../img/ourclinnet.png")}
                  className="partercc"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section-8 section_9">
          <div className="container">
            <div className="row justify-center">
              <div className="col-md-12 col-lg-10">
                <div className="why-chooks">
                  <h3 className="text-center">Start earning today</h3>
                  <Link
                    to="/register"
                    className="btn btn-primary button_trade footer_byny"
                  >
                    Sign Up Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Home;
