import React, { useEffect } from "react";
import useState from "react-usestateref";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { CSVLink, CSVDownload } from "react-csv";
import Moment from "moment";

function Home() {
  Moment.locale("en");
  const [p2pOrders, setp2pOrders] = useState([]);
  const navigate = useNavigate();
  const [p2pcurrentpage, setp2pcurrentpage] = useState(1);
  const [p2ptotalpage, setp2pTotalpages] = useState(0);
  const [p2porderHistory, setp2porderHistory] = useState([]);
  const [sideLoader,setSiteLoader]=useState(true);

  const [p2phistorytotalpage, setp2phistoryTotalpages] = useState(0);

  const [p2pcsvfile, Setp2pcsvfile] = useState([]);
  const [testp2pcsvfile, Settestp2pcsvfile] = useState([]);

  const getp2pOrders = async (page) => {
    var data = {
      apiUrl: apiService.p2pOrders,
      payload: { FilPerpage: 5, FilPage: page },
    };
    setSiteLoader(true)
    var p2p_orders_list = await postMethod(data);
    setSiteLoader(false)
    // console.log("p2p_orders_list===", p2p_orders_list.returnObj.Message);
    if (p2p_orders_list.status) {
      setp2pOrders(p2p_orders_list.returnObj.Message);
      Settestp2pcsvfile(p2p_orders_list.p2porder);

      setp2pTotalpages(p2p_orders_list.returnObj.total);
    }
  };
  const p2pordercsv = async () => {
    Setp2pcsvfile(testp2pcsvfile);
  };

  const p2precordpage = 5;
  const p2ppagerange = 5;
  const handlepagep2p = (p2ppage) => {
    // console.log(p2ppage, "==-=-=-p2ppage==-==-=");
    getp2pOrders(p2ppage);
    setp2pcurrentpage(p2ppage);
  };

  const getp2pHistory = async (page) => {
    var data = {
      apiUrl: apiService.p2pHistory,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var p2p_orders_history = await postMethod(data);
    // console.log("p2p_orders_history===", p2p_orders_history.returnObj.Message);
    if (p2p_orders_history.status) {
      setp2porderHistory(p2p_orders_history.returnObj.Message);
      setp2phistoryTotalpages(p2p_orders_history.returnObj.total);
    }
  };

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getp2pOrders(1);
    getp2pHistory(1);
  }, [0]);

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5 padinf_ mb-5">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padinf_">
            <div className="container d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="bg_ash bg_card_section bg_card_super">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="title_section_funds">
                        <h2>P2P Order History</h2>
                      </div>
                    </div>
                    <div className="col-lg-8"></div>
                  </div>
                </div>
                <div className="staking_optoikn">
                  {/* //======================Staking Details==============// */}

                  <div class="">
                    <div id="LockedStaking" class="">
                      <div className="staking_title bg_card_super">
                        <div class="fixTableHead">
                          {p2pOrders && p2pOrders.length > 0 ? (
                            <CSVLink
                              className="btn btn-primary float-right mr-2 mt-2"
                              filename="P2p Order History"
                              onClick={p2pordercsv}
                              data={p2pcsvfile}
                            >
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </CSVLink>
                          ) : (
                            <div className="btn yellow float-right mr-2 mt-2">
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </div>
                          )}

                          <div className="table-responsive transaction">
                            <table className="table table_section_color mt-3">
                              <thead>
                                <tr>
                                  <th scope="col">S.No</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Time</th>
                                  <th scope="col">Currency</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Price</th>
                                  <th scope="col">Order Type</th>
                                  <th scope="col">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {p2pOrders && p2pOrders.length > 0 ? (
                                  p2pOrders.map((item, i) => {
                                    return (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                          {Moment(item.createdAt).format(
                                            "YYYY-MM-DD"
                                          )}
                                        </td>
                                        <td>
                                          {Moment(item.createdAt).format(
                                            "hh:mm a"
                                          )}
                                        </td>
                                        <td>{item.firstCurrency}</td>
                                        <td>
                                          {parseFloat(item.totalAmount).toFixed(
                                            8
                                          )}
                                        </td>
                                        <td>{item.price}</td>
                                        <td>
                                          {item.orderType == "buy" ? (
                                            <span className="text-green">
                                              Buy
                                            </span>
                                          ) : (
                                            <span className="text-red">
                                              Sell
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          {item.status == "filled" ? (
                                            <span className="text-green">
                                              {item.status}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {item.status !== "filled" ? (
                                            <span className="text-red">
                                              {item.status}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <td colSpan="8">
                                    <span className="w-100 text-center d-block">
                                      No Orders Found!
                                    </span>
                                  </td>
                                )}
                              </tbody>
                            </table>
                          </div>

                          <div className="mt-5">
                            <Pagination
                              itemClass="page-item" // add it for bootstrap 4
                              linkClass="page-link" // add it for bootstrap 4
                              activePage={p2pcurrentpage}
                              itemsCountPerPage={p2precordpage}
                              totalItemsCount={p2ptotalpage}
                              pageRangeDisplayed={p2ppagerange}
                              onChange={handlepagep2p}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
