import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import useState from "react-usestateref";
import Arrow from "../img/ArrowRight.svg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { setAuthToken, getAuthToken } from "../core/lib/localStorage";
import { useParams } from "react-router-dom";
import { setAuthorization } from "../core/service/axios";

import "rc-slider/assets/index.css";
import Pagination from "react-js-pagination";

function Home() {
  const options = ["one", "two", "three"];

  const [perpage, setperpage] = useState(5);
  const [page, setpage] = useState(1);
  const [search, setsearch] = useState(1);
  const [balanceDetails, setbalanceDetails] = useState([]);
  const [balance_overallusdt, setbalance_overallusde] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [totalINRPrice, setToatalINRPrice] = useState(0);
  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [inorderPrice, setinorderPrice] = useState(0);
  const [hidezero, sethidezero, hidezeroref] = useState(false);
  const [hide, Sethide, hideref] = useState(true);
  const [usdtvconvertingbalance, Setusdtvconvertingbalance] = useState(0);
  const [inrconvertingbalance, Setinrconvertingbalance] = useState(0);
  const [sideLoader,setSiteLoader]=useState(true);

  const recordPerPage = 5;

  // total number of the records
  const totalRecords = 7;

  // range of pages in paginator
  const pageRange = 5;
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    if (hideref.current == true) {
      sethidezero(false);
      // console.log(hidezeroref.current, "00000000000");
      setCurrentPage(pageNumber); // call API to get data based on pageNumber
      getUserbalance(pageNumber);
    } else {
      sethidezero(true);
      setCurrentPage(pageNumber); // call API to get data based on pageNumber
      getUserbalance(pageNumber);
    }
  };

  const getUserbalance = async (pages) => {
    // console.log(pages);

    var obj = {
      perpage: perpage,
      page: pages,
      search: search,
      hidezero: hidezeroref.current,
    };
    setSiteLoader(true);
    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };
    var resp = await postMethod(data);
    setSiteLoader(false);

    console.log(resp, "-------resp----");
    if (resp.status) {
      sethidezero(true);
      // console.log(resp.Message, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.Message;
      // zerobalance();
      setbalanceDetails(resp.Message);
      setbalance_overallusde(resp.balance);
      var total = resp.total - 5;
      settotal(total);
    } else {
    }
  };

  const searchWalletList = async () => [sethidezero(false), getUserbalance(1)];

  const getPortfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    var resp = await getMethod(data);
    console.log(resp, "balanceData");

    if (resp.status) {
      console.log(resp.balance, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.available_balance);
      setToatalINRPrice(balanceData.available_balance);
      Setusdtvconvertingbalance(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    } else {
    }
  };

  const checkzerobalancefalse = async () => {
    // console.log("------");
    // console.log(hide, "hide");
    if (hideref.current == false) {
      // console.log(hideref.current, "hiderefhideref");
      sethidezero(false);
    }
    Sethide(true);
    // console.log(hidezeroref.current, "-----");
    await handlePageChange(1);
  };

  const checkzerobalancetrue = async () => {
    // console.log(",,,,,");
    if (hideref.current == true) {
      // console.log(hideref.current, "hiderefhideref");
      sethidezero(true);
    }
    Sethide(false);
    await handlePageChange(1);
  };

  const zerobalance = async (pages) => {
    try {
      var obj = {
        // perpage: perpage,
        // page: pages,
        search: search,
        hidezero: pages,
      };
      console.log(obj, "-=-=-obj==-=-");
      var data = {
        apiUrl: apiService.zerobalance,
        payload: obj,
      };
      var resp = await postMethod(data);

      // await setbalanceDetails(resp.Messa+ge);
      console.log(resp, "resp======");
      // console.log(resp.balance.total_balance_usdt);

      var data = resp.balance.fiat_balance;
      var fiat = resp.balance.fiat_balance;
      // console.log(fiat);
      Setinrconvertingbalance(fiat);
      // console.log(resp.Message.EstimatedFIAT);
      // console.log(typeof fiat);

      // settotal(resp.total);
    } catch (error) {}
  };
  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getPortfolio();
    getUserbalance(currentPage);
    zerobalance();
  }, []);

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ba_fff">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div class="container">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div
              class="my_fund_content my_fund_content_new
"
            >
              <div className="container">
                <div className="bg_ash bg_card_section">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="title_section_funds">
                        <h2>My Funds</h2>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="button_section">
                        <Link to="/transaction">Deposit</Link>
                        <Link to="/transaction">Withdraw</Link>
                        {/* <Link to="">Transaction History</Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container">
                <div class="">
                  <div class="my_fund_sectin_ bg_card_section">
                    <div class="row">
                      <div class="col-lg-6">
                        <div className="promery_balance">
                          <p>Spot Balance</p>
                          <h2>
                            {parseFloat(usdtvconvertingbalance).toFixed(2)} INR
                            {/* <span>= $2.227.52</span> */}
                          </h2>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div className="balance_detalis">
                          <div className="balance_child">
                            {/* <span>Spot balance</span>
                            <h3>
                              {parseFloat(
                                totalINRPrice && totalINRPrice
                              ).toFixed(2)}{" "} INR
                            </h3> */}
                            {/* <small>= $2.733.52</small> */}
                          </div>
                          {/* <div className="balance_child">
                            <span>Fiat balance</span>
                            <h3>
                              {parseFloat(inrconvertingbalance).toFixed(2)} INR
                            </h3>
                          </div> */}
                          {/* <div className="balance_child">
                            <span>Today PNL</span>
                            <h3 className="text-red">-$15.00</h3>
                            <small className="text-red">-0.50%</small>
                          </div> */}
                        </div>
                        {/* <div class="search_row">
                          <div class="input_rpow">
                            <input type="text" placeholder="Search coin" />
                            <i class="fas fa-search"></i>
                          </div>
                          <select>
                            <option value="">USD</option>
                          </select>
                          <button class="btn btn-primary">Show Balance</button>
                        </div> */}
                      </div>
                      {/* <div class="col-lg-12">
                        <div class="balance_section">
                          <p>Total balance</p>
                          <h3>
                            {parseFloat(totalINRPrice && totalINRPrice).toFixed(
                              2
                            )}{" "}
                            <span> INR </span>
                          </h3>
                          <small>$10,098.36</small>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <p class="title_aa">Account Balances</p> */}

                  <div class="row hidden">
                    <div class="col-lg-6">
                      <div class="bg_card_section">
                        <div class="balanc_avail">
                          <p>
                            <img
                              src={require("../img/box_gray.png")}
                              className=""
                            />
                            Available Balance
                          </p>
                          <div>
                            <h5> {AvailablePrice.toFixed(2)} INR</h5>
                            {/* <span>$10,098.36</span> */}
                          </div>
                        </div>
                        <div class="button_account_bottn">
                          <Link to="/transaction">
                            <button>
                              Deposit <i class="fas fa-arrow-left"></i>
                            </button>
                          </Link>
                          <Link to="/transaction">
                            <button>
                              Withdraw <i class="fas fa-arrow-right"></i>
                            </button>
                          </Link>
                          {/* <button>Transfer </button> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="bg_card_section">
                        <div class="balanc_avail">
                          <p>
                            <img
                              src={require("../img/box_gray.png")}
                              className=""
                            />
                            In Order
                          </p>
                          <div>
                            <h5>{inorderPrice.toFixed(2)} INR</h5>
                            {/* <span>$10,098.36</span> */}
                          </div>
                        </div>
                        <div class="button_account_bottn">
                          <Link to="/transaction">
                            <button class="green">View More</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* <div class="col-lg-6">
                      <div class="bg_card_section">
                        <div class="balanc_avail">
                          <p>
                            <img
                              src={require("../img/box_gray.png")}
                              className=""
                            />
                            Orders List
                          </p>
                          <div>
                            <h5>0.2785689852 BTC</h5>
                            <span>$10,098.36</span>
                          </div>
                        </div>
                        <div class="button_account_bottn">
                          <button class="green"> View More </button>
                        </div>
                      </div>
                    </div> */}
                    {/* <div class="col-lg-6">
                      <div class="bg_card_section">
                        <div class="balanc_avail">
                          <p>
                            <img
                              src={require("../img/box_gray.png")}
                              className=""
                            />
                            Orders List
                          </p>
                          <div>
                            <h5>0.2785689852 BTC</h5>
                            <span>$10,098.36</span>
                          </div>
                        </div>
                        <div class="button_account_bottn">
                          <button class="green">View More </button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div class="table_section_">
              <div class="container">
                <div className=" my_fund_sectin_ bg_card_section bg_card_super">
                  <div className="flex_content_dex">
                    <div className="prime_deposit copyicon left searchicon">
                      <input
                        type="text"
                        name="searchvallist"
                        // value={searchvallist}
                        placeholder="Search by Currency"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                      <span
                        className="bg_yellow"
                        onClick={() => searchWalletList()}
                      >
                        <i class="bi bi-search text-dark"></i>
                      </span>
                    </div>
                    <div>
                      {hide == true ? (
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck"
                            name="example1"
                            onClick={checkzerobalancetrue}
                          />
                          <label class="custom-control-label" for="customCheck">
                            Hide 0 balances
                          </label>
                        </div>
                      ) : (
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck"
                            name="example1"
                            onClick={checkzerobalancefalse}
                          />
                          <label class="custom-control-label" for="customCheck">
                            Show 0 balance
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="table-responsive tavelie_margein">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Asset</th>
                          <th scope="col" class="text_222">
                            On orders
                          </th>
                          <th scope="col" class="text_222">
                            Available balance
                          </th>
                          <th scope="col" class="text_222">
                            Total balance
                          </th>
                          {/* <th scope="col" class="text_222">
                            Launchpad balance
                          </th> */}
                          {/* <th scope="col" class="text_222">
                          P2P balance
                        </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {balanceDetails &&
                          balanceDetails.map((item, i) => {
                            return (
                              <tr>
                                <td>
                                  {/* <Link
                                    to={
                                      "/transaction-history/" +
                                      item.currencysymbol
                                    }
                                  > */}
                                  <div class="coin_table_ta">
                                    <img
                                      src={item.currencyImage}
                                      className=""
                                    />
                                    <div class="row_eee">
                                      <h3>{item.currencysymbol}</h3>
                                      <span>{item.currencyName}</span>
                                    </div>
                                  </div>
                                  {/* </Link> */}
                                </td>

                                <td>
                                  <div class="row_oiu_p">
                                    <h5>
                                      {parseFloat(item.holdAmount).toFixed(8)}{" "}
                                      {item.currencysymbol}{" "}
                                    </h5>
                                    <span>
                                      {parseFloat(
                                        item.estimatedUSDThold
                                      ).toFixed(2)}{" "}
                                      ₹{" "}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div class="row_oiu_p">
                                    <h5>
                                      {parseFloat(item.currencyBalance).toFixed(
                                        8
                                      )}{" "}
                                      {item.currencysymbol}
                                    </h5>
                                    <span>
                                      {parseFloat(
                                        item.estimatedUSDTbalance
                                      ).toFixed(2)}{" "}
                                      ₹{" "}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div class="row_oiu_p">
                                    <h5>
                                      {" "}
                                      {parseFloat(
                                        item.currencyBalance +
                                          parseFloat(item.holdAmount)
                                      ).toFixed(8)}{" "}
                                      {item.currencysymbol}{" "}
                                    </h5>
                                    <span>
                                      {parseFloat(
                                        item.estimatedUSDTtotal
                                      ).toFixed(2)}{" "}
                                      ₹{" "}
                                    </span>
                                  </div>
                                </td>
                                {/* <td>
                                  <div class="row_oiu_p">
                                    <h5>
                                      {item.launchPadAmount != undefined &&
                                      item.launchPadAmount != null &&
                                      item.launchPadAmount != ""
                                        ? parseFloat(
                                            item.launchPadAmount
                                          ).toFixed(8)
                                        : (0).toFixed(8)}{" "}
                                      {item.currencysymbol}{" "}
                                    </h5>
                                    {/* <span>
                                    {parseFloat(
                                      item.estimatedUSDTtotal
                                    ).toFixed(2)}{" "}
                                    ₹{" "}
                                  </span> */}
                                {/* </div>
                                </td> */}

                                {/* <td>
                                <div class="row_oiu_p">
                                  <h5>
                                    {" "}
                                    {parseFloat(
                                        item.p2p
                                    ).toFixed(8)}{" "}
                                    {item.currencysymbol}{" "}
                                  </h5>
                                </div>
                              </td> */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={currentPage}
                      itemsCountPerPage={recordPerPage}
                      totalItemsCount={total}
                      pageRangeDisplayed={pageRange}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
