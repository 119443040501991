import React, { useEffect } from 'react'
import Header from "./Header";
import Footer from "./Footer"



function Comingsoon() {
  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ba_fff">
        <Header />
        <div class="container">
        <div className='row comingsoon'>
            <div className='col-lg-12 '>    
            <img src={require('../img/sooon.png')} width='50%' className='d-block mx-auto mt-5' />
            </div>
        </div>
        </div>
        <Footer/>
      </main>
    </div>
  )
}

export default Comingsoon