import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import Pagination from "react-js-pagination";
import Footer from "./Footer";

function Dashboard() {
  const options = ["one", "two", "three"];

  const [perpage, setperpage] = useState(5);
  const [page, setpage] = useState(1);
  const [search, setsearch] = useState(1);
  const [balanceDetails, setbalanceDetails] = useState([]);
  const [balance_overallusdt, setbalance_overallusde] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [totalINRPrice, setToatalINRPrice] = useState(0);
  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [inorderPrice, setinorderPrice] = useState(0);
  const [passHide, setPasshide] = useState(true);
  const [hidezero, sethidezero, hidezeroref] = useState(false);
  const [hide, Sethide, hideref] = useState(true);
  const [usdtvconvertingbalance, Setusdtvconvertingbalance] = useState(0);
  const [inrconvertingbalance, Setinrconvertingbalance] = useState(0);
  const [Totalvalueinr, setTotalvalueinr, Totalvalueinrref] = useState(0);
  const [sideLoader,setSiteLoader]=useState(true);

  const recordPerPage = 5;

  // total number of the records
  const totalRecords = 7;

  // range of pages in paginator
  const pageRange = 5;
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    if (hideref.current == true) {
      sethidezero(false);
      // console.log(hidezeroref.current, "00000000000");
      setCurrentPage(pageNumber); // call API to get data based on pageNumber
      getUserbalance(pageNumber);
    } else {
      sethidezero(true);
      setCurrentPage(pageNumber); // call API to get data based on pageNumber
      getUserbalance(pageNumber);
    }
  };

  const getUserbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
      search: search,
      hidezero: hidezeroref.current,
    };
    setSiteLoader(true);
    var data = {
      apiUrl: apiService.dashboardgetUserBalance,
      payload: obj,
    };
    var resp = await postMethod(data);
    // console.log(resp, "-------resp----");
    setSiteLoader(false);
    if (resp.status) {
      sethidezero(true);
      console.log(resp, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.Message;
      // zerobalance();
      setbalanceDetails(resp.Message);
      setbalance_overallusde(resp.balance);
      var total = resp.total - 5;
      settotal(total);
    } else {
    }
  };

  const Portfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    
    var resp = await getMethod(data);
    console.log(resp, "balanceData");

    if (resp.status) {
      console.log(resp.balance, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.balance;
      setTotalvalueinr(balanceData.available_balance);
    } else {
    }
  };

  const searchWalletList = async () => [sethidezero(false), getUserbalance(1)];
  const getPortfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    var resp = await getMethod(data);
    if (resp.status) {
      // console.log(resp.balance, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    } else {
    }
  };

  const checkzerobalancefalse = async () => {
    if (hideref.current == false) {
      // console.log(hideref.current, "hiderefhideref");
      sethidezero(false);
    }
    Sethide(true);
    // console.log(hidezeroref.current, "-----");
    await handlePageChange(1);
  };

  const checkzerobalancetrue = async () => {
    // console.log(",,,,,");
    if (hideref.current == true) {
      // console.log(hideref.current, "hiderefhideref");
      await sethidezero(true);
    }
    Sethide(false);
    await handlePageChange(1);
  };

  const zerobalance = async (pages) => {
    try {
      var obj = {
        // perpage: perpage,
        // page: pages,
        search: search,
        hidezero: pages,
      };
      console.log(obj, "-=-=-obj==-=-");
      var data = {
        apiUrl: apiService.dashboardzerobalance,
        payload: obj,
      };
      var resp = await postMethod(data);

      // await setbalanceDetails(resp.Messa+ge);
      // console.log(resp);
      // console.log(resp.balance.total_balance_usdt);

      Setusdtvconvertingbalance(resp.balance.total_balance_usdt);
      var data = resp.balance.fiat_balance;
      var fiat = resp.balance.fiat_balance;
      // console.log(fiat);
      Setinrconvertingbalance(fiat);
      // console.log(resp.Message.EstimatedFIAT);
      // console.log(typeof fiat);

      // settotal(resp.total);
    } catch (error) {}
  };
  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getPortfolio();
    getUserbalance(currentPage);
    zerobalance();
    Portfolio();
  }, []);

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
    } else {
      setPasshide(false);
    }
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ba_fff">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div class="container">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div
              class="my_fund_content my_fund_content_new
"
            >
              <div className="container">
                <div className="bg_ash bg_card_section">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="title_section_funds d-flex justify-content-between align-items-center">
                        <h3>
                          Estimated Assets Balance:{" "}
                          {Totalvalueinr
                            ? Totalvalueinrref.current.toFixed(3)
                            : "0"}{" "}
                          INR{" "}
                        </h3>
                        <div className="input-group-addon mt-2">
                          {passHide == false ? (
                            <i
                              className="bi bi-eye-slash-fill"
                              onClick={() => passwordHide("hide")}
                            > Show  Balance</i>
                          ) : (
                            <i
                              className="bi bi-eye-fill"
                              onClick={() => passwordHide("show")}
                            >
                              Hide Balance
                            </i>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="">
                  <div class="my_fund_sectin_ bg_card_section hidden">
                    <div class="row ">
                      <div class="col-lg-5"></div>
                      <div class="col-lg-7">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="bg_card_section">
                              <div class="balanc_avail">
                                <p>
                                  <img
                                    src={require("../img/box_gray.png")}
                                    className=""
                                  />
                                  Available Balance
                                </p>
                                <div>
                                  {/* <h5> {AvailablePrice.toFixed(2)} INR</h5> */}
                                </div>
                              </div>
                              <div class="button_account_bottn">
                                <Link to="/transaction">
                                  <button>
                                    Deposit <i class="fas fa-arrow-left"></i>
                                  </button>
                                </Link>
                                <Link to="/transaction">
                                  <button>
                                    Withdraw <i class="fas fa-arrow-right"></i>
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="bg_card_section">
                              <div class="balanc_avail">
                                <p>
                                  <img
                                    src={require("../img/box_gray.png")}
                                    className=""
                                  />
                                  In Order
                                </p>
                                <div>
                                  <h5>0.00 INR</h5>
                                </div>
                              </div>
                              <div class="button_account_bottn">
                                <Link to="/transaction">
                                  <button class="green">View More</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="table_section_">
                    <div class="container">
                      <div className="bg_card_section">
                        <div className="flex_content_dex">
                          <div className="prime_deposit copyicon left searchicon">
                            <input
                              type="text"
                              name="searchvallist"
                              // value={searchvallist}
                              placeholder="Search by Currency"
                              onChange={(e) => setsearch(e.target.value)}
                            />
                            <span
                              onClick={() => searchWalletList()}
                              className="bg_yellow"
                            >
                              <i class="bi bi-search text-dark"></i>
                            </span>
                          </div>
                          <div>
                            {hide == true ? (
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheck"
                                  name="example1"
                                  onClick={checkzerobalancetrue}
                                />
                                <label
                                  class="custom-control-label"
                                  for="customCheck"
                                >
                                  Hide 0 balances
                                </label>
                              </div>
                            ) : (
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheck"
                                  name="example1"
                                  onClick={checkzerobalancefalse}
                                />
                                <label
                                  class="custom-control-label"
                                  for="customCheck"
                                >
                                  Show 0 balance
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="table-responsive tavelie_margein">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Asset</th>

                                <th scope="col" class="text_222">
                                  Amount
                                </th>
                                <th
                                  scope="col"
                                  class="text_222 text-right"
                                  colSpan="3"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {balanceDetails && balanceDetails.length > 0 ? (
                                balanceDetails.map((item, i) => {
                                  return (
                                    <tr>
                                      <td>
                                        <Link
                                          to={
                                            "/transaction-history/" +
                                            item.currencysymbol
                                          }
                                        >
                                          <div class="coin_table_ta">
                                            <img
                                              src={item.currencyImage}
                                              className=""
                                            />
                                            <div class="row_eee">
                                              <h3>{item.currencysymbol}</h3>
                                              <span>{item.currencyName}</span>
                                            </div>
                                          </div>
                                        </Link>
                                      </td>
                                      {passHide == true ? (
                                        <td>
                                          <div class="row_oiu_p">
                                            <h5>
                                              {parseFloat(
                                                item.currencyBalance
                                              ).toFixed(8)}{" "}
                                              {item.currencysymbol}
                                            </h5>
                                            <span>
                                              {parseFloat(
                                                item.estimatedUSDTbalance
                                              ).toFixed(2)}{" "}
                                              ₹{" "}
                                            </span>
                                          </div>
                                        </td>
                                      ) : (
                                        <td>
                                          <div class="row_oiu_p">
                                            <h5>**********</h5>
                                            <span>*******</span>
                                          </div>
                                        </td>
                                      )}
                                      <td colspan="3">
                                        <div className="button_flex_colo">
                                          <Link
                                            to="/transaction"
                                            className="btn pleft btn-primary-alta connectBtn"
                                          >
                                            Deposit
                                          </Link>
                                          <Link
                                            to="/transaction"
                                            className="btn pleft btn-primary-alta connectBtn"
                                          >
                                            Withdraw
                                          </Link>
                                          <Link
                                            to="/swap"
                                            className="btn pleft btn-primary-alta connectBtn"
                                          >
                                            Swap
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="3">
                                    <span className="w-100 text-center d-block">
                                      No History found!
                                    </span>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {balanceDetails && balanceDetails.length > 0 ? (
                            <Pagination
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={currentPage}
                              itemsCountPerPage={recordPerPage}
                              totalItemsCount={total}
                              pageRangeDisplayed={pageRange}
                              onChange={handlePageChange}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Dashboard;
