import React, {useEffect} from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Arrow from "../img/ArrowRight.svg";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import {useParams} from "react-router-dom";
import {setAuthorization} from "../core/service/axios";

function Home() {
  const options = ["one", "two", "three"];

  const initialFormValue = {
    email: "",
  };

  const initialFormValue1 = {
    password: "",
    confirmPassword: "",
  };
  const {token} = useParams();

  const {id} = useParams();

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] = useState(
    false
  );
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [formValue1, setFormValue1] = useState(initialFormValue1);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [activeStatus, seractiveStatus] = useState(false);
  const [passconfNotmatch, setpassconfNotmatch] = useState(false);
  const [validationnErr1, setvalidationnErr1] = useState("");
  const [dpliutl, setdpliutl] = useState("");
  const [passhide, Setpasshide] = useState(false);
  const [inputtype, Setinputtype] = useState("password");
  const [confinputtype, Setconfinputtype] = useState("password");
  const [confpasshide, Setconfpasshide] = useState(false);
  const {email} = formValue;
  const {password, confirmPassword} = formValue1;

  const handleChange = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    setFormValue(formData);
    validate(formData);
  };
  const handleChange1 = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData1 = {...formValue1, ...{[name]: value}};
    setFormValue1(formData1);
    validate1(formData1);
  };

  useEffect(() => {
    var dpliutl = window.location.href.split("_")[1];
    if (dpliutl != "" && dpliutl != null && dpliutl != undefined) {
      linkverify();
    }
  }, []);

  const linkverify = async (values) => {
    console.log("-0-0-0-0-linkverifiecaled)-0-0-");
    var dpliutl = window.location.href.split("_")[1];
    if (dpliutl) {
      setdpliutl(dpliutl);
      var obj = {
        link: dpliutl,
      };
      var data = {
        apiUrl: apiService.verifyForgotpasslink,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status == true) {
        seractiveStatus(true);
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } else {
    }
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is required field";
      setemailValidate(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      setemailValidate(true);
    } else {
      setemailValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const validate1 = async (values) => {
    const errors1 = {};

    if (values.password == "") {
      setpasswordValidate(true);
      errors1.password = "password is required";
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors1.password =
        "Password is required and shouldnot below 5 above 25 letters";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least lower character";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least upper character";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at One digit character";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least one special character";
    } else {
      setpasswordValidate(false);
    }

    if (!values.confirmPassword) {
      errors1.confirmPassword = "Confirm password is required field";
      setconfirmPasswordValidate(true);
    } else if (values.password != values.confirmPassword) {
      errors1.confirmPassword = "Password and Confirm password does not match";
      setconfirmPasswordValidate(true);
    } else {
      setconfirmPasswordValidate(false);
    }

    if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      // errors.password = 'Password and confirm password does not match';
      errors1.confirmPassword = "Password and confirm password does not match";
      setpassconfNotmatch(true);
    }

    setvalidationnErr1(errors1);
    return errors1;
  };

  const formSubmit = async () => {
    validate(formValue);
    // console.log(token, "=-=-=");
    if (emailValidateref.current === false) {
      var obj = {
        email: formValue.email,
      };
      var data = {
        apiUrl: apiService.changepswdlink,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      // console.log(resp, "==-=-resp");
      setbuttonLoader(false);
      if (resp.status == true) {
        toast.success(resp.message);
        setFormValue(initialFormValue);
      } else {
        toast.error(resp.message);
        setFormValue(initialFormValue);
      }
    } else {
      console.log("Email field is required");
    }
  };

  const formSubmitchange = async () => {
    validate1(formValue1);
    if (
      confirmPasswordValidateref.current === false &&
      passwordValidateref.current === false
    ) {
      var obj = {
        password: formValue1.password,
        confimPassword: formValue1.confirmPassword,
        id: dpliutl,
      };

      // console.log(obj, "=-=-=-=-=-=-=-==-=");
      var data = {
        apiUrl: apiService.resetpassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status == true) {
        toast.success(resp.Message);
        navigate("/login");
      } else {
        toast.error(resp.Message);
        setFormValue1(initialFormValue1);
      }
    }
  };
  const passwordHide = (data) => {
    if (data == "hide") {
      Setpasshide(true);
      Setinputtype("text");
    } else {
      Setpasshide(false);
      Setinputtype("password");
    }
  };
  const confpasswordHide = (data) => {
    if (data == "hide") {
      Setconfpasshide(true);
      Setconfinputtype("text");
    } else {
      Setconfpasshide(false);
      Setconfinputtype("password");
    }
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover bg_white">
        <Header />
        <div class="containerpadin_zero">
          <div className="max-w-8xl mx-auto sm:px-6 md:px-8 padin_zero p-0">
            <div class="row">
              <div class="col-lg-6">
                <div class="login_content_section">
                  <h1>
                    Fastest & secure <br /> platform to invest <br />
                    in crypto
                  </h1>
                  <p>
                    Buy and sell cryptocurrencies, trusted by 10M wallets <br />
                    with over $30 billion in transactions.
                  </p>
                  {/* <div class="login_btn_n">
                    <button type="submit" class="btn btn-primary">
                      Try for FREE
                      <img src={Arrow} className="logo" />
                    </button>
                  </div> */}
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form_login_section">
                  <h1>Hi, Welcome Back</h1>
                  <p> Forgot Password</p>

                  {activeStatus == false ? (
                    <div class="form register_login">
                      <form>
                        <div class="form-group">
                          <label>Your Email</label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Your Email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                          />
                          <div>
                            {emailValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr.email}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {buttonLoader == false ? (
                          <button
                            type="button"
                            class="btn btn-primary w-100"
                            onClick={formSubmit}
                          >
                            Submit
                          </button>
                        ) : (
                          <button type="button" class="btn btn-primary w-100">
                            loading...
                          </button>
                        )}

                        <p class="bottom_linnk">
                          <Link to="/login">
                            <a>Already have an account?</a>
                          </Link>
                        </p>
                      </form>
                    </div>
                  ) : (
                    <div class="form register_login">
                      <form>
                        {/* <div class="form-group">
                          <label> Password </label>
                          <div className="postion_reletitt">
                          <input
                            type={inputtype}
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={handleChange1}
                          />
                          <div className="input-group-addon">
                            {passhide == false ? (
                              <i className="bi-bi-eye-slash-fill"
                                onClick={() => passwordHide("hide")}>
                              </i>
                            ) : (
                              <i className="bi-bi-eye-fill"
                                onClick={() => passwordHide("show")}>
                              </i>
                            )}

                          </div>
                          </div>

                          <div>
                            {passwordValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr1.password}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div> */}

                        <div class="form-group">
                          <label>Password</label>
                          <div className="postion_reletitt">
                            <input
                              type={inputtype}
                              class="form-control"
                              id="exampleInputPassword1"
                              placeholder="Enter your password"
                              name="password"
                              value={password}
                              onChange={handleChange1}
                            />
                            <div className="input-group-addon">
                              {passhide == false ? (
                                <i
                                  className="bi bi-eye-slash-fill"
                                  onClick={() => passwordHide("hide")}
                                ></i>
                              ) : (
                                <i
                                  className="bi bi-eye-fill"
                                  onClick={() => passwordHide("show")}
                                ></i>
                              )}
                            </div>
                          </div>
                          <div>
                            {passwordValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr1.password}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {/* <div class="form-group">
                          <label> Confirm password </label>
                          <div className="postion_reletitt">
                          <input
                            type={confinputtype}
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleChange1}
                          />
                          <div className="input-group-addon">
                            {confpasshide == false ? (
                              <i className="bi-bi-eye-slash-fill"
                                onClick={() => confpasswordHide("hide")}>
                              </i>
                            ):(
                              <i className="bi-bi-eye-fill"
                                onClick={() => confpasswordHide("show")}>
                              </i>
                            )
                        }

                          </div>
                          </div>

                          <div>
                            {confirmPasswordValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr1.confirmPassword}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div> */}

                        <div class="form-group">
                          <label>Confirm password</label>
                          <div className="postion_reletitt">
                            <input
                              type={confinputtype}
                              class="form-control"
                              id="exampleInputPassword1"
                              placeholder="Confirm password"
                              name="confirmPassword"
                              value={confirmPassword}
                              onChange={handleChange1}
                            />
                            <div className="input-group-addon">
                              {confpasshide == false ? (
                                <i
                                  className="bi bi-eye-slash-fill"
                                  onClick={() => confpasswordHide("hide")}
                                ></i>
                              ) : (
                                <i
                                  className="bi bi-eye-fill"
                                  onClick={() => confpasswordHide("show")}
                                ></i>
                              )}
                            </div>
                          </div>
                          <div>
                            {confirmPasswordValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr1.confirmPassword}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {buttonLoader == false ? (
                          <button
                            type="button"
                            class="btn btn-primary w-100"
                            onClick={formSubmitchange}
                          >
                            Submit
                          </button>
                        ) : (
                          <button type="button" class="btn btn-primary w-100">
                            loading...
                          </button>
                        )}

                        <p class="bottom_linnk">
                          Already have an account?
                          <Link to="/login">
                            <a>Login to continue</a>
                          </Link>
                        </p>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
