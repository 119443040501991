import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import { Button } from "@material-ui/core";

import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { setAuthToken, getAuthToken } from "../core/lib/localStorage";
import OTPInput, { ResendOTP } from "otp-input-react";
import moment from "moment";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";
// import firebase from 'react-native-firebase';

function Home() {
  const [authToken, setauthToken] = useState(true);
  const [refferance, setrefferance] = useState("");
  const [totalRef, settotalRef] = useState([]);
  const [Rewardamount, setRewardamount] = useState("");
  const [refferaldata, setrefferaldata] = useState([]);
  const [sideLoader,setSiteLoader]=useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    getUser();
    getDetails();
    getReward();
    getrefferal();
    let getToken = localStorage.getItem("user_token");
    if (getToken != "" && getToken != undefined && getToken != null) {
      setauthToken(true);
    } else {
      setauthToken(false);
    }
  }, [0]);

  const copy = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      toast.success("Referral Link copied successfully");
      // console.log("sucess");
    } else {
      // console.log("error");
    }
  };

  const getUser = async () => {
    var data = {
      apiUrl: apiService.userDetails,
    };

    var resp = await getMethod(data);
    // console.log(resp, "=-=-=-=-=resp.data=-=-=-");
    var currURL = window.location.href.split("refferal")[0];
    console.log(currURL);
    var link = currURL + "register?/invite/" + resp.data.ReferlId;
    // console.log(resp.data.ReferlId, "=-=-=-=- resp.data.ReferlId=-=-==-");
    console.log(link, "123567890");
    setrefferance(link);
    // console.log(link, "=-=-=-=-link=-=-==-");
  };

  const getDetails = async () => {
    var get = {
      apiUrl: apiService.getDetails,
    };
    setSiteLoader(true);
    var responce = await getMethod(get);
    setSiteLoader(false)
    // console.log(responce, "=-=-=-=-=responce.data=-=-=-");
    settotalRef(responce.data);
  };

  const getReward = async () => {
    var get = {
      apiUrl: apiService.getReward,
    };
    var responce = await getMethod(get);
    setRewardamount(responce.data);
  };

  const getrefferal = async () => {
    var get = {
      apiUrl: apiService.getrefferal,
    };
    var responce = await getMethod(get);
    setrefferaldata(responce.data);
  };

  const title = "Welcome to Bitbuyerx";

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container mb-4 ">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div className="container  padin_zero d-block mt-5">
              <div className="row">
                <div className="col-lg-12">
                  <div className="titile_refere">
                    <h1>Bitbuyrex Refferal Program</h1>
                    <p>
      setSiteLoaderZ                Refer & Earn 50% of trading fee paid by your friends as
                      reward. be your own boss
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="staking_title launcpad d-block reffer-card bg_card_super">
                    <h2>Total Referral</h2>
                    <p>{totalRef}</p>
                    <span>
                      <i class="bi bi-people"></i>
                    </span>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="staking_title launcpad d-block reffer-card bg_card_super">
                    <h2>Referral Rewards</h2>
                    <p>{Rewardamount}</p>
                    <span>
                      <i class="bi bi-hand-thumbs-up"></i>
                    </span>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="staking_title launcpad d-block reffer-card bg_card_super">
                    <h2>Your Reward Rate</h2>
                    <p>0.5%</p>
                    <span>
                      <i class="bi bi-bar-chart"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="container d-flex justify-content-center padin_zero d-block ">
              <div className="col-lg-6">
                <div className="staking_title launcpad d-block register_login referal_link_card bg_card_super">
                  <p>Share your link & earn more</p>
                  <form>
                    <div class="input-group earv_refer row">
                      <div className="col-sm-9">
                        {" "}
                        <input
                          type="text"
                          class="form-control"
                          value={refferance}
                        />
                      </div>
                      <div className="col-sm-3 mx-auto">
                        <Button
                          onClick={() => copy(refferance)}
                          class="input-group-addon"
                        >
                          Copy
                        </Button>
                      </div>
                    </div>
                  </form>
                  <div className="social_share">
                    <ul>
                      <div>
                        <li>
                          <FacebookShareButton url={refferance} title={title}>
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>
                        </li>
                      </div>
                      <div>
                        <li>
                          <TwitterShareButton url={refferance} title={title}>
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                        </li>
                      </div>
                      <div>
                        <li>
                          <InstapaperShareButton url={refferance} title={title}>
                            <InstapaperIcon size={32} round />
                          </InstapaperShareButton>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>

          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div className="container d-flex justify-content-center flexnew_center mt-4">
              <div className="col-lg-12 padin_zero">
                <div className="staking_title launcpad d-block fonrm_card_s need_meanissss bg_card_super">
                  <div class="fixTableHead table_section_color">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Refferal Email</th>
                          <th scope="col">Deposit Amount</th>
                          <th scope="col">Reward Amount</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {refferaldata.length > 0 ? (
                          refferaldata.map((data, i) => {
                            return (
                              <tr>
                                <td scope="col">{i + 1}</td>
                                <td scope="col">{data.Email}</td>
                                <td scope="col">{data.Amount}</td>
                                <td scope="col">{data.Rewardamount}</td>
                                <td scope="col">
                                  {moment(data.createdDate).format("lll")}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="5">No data's founds</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
