import React, { useEffect } from "react";
import useState from "react-usestateref";
import "react-dropdown/style.css";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { CSVLink, CSVDownload } from "react-csv";

import Moment from "moment";

function Home() {
  Moment.locale("en");
  const profileForm = {
    email: "",
    username: "",
    mobileNumber: "",
  };
  const [profileData, setprofileData] = useState("");
  const [sessionHistory, setsessionHistory] = useState([]);
  const [twofaHistory, set2faHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const [tfatotalPage, settfaTotalpages] = useState(0);
  const [tfacurrentPage, settfaCurrentPage] = useState(1);

  const navigate = useNavigate();
  const [profileformValue, setprofileFormValue] = useState(profileForm);
  const [tfacsvfile, Settfacsvfile] = useState([]);
  const [testtfacsvfile, Settesttfacsvfile] = useState([]);
  const [testcsvfile, Settestcsvfile] = useState([]);
  const [sideLoader,setSiteLoader]=useState(true);

  const tfarecordPerPage = 5;
  const tfapageRange = 5;

  const getProfile = async (page) => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setprofileData(resp.data);
        console.log(resp.data, "===-=-=-=resp.data-=-");
        profileformValue.username = resp.data.username;
        profileformValue.email = resp.data.email;
        profileformValue.mobileNumber = resp.data.mobileNumber;
        localStorage.setItem("eligibleforEarn", resp.data.kycstatus);
      }
      var payload = {
        perpage: 5,
        page: page,
        tfaperpage: 5,
        tfapage: page,
      };
      setSiteLoader(true)
      var data = {
        apiUrl: apiService.getSessionHisotry,
        payload: payload,
      };
      var resp = await postMethod(data);
      setSiteLoader(false)

      if (resp.status) {
        setsessionHistory(resp.logindata.data);
        set2faHistory(resp.data.data);
        Settestcsvfile(resp.csvData);
        Settesttfacsvfile(resp.tfadata);
        setTotalpages(resp.logindata.total);
        settfaTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const twoFAcsv = async () => {
    Settfacsvfile(testtfacsvfile);
  };

  const tfahandlePageChange = (pageNumber) => {
    getProfile(pageNumber);
    settfaCurrentPage(pageNumber);
  };

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getProfile();
  }, [0]);
  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5 padinf_ mb-5">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padinf_">
            <div className="container d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="bg_ash bg_card_section bg_card_super">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="title_section_funds">
                        <h2>2FA History</h2>
                      </div>
                    </div>
                    <div className="col-lg-8"></div>
                  </div>
                </div>
                <div className="staking_optoikn">
                  {/* //======================Staking Details==============// */}

                  <div class="">
                    <div id="LockedStaking" class="">
                      <div className="staking_title bg_card_super">
                        <div class="fixTableHead">
                          {twofaHistory && twofaHistory.length > 0 ? (
                            <CSVLink
                              className="btn btn-primary float-right mr-2 mt-2 mb-3"
                              filename="2FA History"
                              onClick={twoFAcsv}
                              data={tfacsvfile}
                            >
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </CSVLink>
                          ) : (
                            <div className="btn yellow float-right mr-2 mt-2 mb-3">
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </div>
                          )}

                          <div className="table-responsive transaction table_section_color">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">S.No</th>
                                  <th scope="col"> Date / time</th>
                                  <th scope="col">IP address</th>
                                  <th scope="col">Browser</th>
                                  <th scope="col">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {twofaHistory && twofaHistory.length > 0 ? (
                                  twofaHistory.map((item, i) => {
                                    return (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                          {Moment(item.createdDate).format(
                                            "lll"
                                          )}
                                        </td>
                                        <td>{item.ipAddress}</td>
                                        <td> {item.browser}</td>
                                        <td>{item.Status}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <td colSpan="5">
                                    <span className="w-100 text-center d-block">
                                      No Withdraw History Found!
                                    </span>
                                  </td>
                                )}
                              </tbody>
                            </table>
                          </div>

                          <div className="mt-5">
                            <Pagination
                              itemClass="page-item" // a
                              dd
                              it
                              for
                              bootstrap
                              linkClass="page-link" // add it for bootstrap 4
                              activePage={tfacurrentPage}
                              itemsCountPerPage={tfarecordPerPage}
                              totalItemsCount={tfatotalPage}
                              pageRangeDisplayed={tfapageRange}
                              onChange={tfahandlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
