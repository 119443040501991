import React, {useEffect, useState} from "react";
import {Link, useNavigate, useLocation} from "react-router-dom";
import Header from "./Header";
import {socket} from "./context/socket";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";

function Footer() {
  const [siteData, setSiteData] = useState({});

  useEffect(() => {
    fetchTfaData();
  }, [0]);

  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      // console.log(resp.data, "=--=-=-resp-=-=-=-=-=resp=-=-");
      setSiteData(resp.data);
    } catch (error) {}
  };

  // /get_sitedata
  return (
    <div>
      <footer className="rn-footer-one bg-color--1 rn-section-gap mt--100 mt_md--80 mt_sm--80">
        <div className="container">
          <div className="row justify-center">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 ">
                  <div className="widget-content-wrapper">
                    <div className="footer-left">
                      <div className="logo-thumbnail logo-custom-css">
                        <Link to="" className="logo-light">
                          <img
                            src={require("../img/keedx-logo.png")}
                            className="logo darktheme"
                          />
                          <img
                            src={require("../img/keedx-logo-light.png")}
                            className="logo lighttheme"
                          />
                        </Link>
                      </div>
                      <p className="rn-footer-describe">
                        Building the future of <br />
                        crypto analysis and tracking
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_mobile--40">
                  <div className="footer-widget widget-quicklink">
                    <h6 className="widget-title">About</h6>
                    <ul className="footer-list-one">
                      <li className="single-list">
                        <Link to="/privacy" target="_plank">
                          Privacy Policy
                        </Link>
                      </li>
                      <li className="single-list">
                        <Link to="/terms" target="_plank">
                          Terms Of Use
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_mobile--40">
                  <div className="footer-widget widget-quicklink">
                    <h6 className="widget-title">services</h6>
                    <ul className="footer-list-one">
                      <li className="single-list">
                        <Link to="/trade/BTC_USDT">Spot</Link>
                      </li>
                      <li className="single-list">
                        <Link to="/p2p">P2p</Link>
                      </li>
                      <li className="single-list">
                        <Link to="/swap">Swap</Link>
                      </li>
                      <li className="single-list">
                        <Link to="/internaltransfer">Internal Transfer</Link>
                      </li>
                      <li className="single-list">
                        <Link to="/">Margin</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_mobile--40">
                  <div className="footer-widget widget-quicklink">
                    <ul className="footer-list-one">
                      <li className="single-list">
                        <Link to="/">Optional</Link>
                      </li>
                      <li className="single-list">
                        <Link to="/staking">Staking</Link>
                      </li>
                      <li className="single-list">
                        <Link to="/launchpad">Lanchpad</Link>
                      </li>
                      <li className="single-list">
                        <Link to="/refferal">Invite and Earn</Link>
                      </li>
                      <li className="single-list">
                        <Link to="/giftCard">Gift</Link>
                      </li>
                      <li className="single-list">
                        <Link to="p2p/post_ad">Post add</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_mobile--40">
                  <div className="footer-widget widget-quicklink">
                    <h6 className="widget-title mb-2">Social media</h6>
                    <ul className="footer-list-one">
                      <li className="single-list first_content p-0">
                        <div className="social">
                          <Link
                            to={siteData && siteData.insta_url}
                            target="_blank"
                          >
                            <img
                              src={require("../img/insta.png")}
                              className="logo"
                            />
                          </Link>
                          <Link
                            to={siteData && siteData.youtube_url}
                            target="_blank"
                          >
                            <img
                              src={require("../img/medium.png")}
                              className="logo"
                            />
                          </Link>

                          <Link
                            to={siteData && siteData.twitter_url}
                            target="_blank"
                          >
                            <img
                              src={require("../img/twiter.png")}
                              className="logo"
                            />
                          </Link>

                          <Link
                            to={siteData && siteData.telegram_url}
                            target="_blank"
                          >
                            <img
                              src={require("../img/email.png")}
                              className="logo"
                            />
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
