import React, {useState, useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Arrow from "../img/ArrowRight.svg";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {getMethod, postMethod} from "../core/service/common.api";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import {useParams} from "react-router-dom";
import {setAuthorization} from "../core/service/axios";
import {Button} from "@material-ui/core";

import "rc-slider/assets/index.css";
import Pagination from "react-js-pagination";

function Home() {
  const options = ["one", "two", "three"];
  const initialvalues = {
    redeem: "",
  };
  const [formvalue, Setformvalue] = useState(initialvalues);
  const {redeem} = formvalue;

  const [redeemErr, SetredeemErr] = useState("");
  const [vallidationErr, setvalidationnErr] = useState("");

  const [perpage, setperpage] = useState(5);
  const [page, setpage] = useState(1);
  const [search, setsearch] = useState(1);
  const [balanceDetails, setbalanceDetails] = useState([]);
  const [balance_overallusdt, setbalance_overallusde] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [totalINRPrice, setToatalINRPrice] = useState(0);
  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [inorderPrice, setinorderPrice] = useState(0);
  const [sideLoader,setSiteLoader]=useState(true);

  const recordPerPage = 5;

  // total number of the records
  const totalRecords = 7;

  // range of pages in paginator
  const pageRange = 5;
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getUserbalance(pageNumber);
  };

  const getUserbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
      search: search,
    };

    setSiteLoader(true);
    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };
    var resp = await postMethod(data);
    setSiteLoader(false);
    // console.log(resp, "-------resp----");
    if (resp.status) {
      // console.log(resp.Message, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.Message;
      // var totalAmount = 0;
      // var availableAmount = 0;
      // var holdAMount = 0;
      // for(var i=0; i< balanceData.length; i++ ){
      //   totalAmount += balanceData[i].estimatedUSDTtotal;
      //   availableAmount += balanceData[i].estimatedUSDTbalance;
      //   holdAMount += balanceData[i].estimatedUSDThold;
      // }
      // console.log(totalAmount,'=-=-=-=-=totalAmount-=-=-=-totalAmount-=-=');
      // setToatalINRPrice(totalAmount);
      setbalanceDetails(resp.Message);
      setbalance_overallusde(resp.balance);

      settotal(resp.total);
      // setAvailablePrice(availableAmount);
      // setinorderPrice(holdAMount);
    } else {
    }
  };
  const searchWalletList = async () => [getUserbalance(1)];
  const getPortfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    var resp = await getMethod(data);
    if (resp.status) {
      // console.log(resp.balance, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    } else {
    }
  };

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getPortfolio();
    getUserbalance(currentPage);
  }, []);

  const handlechange = (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    // console.log(value);
    let formData = {...formvalue, ...{[name]: value}};
    Setformvalue(formData);
  };

  const vallidation = (values) => {
    const error = {};
    if (!values.redeem) {
      error.redeem = "Enter Your Gift Coupen Code";
      SetredeemErr(true);
    } else {
      SetredeemErr(false);
    }
    setvalidationnErr(error);
  };

  const redeemclick = async () => {
    // console.log("--");
    vallidation(formvalue);
    var obj = {
      code: formvalue.redeem,
    };
    var data = {
      apiUrl: apiService.redeem,
      payload: obj,
    };
    var resp = await postMethod(data);
    // console.log(resp);
    // console.log(obj);
    if (resp.status == true) {
      toast.success(resp.Message);
      navigate("/Dashboard");
    } else {
      toast.error(resp.Message);
    }
    // console.log(resp.Message, "pppppp");
  };
  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div class="container">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div class="my_fund_content">
              <div class="container">
                <div class="">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="gift-content">
                        <h2>
                          Send Crypto
                          <br />
                          with Bitbuyrex Gift Card
                        </h2>
                        <p>
                          Gift crypto with your referral code and choose from
                          30+ thematic templates
                        </p>
                        <Button className="btn btn-primary-alta connectBtn">
                          <Link to="/giftCardbuy">
                            Send a Crypto Gift Card
                            <i class="bi bi-arrow-right"></i>
                          </Link>
                        </Button>
                      </div>
                      <div className="my_fund_sectin_ bg_card_section gift-card">
                        <h3 className="title_new">Redeem</h3>
                        <div className="form register_login">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputPassword1"
                              placeholder="Enter Copen Code"
                              name="redeem"
                              value={redeem}
                              onChange={handlechange}
                            />
                          </div>
                          <Button
                            className="btn btn-primary-alta new_butin_redene"
                            onClick={redeemclick}
                          >
                            Redeem
                          </Button>
                        </div>
                        {redeemErr == true ? (
                          <p className="text-danger">
                            {" "}
                            {vallidationErr.redeem}{" "}
                          </p>
                        ) : (
                          ""
                        )}

                        <div className="note_sedrir">
                          <p>
                            <strong>Note:</strong>Bitbuyrex is not responsible
                            for, and assumes no liability to you for, any
                            unlawful conduct or fraud by any third party
                            associated with any Gift Card.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="img_clas_sss">
                        <img
                          src={require("../img/4320866-ai.png")}
                          className="logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
