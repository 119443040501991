import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";

import "rc-slider/assets/index.css";
import Header from "./Header";

import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import OTPInput, {ResendOTP} from "otp-input-react";

function Home() {
  const options = ["one", "two", "three"];

  const initialFormValue = {
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  };

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [buttonLoader1, setbuttonLoader1] = useState(false);
  const [OTP, setOTP] = useState("");

  const navigate = useNavigate();

  const {otp1, otp2, otp3, otp4} = formValue;

  const formSubmit = async (payload) => {
    // return
    if (OTP != "") {
      // return false

      var obj = {
        emailOtp: OTP,
        email: localStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.emailotpverify,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/login");
      } else {
        toast.error(resp.Message);
      }
    } else {
      toast.error("Enter OTP");
    }
  };

  const resendOTP = async (payload) => {
    var obj = {
      email: localStorage.getItem("useremail"),
    };

    var data = {
      apiUrl: apiService.resendCode,
      payload: obj,
    };
    setbuttonLoader1(true);
    var resp = await postMethod(data);
    setbuttonLoader1(false);
    if (resp.status == true) {
      toast.success(resp.Message);
    } else {
      toast.error(resp.Message);
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    setFormValue(formData);
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover bg_white">
        <Header />
        <div className="container">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div className="container d-flex justify-content-center">
              <div className="col-lg-10">
                <div className="register_login padin_otp">
                  <h1>Enter Code To Create Account</h1>
                  <h6 className="text-center my-2">We send you on mail.</h6>
                  {/* <span>We’ve send you code on arun.****@gmail.com</span> */}
                  <div>
                    <div className="otp_content_e">
                      <div className="form-group">
                        <OTPInput
                          value={OTP}
                          onChange={setOTP}
                          autoFocus
                          OTPLength={4}
                          otpType="number"
                          disabled={false}
                          secure
                        />
                      </div>
                      {/* <div className="form-group">
                        <input type="number" className="form-control"
                             name = "otp1"  
                             value={otp1}
                             onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <input type="number" className="form-control" 
                             name = "otp2"  
                             value={otp2}
                             onChange={handleChange}/>
                      </div>
                      <div className="form-group">
                        <input type="number" className="form-control"
                             name = "otp3"  
                             value={otp3}
                             onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <input type="number" className="form-control"
                             name = "otp4"  
                             value={otp4}
                             onChange={handleChange} />
                      </div> */}
                    </div>
                    <div className="col-lg-6 d-block mx-auto">
                      {buttonLoader == false ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={formSubmit}
                        >
                          Continue
                        </button>
                      ) : (
                        <button type="button" className="btn btn-primary">
                          loading...
                        </button>
                      )}
                    </div>

                    <div className="col-lg-6 d-block mx-auto">
                      <p className="bottom_linnk">
                        Did not receive the email? Check your spam filter, or
                      </p>
                      <button
                        type="submit"
                        className="btn btn-primary class_buutn_resend"
                      >
                        <small>
                          {buttonLoader1 == false ? (
                            <small
                              onClick={resendOTP}
                              className="fonteee_small"
                            >
                              Resend Code
                            </small>
                          ) : (
                            <small
                              onClick={resendOTP}
                              className="fonteee_small"
                            >
                              loading...
                            </small>
                          )}
                        </small>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
