import React, { useEffect } from "react";
import useState from "react-usestateref";
import "react-dropdown/style.css";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";

import Moment from "moment";

function Home() {
  Moment.locale("en");

  useEffect(() => {
    Swaphistory();
  }, []);

  const [swapinghistory, Setswaphistory] = useState();
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sideLoader,setSiteLoader]=useState(true);

  const Swaphistory = async (page) => {
    var obj = {
      perpage: 5,
      page: page,
    };
    setSiteLoader(true)
    var data = {
      apiUrl: apiService.Swaphistory,
      payload: obj,
    };
    var resp = await postMethod(data);
    setSiteLoader(false)
    Setswaphistory(resp.Swapdata.data);
    setTotalpages(resp.Swapdata.total);
    // console.log(resp.Swapdata.total, "=-=-=-=-990909090==-=-=-");
  };

  const recordPerPage = 5;
  const pageRange = 5;

  const handlePageChange = async (PageNumber) => {
    Swaphistory(PageNumber);
    setCurrentPage(PageNumber);
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5 padinf_ mb-5">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padinf_">
            <div className="container d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="bg_ash bg_card_section bg_card_super">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="title_section_funds">
                        <h2>Swap History</h2>
                      </div>
                    </div>
                    <div className="col-lg-8"></div>
                  </div>
                </div>
                <div className="staking_optoikn">
                  {/* //======================Staking Details==============// */}

                  <div class="">
                    <div id="LockedStaking" class="">
                      <div className="staking_title bg_card_super">
                        <div class="fixTableHead table_section_color">
                          <div className="table-responsive transaction">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">S.No</th>
                                  <th scope="col" class="text_222">
                                    Sell
                                  </th>
                                  <th scope="col" class="text_222">
                                    Buy
                                  </th>
                                  <th scope="col" class="text_222">
                                    Buy Amount
                                  </th>
                                  <th scope="col" class="text_222">
                                    Sell Amount
                                  </th>
                                  <th scope="col" class="text_222">
                                    Fees
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {swapinghistory &&
                                  swapinghistory.map((item, i) => {
                                    return (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>{item.fromcurrency}</td>
                                        <td>{item.tocurrency}</td>
                                        <td>{item.totalamount}</td>
                                        <td>{item.amount}</td>
                                        <td>{item.Fees}</td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>

                          <div className="mt-5">
                            <Pagination
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={currentPage}
                              itemsCountPerPage={recordPerPage}
                              totalItemsCount={totalPage}
                              pageRangeDisplayed={pageRange}
                              onChange={handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
