import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import "rc-slider/assets/index.css";
import Header from "./Header";
import {Button} from "@material-ui/core";

import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import OTPInput, {ResendOTP} from "otp-input-react";
import useState from "react-usestateref";
import Pagination from "react-js-pagination";
import Moment from "moment";

const options = ["one", "two", "three"];
const defaultOption = options[0];

function Home() {
  const [perpage, setperpage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);

  const pageRange = 5;

  const [depositperpage, setdepositperpage] = useState(5);
  const [depositcurrentPage, setdepositCurrentPage] = useState(1);
  const [deposittotal, setdeposittotal] = useState(0);

  const [loginTrue, setloginTrue] = useState(true);
  const [activetab, setActive, activetabref] = useState("deposit");
  const [currentCurrency, setcurrentCurrency, currentCurrencyref] =
    useState("");
  const [WithdrawHistory, setWithdrawHistory] = useState([]);
  const [DepositHistory, setDeposithistory] = useState([]);
  const recordPerPage = 5;
  useEffect(() => {
    let user_token = getAuthToken();
    // console.log("user_token===", typeof user_token);
    // console.log("user_token.length===", user_token.length);
    if (user_token != "" && user_token != undefined && user_token != null) {
      setloginTrue(true);
    } else {
      setloginTrue(false);
    }

    getWithdrawHistoryfn(1);
    getDepositHistoryfn(1);
  }, []);
  const handlePageChange = (pageNumber) => {
    // setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getWithdrawHistoryfn(pageNumber);
  };
  const handlePageChangedeposit = (pageNumber) => {
    getDepositHistoryfn(pageNumber);
  };
  const getWithdrawHistoryfn = async (pages) => {
    var urls = window.location.href;
    var fetchcurrency = await urls.split("transaction-history/")[1];
    var obj = {
      FilPerpage: perpage,
      FilPage: pages,
      currency: fetchcurrency,
    };

    var data = {
      apiUrl: apiService.withdrawhistoryBycurrency,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status) {
      setWithdrawHistory(resp.result);
      settotal(resp.total);
      setCurrentPage(resp.current);
    }
  };
  const getDepositHistoryfn = async (pages) => {
    var urls = window.location.href;
    var fetchcurrency = await urls.split("transaction-history/")[1];
    var obj = {
      FilPerpage: perpage,
      FilPage: pages,
      currency: fetchcurrency,
    };

    var data = {
      apiUrl: apiService.deposithistoryBycurrency,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status) {
      setDeposithistory(resp.result);
      setdeposittotal(resp.total);
      setdepositCurrentPage(resp.current);
    }
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        <div className="container pt-5 padin_zero mt-3">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div className="container d-flex justify-content-center padin_zero">
              <div className="col-lg-10">
                <div className="p2ppost p2plist newdeposite-section">
                  <ul class="nav nav-tabs">
                    <li class="active">
                      <a data-toggle="tab" href="#deposit" class="active">
                        Deposit History
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#withdraw">
                        Withdraw History
                      </a>
                    </li>
                  </ul>
                  <div
                    id="deposit"
                    class={`bg_card_section tab-pane fade in ${
                      activetabref.current == "deposit" ? "active show" : ""
                    }`}
                  >
                    <div className="table-responsive transaction">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Date</th>
                            <th scope="col">Time</th>
                            <th scope="col">Currency</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Transaction Id</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {DepositHistory && DepositHistory.length > 0 ? (
                            DepositHistory.map((item, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>
                                    {Moment(item.date).format("YYYY-MM-DD")}
                                  </td>
                                  <td>
                                    {Moment(item.date).format("HH:mm:ss")}
                                  </td>
                                  <td>{item.currencySymbol}</td>
                                  <td>{parseFloat(item.amount).toFixed(8)}</td>
                                  <td>
                                    <span className="text_wrapaa">
                                      {item.txnid}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-green">
                                      Completed
                                    </span>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="7">
                              <span className="w-100 text-center d-block">
                                No Deposit History Found!
                              </span>
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={depositcurrentPage}
                      itemsCountPerPage={recordPerPage}
                      totalItemsCount={deposittotal}
                      pageRangeDisplayed={pageRange}
                      onChange={handlePageChangedeposit}
                    />
                  </div>
                  <div
                    id="withdraw"
                    class={`bg_card_section tab-pane fade in ${
                      activetabref.current == "withdraw" ? "active show" : ""
                    }`}
                  >
                    <div className="table-responsive transaction">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Date</th>
                            <th scope="col">Time</th>
                            <th scope="col">Currency</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Transaction Id</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {WithdrawHistory && WithdrawHistory.length > 0 ? (
                            WithdrawHistory.map((item, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>
                                    {Moment(item.created_at).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  <td>
                                    {Moment(item.created_at).format("HH:mm:ss")}
                                  </td>
                                  <td>{item.currency}</td>
                                  <td>{parseFloat(item.amount).toFixed(8)}</td>
                                  <td>
                                    <span className="text_wrapaa">
                                      {item.txn_id}
                                    </span>
                                  </td>
                                  <td>
                                    {item.status == "Completed" ? (
                                      <span className="text-green">
                                        {item.status}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {item.status !== "Completed" ? (
                                      <span className="text-red">
                                        {item.status}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="7">
                              <span className="w-100 text-center d-block">
                                No Withdraw History Found!
                              </span>
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={currentPage}
                      itemsCountPerPage={recordPerPage}
                      totalItemsCount={total}
                      pageRangeDisplayed={pageRange}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
