import React, { useEffect } from "react";
import useState from "react-usestateref";
import "react-dropdown/style.css";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { CSVLink, CSVDownload } from "react-csv";

import Moment from "moment";

function Home() {
    Moment.locale("en");

const [sideLoader,setSiteLoader]=useState(true);

    const profileForm = {
        email: "",
        username: "",
        mobileNumber: "",
    };
    const [profileData, setprofileData] = useState("");
    const [sessionHistory, setsessionHistory] = useState([]);
    const [twofaHistory, set2faHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalpages] = useState(0);
    const [tfatotalPage, settfaTotalpages] = useState(0);

    const navigate = useNavigate();
    const [profileformValue, setprofileFormValue] = useState(profileForm);

    const [csvfile, Setcsvfile] = useState([]);
    const [testtfacsvfile, Settesttfacsvfile] = useState([]);
    const [testcsvfile, Settestcsvfile] = useState([])

    const getProfile = async (page) => {
        try {
            var data = {
                apiUrl: apiService.getUserDetails,
            };
            var resp = await getMethod(data);
            if (resp.status) {
                setprofileData(resp.data);
                console.log(resp.data, "===-=-=-=resp.data-=-");
                profileformValue.username = resp.data.username;
                profileformValue.email = resp.data.email;
                profileformValue.mobileNumber = resp.data.mobileNumber;
                localStorage.setItem("eligibleforEarn", resp.data.kycstatus);
            }
            var payload = {
                perpage: 5,
                page: page,
                tfaperpage: 5,
                tfapage: page,
            };
            var data = {
                apiUrl: apiService.getSessionHisotry,
                payload: payload,
            };
            var resp = await postMethod(data);
            if (resp.status) {
                setsessionHistory(resp.logindata.data);
                set2faHistory(resp.data.data);
                Settestcsvfile(resp.csvData);
                Settesttfacsvfile(resp.tfadata);
                setTotalpages(resp.logindata.total);
                settfaTotalpages(resp.data.total);
            }
        } catch (error) { }
    };

    const csv = async () => {
        Setcsvfile(testcsvfile);
    };

    const recordPerPage = 5;
    const pageRange = 5;

    const handlePageChange = (pageNumber, page) => {
        getProfile(pageNumber);
        setCurrentPage(pageNumber);
    };


    const [notifications, setNotification] = React.useState("");

    useEffect(() => {
        let token_socket = localStorage.getItem("user_token");
        if (!token_socket) {
            navigate("/login");
        }
        getProfile();
      getNotifications();

    }, [0]);
    const getNotifications = async () => {
        try {
            setSiteLoader(true)
          var data = {
            apiUrl: apiService.notifications,
          };
          var resp = await getMethod(data);
          setSiteLoader(false)
            setNotification(resp.Message);
            console.log(notifications,"notifications=====")         
        } catch (error) {}
      };
      const link=(item)=>{
        console.log(item)
        navigate(item.link)
      }
    return (
        <div className="">
            <main className="main-content tradepage-bg login_body_ bg-cover ">
                <Header />
                {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
                <div className="container pt-5 padinf_ mb-5">

                    <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padinf_">
                        <div className="container d-flex justify-content-center">
                            <div className="col-lg-10">
                                <div className="staking_optoikn">

                                    <div className="sessions__title text-center">
                                        Notification
                                    </div>
                                    {/* //======================Staking Details==============// */}

                                    <div class="">
                                        <div id="LockedStaking" class="">
                                            <div className="staking_title">
                                                <div class="fixTableHead">
                                                    {notifications &&
                                                        notifications.length > 0 ? (
                                                        <CSVLink
                                                            className="btn btn-primary float-right ml-2 mt-2"
                                                            filename="Login History"
                                                            onClick={csv}
                                                            data={csvfile}
                                                        >
                                                            <i
                                                                class="fa fa-download"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </CSVLink>) :
                                                        (
                                                            <div className="btn yellow float-right mr-2 mt-2">
                                                                <i
                                                                    class="fa fa-download"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </div>
                                                        )
                                                    }

                                                    <div className="table-responsive transaction">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">S.No</th>
                                                                    <th scope="col"> Date / time</th>
                                                                    <th scope="col">From User</th>
                                                                    <th scope="col">To User</th>
                                                                    <th scope="col">Messsage</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {notifications &&
                                                                    notifications.length > 0 ? (
                                                                    notifications.map((item, i) => {
                                                                        return (
                                                                            <tr onClick={()=>link(item)}>
                                                                                <td>{i + 1}</td>
                                                                                <td>
                                                                                    {Moment(
                                                                                        item.createdAt
                                                                                    ).format("lll")}
                                                                                </td>
                                                                                <td>{item.from_user_name}</td>
                                                                                <td> {item.to_user_name}</td>
                                                                                <td>{item.message}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <td colSpan="5">
                                                                        <span className="w-100 text-center d-block">
                                                                            No  History Found!
                                                                        </span>
                                                                    </td>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="mt-5">
                                                        <Pagination
                                                            itemClass="page-item" // add it for bootstrap 4
                                                            linkClass="page-link" // add it for bootstrap 4
                                                            activePage={currentPage}
                                                            itemsCountPerPage={recordPerPage}
                                                            totalItemsCount={totalPage}
                                                            pageRangeDisplayed={pageRange}
                                                            onChange={handlePageChange}
                                                        /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
}
                <Footer />

            </main>
        </div>
    );
}

export default Home;
