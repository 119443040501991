import "./App.css";
import React, {useEffect, useState} from "react";

import {Routes, Route} from "react-router-dom";
import {BrowserRouter as Router} from "react-router-dom";
import Home from "./Component/Home";
import Uploadtype from "./Component/Uploadtype";
import Create from "./Component/Create";
import Trade from "./Component/Trade";
import Login from "./Component/Login";
import Register from "./Component/Register";
import Otp from "./Component/Otp";
import Myfund from "./Component/Myfund";
import Kyc from "./Component/Kyc";
import Transaction from "./Component/Transaction";
import Profile from "./Component/Profile";
import VerifyTfa from "./Component/verifyTfa";
import Activity from "./Component/Activity";
import Creators from "./Component/Creators";
import Staking from "./Component/Staking";
import Launchpad from "./Component/Launchpad";
import Launchpadnow from "./Component/Launchpadnow";
import Launchpadbuy from "./Component/Launchpadbuy";
import Launchpadapplay from "./Component/Launchpadapplay";
import Support from "./Component/Support";
import Bankdetails from "./Component/Bankdetails";
import P2Ppost from "./Component/P2Ppost";
import P2home from "./Component/P2home";
import P2Pchat from "./Component/P2Pchat";
import Dashboard from "./Component/Dashboard";
import Deposithistory from "./Component/deposithistory";
import Launchhistory from "./Component/lanchpadhistory";
import TWOFAhistory from "./Component/2fahistory";
import Loginhistory from "./Component/loginhistory";
import Inttransferhistory from "./Component/internaltransactionhistory";
import P2porderhistory from "./Component/p2pmyorderhistory";
import P2Phistory from "./Component/p2phistory";
import Gifthistory from "./Component/gifthistory";
import Withdrawhistory from "./Component/withdrawhistory";
import Notification from "./Component/Notification";
import Spothistory from "./Component/spothistory";
import Stakinghistory from "./Component/stakinghistory";
import Swaphistory from "./Component/swaphistory";
import P2pMywallet from "./Component/P2pmyfund";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "./Component/Chart";
import ForgotPassword from "./Component/ForgotPassword";
import P2Plist from "./Component/p2pList";
import P2Porder from "./Component/p2pOrder";
import Terms from "./Component/Terms";
import Privacy from "./Component/Privacy";
import About from "./Component/AboutUs";
import Transfer from "./Component/Transfer";
import IdleTimeOutHandler from "./Component/IdleTimeOutHandler";
import TransactionHistory from "./Component/TransactionHistory";
import P2pmyOrders from "./Component/p2pmyOrders";
import Swap from "./Component/Swap";
import Refferal from "./Component/Refferal";
import GiftCard from "./Component/GiftCard";
import GiftCardbuy from "./Component/GiftCardbuy";
import Internaltransfer from "./Component/Internaltransfer";
import Giftinvite from "./Component/Giftinvite";
import PaymentGetway from "./Component/PaymentGetway";
import P2pView from "./Component/p2pview";
import FutureTrade from "./Component/FutureTrade";
import Comingsoon from "./Component/comingsoon";
import StakingWallet from "./Component/stakingmyfund"

function App() {
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      <Router>
        <IdleTimeOutHandler
          onActive={() => {
            setIsActive(true);
          }}
          onIdle={() => {
            setIsActive(false);
          }}
        />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/trade" element={<Trade />} />
          <Route path="/verify-tfa" element={<VerifyTfa />} />
          <Route path="/trade/:pair" element={<Trade />} />
          <Route path="/futures/trade/:pair" element={<FutureTrade />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/myfund" element={<Myfund />} />
          <Route path="/kyc" element={<Kyc />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/forgot/:id" element={<ForgotPassword />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/tradeview/:pair" element={<Chart />} />
          trade
          <Route path="/staking" element={<Staking />} />
          <Route path="/launchpad" element={<Launchpad />} />
          <Route path="/launchnow" element={<Launchpadnow />} />
          <Route path="/launchbuy/:id" element={<Launchpadbuy />} />
          <Route path="/launchpadapplay" element={<Launchpadapplay />} />
          <Route path="/support" element={<Support />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Bankdetails" element={<Bankdetails />} />
          <Route path="/p2p/post_ad" element={<P2home />} />
          <Route path="/p2p" element={<P2Ppost />} />
          <Route path="/p2p/chat/:id" element={<P2Pchat />} />
          <Route path="/p2p/view/:id" element={<P2pView />} />
          <Route path="/p2plist" element={<P2Plist />} />
          <Route path="/p2p/postad" element={<P2Porder />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/transfer" element={<Transfer />} />
          <Route
            path="/transaction-history/:currency"
            element={<TransactionHistory />}
          />
          <Route path="/p2pmyOrders" element={<P2pmyOrders />} />
          <Route path="/deposithistory" element={<Deposithistory />} />
          <Route path="/launchpadhistory" element={<Launchhistory />} />
          <Route path="/2fahistory" element={<TWOFAhistory />} />
          <Route path="/loginhistory" element={<Loginhistory />} />
          <Route path="/internalhistory" element={<Inttransferhistory />} />
          <Route path="/p2porderhistory" element={<P2porderhistory />} />
          <Route path="/p2phistory" element={<P2Phistory />} />
          <Route path="/gifthistory" element={<Gifthistory />} />
          <Route path="/withdrawhistory" element={<Withdrawhistory />} />
          <Route path="/notify" element={<Notification />} />
          <Route path="/spothistory" element={<Spothistory />} />
          <Route path="/stakinghistory" element={<Stakinghistory />} />
          <Route path="/swaphistory" element={<Swaphistory />} />
          <Route path="/p2pmywallet" element={<P2pMywallet />} />
          <Route path="/stakingWallet" element={<StakingWallet />} />
          
          <Route path="/swap" element={<Swap />} />
          <Route path="/refferal" element={<Refferal />} />
          <Route path="/giftCard" element={<GiftCard />} />
          <Route path="/giftCard/:email" element={<GiftCard />} />
          <Route path="/giftCardbuy" element={<GiftCardbuy />} />
          <Route path="/internaltransfer" element={<Internaltransfer />} />
          <Route path="/buycurrency" element={<PaymentGetway />} />
          <Route path="/soon" element={<Comingsoon />} />
        </Routes>
      </Router>
    </>
  );
}
export default App;
