import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";

import "rc-slider/assets/index.css";
import Header from "./Header";
import {Button} from "@material-ui/core";
import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import Moment from "moment";
import {toast} from "react-toastify";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import OTPInput, {ResendOTP} from "otp-input-react";
import {getMethod, postMethod} from "../core/service/common.api";
var moment = require("moment");


function Home() {
    const [p2pOrders, setp2pOrders] = useState([]);
    const[p2pcurrentpage,setp2pcurrentpage]=useState(1)
    const [p2ptotalpage, setp2pTotalpages] = useState(0);
    const navigate = useNavigate();

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getp2pOrders(1)
  }, [0]);

  const getp2pOrders = async (page) => {
    var data = {
      apiUrl: apiService.p2pOrders,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var p2p_orders_list = await postMethod(data);
    // console.log("p2p_orders_list===", p2p_orders_list.returnObj.Message);
    if (p2p_orders_list.status) {
      setp2pOrders(p2p_orders_list.returnObj.Message);
      setp2pTotalpages(p2p_orders_list.returnObj.total);
    }
  }

  const p2precordpage=5;
  const p2ppagerange=5;
   const handlepagep2p=(p2ppage)=>{
    //  console.log(p2ppage,"==-=-=-p2ppage==-==-=")
     getp2pOrders(p2ppage);
     setp2pcurrentpage(p2ppage)
   }
  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        <div className="container pt-5">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div className="container ">
              <div className="logo_launchpad">
                <img
                  src={require("../img/keedx-logo.png")}
                  className="logo darktheme"
                />
                <img
                  src={require("../img/keedx-logo-light.png")}
                  className="logo lighttheme"
                />
                <h1>P2P My Orders</h1>
              </div>
            </div>
          </div>

          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div className="row justify-center">
              <div className="col-lg-10">
                <div className="d-flex justify-start">
                  <Button className="trade-btn btton_back ">
                    {" "}
                    <Link to="/profile">
                      <i class="bi bi-arrow-left-short"></i>
                    </Link>
                  </Button>
                </div>
                <div className="staking_title">
                  <div class="fixTableHead">
                    <table>
                      <thead>
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Price</th>
                        <th scope="col">Order Type</th>
                        <th scope="col">Status</th>
                        </tr>
                      </thead>

                      <tbody>
                      {p2pOrders && p2pOrders.length > 0 ? (
                        p2pOrders.map((item, i) => {
                            return (
                            <tr>
                                <td>{i + 1}</td>
                                <td>
                                {Moment(item.createdAt).format(
                                    "YYYY-MM-DD"
                                )}
                                </td>
                                <td>
                                {Moment(item.createdAt).format(
                                    "hh:mm a"
                                )}
                                </td>
                                <td>{item.firstCurrency}</td>
                                <td>
                                {parseFloat(
                                    item.totalAmount
                                ).toFixed(8)}
                                </td>
                                <td>{item.price}</td>
                                <td>
                                {item.orderType == "buy" ? (
                                    <span className="text-green">
                                    Buy
                                    </span>
                                ) : (
                                    <span className="text-red">
                                    Sell
                                    </span>
                                )}
                                </td>
                                <td>
                                {item.status == "filled" ? (
                                    <span className="text-green">
                                    {item.status}
                                    </span>
                                ) : (
                                    ""
                                )}
                                {item.status !== "filled" ? (
                                    <span className="text-red">
                                    {item.status}
                                    </span>
                                ) : (
                                    ""
                                )}
                                </td>
                            </tr>
                            );
                        })
                        ) : (
                        <td colSpan="8">
                            <span className="w-100 text-center d-block">
                            No Orders Found!
                            </span>
                        </td>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <Pagination
                    itemClass="page-item" // add it for bootstrap 4
                    linkClass="page-link" // add it for bootstrap 4
                    activePage={p2pcurrentpage}
                    itemsCountPerPage={p2precordpage}
                    totalItemsCount={p2ptotalpage}
                    pageRangeDisplayed={p2ppagerange}
                    onChange={handlepagep2p}
                />

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
