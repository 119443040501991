import React, {useEffect} from "react";
import useState from "react-usestateref";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Pagination from "react-js-pagination";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {CSVLink, CSVDownload} from "react-csv";
import Moment from "moment";

function Home() {
  Moment.locale("en");

  const [transferhistorylist, setTransferhistorylist] = useState([]);
  const [transfercurrentPage, settransferCurrentPage] = useState(1);
  const [transfertotalpage, settransfertotalpage] = useState(0);
  const [transfercsvfile, Settransfercsvfile, transfercsvfileref] = useState(
    []
  );
const [sideLoader,setSiteLoader]=useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    transferhistory(1);
  }, [0]);
  const transferrecordpage = 5;
  const transferpagerange = 5;
  const transferhandlepage = (page) => {
    transferhistory(page);
    settransferCurrentPage(page);
  };
  const transferhistory = async (page) => {
    var payload = {
      perpage: 5,
      page: page,
    };
    var data = {
      apiUrl: apiService.transferhistory,
      payload: payload,
    };
    setSiteLoader(true)
    var responce = await postMethod(data);
    setSiteLoader(false)
    setTransferhistorylist(responce.data.data);
    Settransfercsvfile(responce.data.CSV);
    console.log(transfercsvfileref.current, "transfercsvfile");
    settransfertotalpage(responce.data.total);
    console.log(responce, "responcereaspoijknkj");
  };
  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover ">
        <Header />
        {sideLoader==true?
                            <i class="fa-solid fa-spinner fa-spin-pulse loader"></i>
                              :
        <div className="container pt-5 padinf_">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padinf_">
            <div className="container d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="bg_ash bg_card_section bg_card_super">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="title_section_funds">
                        <h2> Internal Transfer History</h2>
                      </div>
                    </div>
                    <div className="col-lg-8"></div>
                  </div>
                </div>
                <div className="staking_optoikn">
                  {/* //======================Staking Details==============// */}

                  <div class="">
                    <div id="LockedStaking" class="">
                      <div className="staking_title bg_card_super">
                        <div class="fixTableHead table_section_color">
                          {transferhistorylist &&
                          transferhistorylist.length > 0 ? (
                            <CSVLink
                              className="btn btn-primary float-right ml-2 mt-2 mb-3"
                              filename="Transfer History"
                              // onClick={csv}
                              data={transfercsvfileref.current}
                            >
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </CSVLink>
                          ) : (
                            <div className="btn yellow float-right mr-2 mt-2 mb-3">
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </div>
                          )}
                          <div className="sessions__table">
                            <div className="sessions__row">
                              <div className="sessions__col">S.No</div>
                              <div className="sessions__col">Date / time</div>
                              <div className="sessions__col">Currency</div>
                              <div className="sessions__col">From Wallet</div>
                              <div className="sessions__col">To Wallet</div>
                              <div className="sessions__col">Amount</div>
                              <div className="sessions__col">Fees</div>
                            </div>

                            {transferhistorylist &&
                            transferhistorylist.length > 0 ? (
                              transferhistorylist.map((item, i) => {
                                const fee = parseFloat(item.fees);
                                const fees = fee.toFixed(9);
                                console.log(fees, "======fees", typeof fees);
                                return (
                                  <div className="sessions__row ">
                                    <div className="sessions__col">
                                      <div className="sessions__content">
                                        {i + 1}
                                      </div>
                                    </div>
                                    <div className="sessions__col">
                                      <div className="sessions__content">
                                        {Moment(item.createdDate).format("lll")}
                                      </div>
                                    </div>
                                    <div className="sessions__col">
                                      <div className="sessions__content">
                                        {item.currency}
                                      </div>
                                    </div>
                                    <div className="sessions__col">
                                      <div className="sessions__content">
                                        {item.fromWallet}
                                      </div>
                                    </div>
                                    <div className="sessions__col">
                                      <div className="sessions__content">
                                        {item.toWallet}
                                      </div>
                                    </div>
                                    <div className="sessions__col">
                                      <div className="sessions__content">
                                        {item.amount}
                                      </div>
                                    </div>
                                    <div className="sessions__col">
                                      <div className="sessions__content">
                                        {fees == "NaN" ? 0.0 : fees}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <td colSpan="7">
                                <span className="w-100 text-center d-block">
                                  No History Found!
                                </span>
                              </td>
                            )}
                          </div>
                          <div className="mt-5">
                            <Pagination
                              itemClass="page-item" // add it for bootstrap 4
                              linkClass="page-link" // add it for bootstrap 4
                              activePage={transfercurrentPage}
                              itemsCountPerPage={transferrecordpage}
                              totalItemsCount={transfertotalpage}
                              pageRangeDisplayed={transferpagerange}
                              onChange={transferhandlepage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
        <Footer />
      </main>
    </div>
  );
}

export default Home;
