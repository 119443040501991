import React, {useEffect} from "react";
import useState from "react-usestateref";
import {Button} from "@material-ui/core";
import {Link, useNavigate} from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Arrow from "../img/ArrowRight.svg";
import glogo from "../img/google_logo.svg";
import {v4 as uuid} from "uuid";
import {GOOGLE} from "../core/config/";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {Formik, Form, Field, ErrorMessage} from "formik";
import {toast} from "react-toastify";
import * as Yup from "yup";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import ReCAPTCHA from "react-google-recaptcha";
import {PhoneInput} from "react-contact-number-input";

function Home() {
  useEffect(() => {
    var token = localStorage.getItem("user_token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const handleOnChange = (type) => {
    console.log(type, "=-=-=-=-=");
    var number = type.countryCode + " " + type.phoneNumber;
    setmobileInput(number);
    console.log(number, "=-=-=-=-=");
    if(number==""){
      setnumberErr("Phone Number Invalid");
    }
  };

  const initialFormValue = {
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    mobile: "",
    lastName: "",
    RefferedBy: "",
  };
  const [mobileInput, setmobileInput] = useState("");
  const [numberErr, setnumberErr] = useState("");
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] = useState(
    false
  );
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [passconfNotmatch, setpassconfNotmatch] = useState(false);
  const [RefferedBy, setRefferedBy] = useState("");
  const [Terms, setTerms] = useState(false);
  const [termsValidation, setTermsValidation] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [
    firstNamevalidation,
    setfirstNamevalidation,
    firstNamevalidationref,
  ] = useState(false);
  const [
    lasttNamevalidation,
    setlasttNamevalidation,
    lasttNamevalidationref,
  ] = useState(false);
  const [mobilevalidation, setmobilevalidation] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [recaptcha, setrecaptcha] = useState("");

  const navigate = useNavigate();

  const {
    email,
    password,
    confirmPassword,
    firstName,
    mobile,
    lastName,
  } = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    // console.log(e.target);
    let formData = {...formValue, ...{[name]: value}};
    setFormValue(formData);

    // validate(formData);
  };

  const onChange = (value) => {
    var captcha = value;
    if (captcha) {
      setrecaptcha(captcha);
    } else {
      toast.error("Captcha not verifiyed");
    }
  };
  useEffect(() => {
    var currURL = window.location.href;
    var refferalId = currURL.split("invite/")[1];
    // console.log(refferalId, "=-=-=-=-currURl");
    setRefferedBy(refferalId);
  }, []);

  const formSubmit = async (payload) => {
    // return

    console.log(formValue, "=-=-=-=-recaptcha", mobileInput);
    const unique_id = uuid();
    const small_id = unique_id.slice(0, 8);

    if (RefferedBy == "" || RefferedBy == undefined) {
    } else {
      formValue["ReferelBy"] = RefferedBy;
    }
    if (small_id == "") {
    } else {
      formValue["ReferelId"] = small_id;
    }

    if (mobileInput == "null null") {
      setnumberErr("Mobile number is required field");
    }
    console.log(numberErr, "numberErrnumberErrnumberErr");
    console.log(emailValidateref.current, " emailValidateref.current");
    console.log(
      firstNamevalidationref.current,
      " firstNamevalidationref.current.current"
    );
    console.log(
      passwordValidateref.current,
      "  passwordValidateref.current.current"
    );
    console.log(
      lasttNamevalidationref.current,
      " lasttNamevalidationref.current"
    );
    console.log(
      confirmPasswordValidateref.current,
      " confirmPasswordValidateref.current"
    );
    validate(formValue);
    if (
      emailValidateref.current === false &&
      firstNamevalidationref.current === false &&
      passwordValidateref.current === false &&
      lasttNamevalidationref.current === false &&
      confirmPasswordValidateref.current === false &&
      numberErr == ""
    ) {
      formValue["mobile"] = mobileInput;

      if (recaptcha) {
        console.log(recaptcha, "=-=-=-=-recaptcha");
        var data = {
          apiUrl: apiService.signup,
          payload: formValue,
        };
        setbuttonLoader(true);
        setnumberErr("");
        var resp = await postMethod(data);
        localStorage.setItem("useremail", resp.email);
        setbuttonLoader(false);
        if (resp.status == true) {
          navigate("/otp");
          toast.success(resp.Message);
          setFormValue(initialFormValue);
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("recaptcha not verified");
      }
    } else {
    }
  };

  useEffect(() => {}, []);

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is required field";
      setemailValidate(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      setemailValidate(true);
    } else {
      setemailValidate(false);
    }

    if (values.password == "") {
      setpasswordValidate(true);
      errors.password = "password is required field";
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password =
        "Password is required and shouldnot below 5 above 25 letters";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at One digit character";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character";
    } else {
      setpasswordValidate(false);
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is required field";
      setconfirmPasswordValidate(true);
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password and Confirm password does not match";
      setconfirmPasswordValidate(true);
    } else {
      setconfirmPasswordValidate(false);
    }

    if (!Terms) {
      errors.terms = "Terms is required field";
      setTermsValidation(true);
    }

    if (!values.firstName) {
      errors.firstName = "First Name is required field";
      setfirstNamevalidation(true);
    } else if (values.firstName.length < 3 || values.firstName.length > 25) {
      errors.firstName = "First Name must have an  3 to 25 characters";
      setfirstNamevalidation(true);
    } else {
      setfirstNamevalidation(false);
    }

    if (!values.lastName) {
      errors.lastName = "Last Name is required field";
      setlasttNamevalidation(true);
    } else if (values.lastName.length < 3 || values.lastName.length > 25) {
      errors.lastName = "LastName must have an  3 to 25 characters";
      setlasttNamevalidation(true);
    } else {
      setlasttNamevalidation(false);
    }

    if (!values.mobile) {
      errors.mobile = "Mobile Number is required field";
      setmobilevalidation(true);
    } else if (values.mobile.length < 7 || values.mobile.length > 15) {
      errors.mobile = "Mobile Number must have an  7 to 15 characters";
      setmobilevalidation(true);
    } else {
      setmobilevalidation(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  return (
    <div className="create">
      <main className="main-content tradepage-bg login_body_ bg-cover bg_white">
        <Header />
        <div class="container d-flex justify-content-center">
          <div class="col-lg-7">
            <div class="register_login">
              <h1 className="my-2">Create Account</h1>
              <h6 className="text-center my-3">
                Enter your credentials to continue
              </h6>
              <div>
                <form class="row">
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="First Name"
                        name="firstName"
                        maxLength={25}
                        minLength={3}
                        value={firstName}
                        onChange={handleChange}
                      />

                      <div>
                        {firstNamevalidation == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.firstName}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Last Name"
                        name="lastName"
                        value={lastName}
                        minLength={3}
                        maxLength={25}
                        onChange={handleChange}
                      />
                      <div>
                        {lasttNamevalidation == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.lastName}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label>Email ID</label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Email ID"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      />
                      <div>
                        {emailValidate == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.email}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label>Mobile Number</label>

                      <PhoneInput
                        class="mobileInput"
                        onChange={handleOnChange}
                      />
                      <div>
                        <p className="text-danger"> {numberErr} </p>
                      </div>

                      {/* <input
                        type="text"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Mobile Number"
                        name="mobile"
                        value={mobile}
                        minLength={3}
                        maxLength={15}
                        onChange={handleChange}
                      /> */}

                      {/* <div>
                        {mobilevalidation == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.mobile}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label>RefferedBy</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="RefferalID(Optional)"
                        name="RefferedBy"
                        value={RefferedBy}
                        maxLength={25}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label>Password</label>
                      <div className="postion_reletitt">
                        <input
                          type={inputType}
                          class="form-control"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          className="form-control"
                          name="password"
                          value={password}
                          minLength={3}
                          maxLength={25}
                          onChange={handleChange}
                        />

                        <div className="input-group-addon">
                          {passHide == false ? (
                            <i
                              className="bi bi-eye-slash-fill"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye-fill"
                              onClick={() => passwordHide("show")}
                            ></i>
                          )}
                        </div>
                      </div>

                      <div>
                        {passwordValidate == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.password}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label>Confirm Password</label>
                      <div className="postion_reletitt">
                        <input
                          type={inputTypeconf}
                          class="form-control"
                          id="exampleInputPassword1"
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          minLength={3}
                          maxLength={25}
                          value={confirmPassword}
                          onChange={handleChange}
                        />

                        <div className="input-group-addon">
                          {passHidconf == false ? (
                            <i
                              className="bi bi-eye-slash-fill"
                              onClick={() => passwordHideconf("hide")}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye-fill"
                              onClick={() => passwordHideconf("show")}
                            ></i>
                          )}
                        </div>
                      </div>

                      <div>
                        {confirmPasswordValidate == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.confirmPassword}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 px-5">
                    <ReCAPTCHA
                      sitekey="6Le2UHIiAAAAANZd3wVF9ypHuDYgGoGVXuwOLhsS"
                      onChange={onChange}
                      className="px-3"
                    />
                  </div>

                  <div class="col-lg-12">
                    {buttonLoader == false ? (
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={formSubmit}
                      >
                        Sign up
                      </button>
                    ) : (
                      <button type="button" class="btn btn-primary">
                        loading...
                      </button>
                    )}

                    <p class="bottom_linnk">
                      <Link to="/login">
                        <a>Already have an account?</a>{" "}
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
