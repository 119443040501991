import React, {useEffect, useContextt} from "react";
import {Button} from "@material-ui/core";
import {
  removeAuthToken,
  getAuthToken,
  getSocketToken,
} from "../core/lib/localStorage";
import {removeAuthorization} from "../core/service/axios";
import {Link, useNavigate} from "react-router-dom";
import {socket} from "./context/socket";
import apiService from "../core/service/detail";
import {getMethod} from "../core/service/common.api";
import Moment from "moment";
import useState from "react-usestateref";

function Header() {
  const [isOpen, setIsOpen, isOpenref] = useState(false);
  const [loginCheck, setloginCheck] = useState(false);
  const [notifications, setNotification] = React.useState("");
  // const socket = useContext(SocketContext);
  const navigate = useNavigate();

  useEffect(() => {
    //document.body.classList.toggle("light", isOpenref.current);
    //fetchTheme();
    let token_socket = localStorage.getItem("socket_token");
    if (token_socket) {
      socket.connect();
      let socketToken = token_socket.split("_")[0];
      socket.on("socketResponse" + socketToken, function (res) {
        // console.log("notify response====", res);
        if (res.Reason == "notify") {
          getNotifications();
        }
      });
    }

    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
      getNotifications();
    } else {
      setloginCheck(false);
    }
  }, [0]);
  const changeTheme = (value) => {
    //console.log("themevalue===",value);
    localStorage.setItem("theme", value);
    var settheme = value == "light" ? true : false;
    //console.log("settheme==",settheme);
    setIsOpen(settheme);
    document.body.classList.toggle("light", isOpenref.current);
  };

  const fetchTheme = () => {
    var theme = localStorage.getItem("theme");
    if (theme != undefined) {
      if (theme == "light") {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    } else {
      localStorage.setItem("theme", "dark");
      setIsOpen(false);
    }
  };
  const logout = async () => {
    await removeAuthorization();
    await removeAuthToken();
    localStorage.clear();
    navigate("/");
    window.location.reload(true);
  };

  const getNotifications = async () => {
    try {
      var data = {
        apiUrl: apiService.notifications,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setNotification(resp.Message);
      } else {
      }
    } catch (error) {}
  };
  return (
    <div>
      <header>
        <nav className="container navbar navbar-expand-lg ">
          <Link className="navbar-brand" to="/">
            <img
              src={require("../img/keedx-logo.png")}
              className="logo darktheme"
            />
            <img
              src={require("../img/keedx-logo-light.png")}
              className="logo lighttheme"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="bi bi-list navbar-toggler-icon"></i>
          </button>
          <div
            className="collapse navbar-collapse nava"
            id="navbarSupportedContent"
          >
            {loginCheck && loginCheck == true ? (
              <>
                <div class="dropdown">
                  <button
                    class="btn btn-default dropdown-toggle "
                    type="button"
                    data-toggle="dropdown"
                    data-hover="dropdown"
                  >
                    Trade <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <Link to="/trade/BTC_USDT">
                        <i class="fa-solid fa-circle-arrow-up pr-2"></i>Spot
                      </Link>
                    </li>
                    <li>
                      <Link to="/p2p">
                        <i class="fa-solid fa-people-carry-box pr-2"></i>P2P
                      </Link>
                    </li>
                    <li>
                      <Link to="/Swap">
                        <i class="fa-solid fa-rotate pr-2"></i>Convert
                      </Link>
                    </li>
                    <li>
                      <Link to="/internaltransfer">
                        <i class="fa-solid fa-arrow-right-arrow-left pr-2"></i>
                        Internal Transfer
                      </Link>
                    </li>
                  </ul>
                </div>

                <div class="dropdown">
                  <button
                    class="btn btn-default dropdown-toggle "
                    type="button"
                    data-toggle="dropdown"
                    data-hover="dropdown"
                  >
                    Future <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <Link to="/futures/trade/BTC_USDT" className="csoon d-flex">
                        Margin
                        {/* <small>Soon</small> */}
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="csoon d-flex">
                        Optional
                        <small className="soon1">Soon</small>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div class="dropdown">
                  <button
                    class="btn btn-default dropdown-toggle "
                    type="button"
                    data-toggle="dropdown"
                    data-hover="dropdown"
                  >
                    Earn <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <Link to="/staking">
                        <i class="fa-solid fa-sack-xmark pr-2"></i>Staking
                      </Link>
                    </li>
                    <li>
                      <Link to="/launchpad">
                        <i class="fa-solid fa-rocket pr-2"></i>Lanchpad
                      </Link>
                    </li>
                    <li>
                      <Link to="/refferal">
                        <i class="fa-solid fa-gifts pr-2"></i>Invite and Earn
                      </Link>
                    </li>
                  </ul>
                </div>

                <div class="dropdown">
                  <button
                    class="btn btn-default dropdown-toggle "
                    type="button"
                    data-toggle="dropdown"
                    data-hover="dropdown"
                  >
                    More <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <Link to="/giftCard">
                        <i class="fa-solid fa-gift pr-2"></i>Gift
                      </Link>
                    </li>
                    <li>
                      <Link to="/p2p/post_ad">
                        <i class="fa-solid fa-circle-arrow-up pr-2"></i>Post add
                      </Link>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="ml-auto padingrespos ">
              {loginCheck == false ? (
                <Link
                  to="/login"
                  className="btn btn-primary-alta connectBtn not "
                >
                  Login
                </Link>
              ) : (
                ""
              )}
              {loginCheck == false ? (
                <Link
                  to="/register"
                  className="btn btn-primary-alta connectBtn ml-3"
                >
                  Register
                </Link>
              ) : (
                ""
              )}
              {loginCheck && loginCheck == true ? (
                <>
                  <div className="d-flex res991">
                    <Link
                      to="/transaction"
                      className="btn btn-primary-alta connectBtn mr-3"
                    >
                      Deposit
                    </Link>
                    <div class="dropdown">
                      <button
                        class="btn btn-default dropdown-toggle "
                        type="button"
                        data-toggle="dropdown"
                        data-hover="dropdown"
                      >
                        Wallet <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <Link to="/Dashboard">
                            <i class="fa-solid fa-qrcode pr-2"></i>Overview
                          </Link>
                        </li>
                        <li>
                          <Link to="/myfund">
                            <i class="fa-solid fa-scale-unbalanced-flip pr-2"></i>
                            Portfolio
                          </Link>
                        </li>
                        <li>
                          <Link to="/p2pmywallet">
                            <i class="fa-solid fa-wallet pr-2"></i>Funding
                            Wallet
                          </Link>
                        </li>
                        <li>
                          <Link to="/stakingWallet">
                            <i class="fa-solid fa-wallet pr-2"></i>Staking
                            Wallet
                          </Link>
                        </li>
                        <li>
                          <Link to="/Bankdetails">
                            <i class="fa-solid fa-building-columns pr-2"></i>
                            Payment Methods
                          </Link>
                        </li>
                        <li>
                          <Link to="/buycurrency">
                            <i class="fa-brands fa-amazon-pay pr-2"></i>Payment
                            Gateway
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div class="dropdown">
                      <button
                        class="btn btn-default dropdown-toggle  "
                        type="button"
                        data-toggle="dropdown"
                        data-hover="dropdown"
                      >
                        Orders <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu ordermenu">
                        <li>
                          <Link to="/spothistory">
                            <i class="fa-solid fa-circle-arrow-up pr-2"></i>Spot
                            History
                          </Link>
                        </li>
                        <li>
                          <Link to="/p2porderhistory">
                            <i class="fa-solid fa-people-carry-box pr-2"></i>P2P
                            My Order
                          </Link>
                        </li>
                        <li>
                          <Link to="/stakinghistory">
                            <i class="fa-solid fa-sack-xmark pr-2"></i>Staking
                            History
                          </Link>
                        </li>
                        <li>
                          <Link to="/p2phistory">
                            <i class="fa-solid fa-arrow-right-arrow-left pr-2"></i>
                            P2P History
                          </Link>
                        </li>
                        <li>
                          <Link to="/launchpadhistory">
                            <i class="fa-solid fa-rocket pr-2"></i>Lanchpad
                            History
                          </Link>
                        </li>
                        <li>
                          <Link to="/swaphistory">
                            <i class="fa-solid fa-retweet pr-2"></i>Swap History
                          </Link>
                        </li>
                        <li>
                          <Link to="/internalhistory">
                            <i class="fa-solid fa-rotate pr-2"></i>Internal
                            Transfer
                          </Link>
                        </li>
                        <li>
                          <Link to="/deposithistory">
                            <i class="fa-solid fa-piggy-bank pr-2"></i>Deposit
                            History
                          </Link>
                        </li>
                        <li>
                          <Link to="/withdrawhistory">
                            <i class="fa-solid fa-building-columns pr-2"></i>
                            Withdraw History
                          </Link>
                        </li>
                        <li>
                          <Link to="/gifthistory">
                            <i class="fa-solid fa-gift pr-2"></i>Gift History
                          </Link>
                        </li>
                        <li>
                          <Link to="/2fahistory">
                            <i class="fa-solid fa-user-shield pr-2"></i>2FA
                            History
                          </Link>
                        </li>
                        <li>
                          <Link to="/loginhistory">
                            <i class="fa-solid fa-right-to-bracket pr-2"></i>
                            Session History{" "}
                          </Link>
                        </li>
                        {/* <li><Link to="#"><i class="fa-solid fa-money-bill pr-2"></i>Payment History</Link></li> */}
                      </ul>
                    </div>
                    <div class="dropdown">
                      <button
                        class="btn btn-default  fcircle"
                        type="button"
                        data-toggle="dropdown"
                        data-hover="dropdown"
                      >
                        <i class="fa-regular fa-circle-user"></i>{" "}
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu usericon">
                        <li>
                          <Link to="/profile">
                            <i class="fa-solid fa-user-large pr-2"></i>Profile
                          </Link>
                        </li>
                        <li>
                          <Link to="/profile">
                            <i class="fa-solid fa-shield-halved pr-2"></i>
                            Security
                          </Link>
                        </li>
                        <li>
                          <Link to="/kyc">
                            <i class="fa-solid fa-id-card-clip pr-2"></i>
                            Verification
                          </Link>
                        </li>
                        <li>
                          <Link to="/support">
                            <i class="fa-solid fa-circle-info pr-2"></i>Support
                          </Link>
                        </li>
                        <li>
                          <Link to="/notify">
                            <i class="fa-solid fa-bell pr-2"></i>Notification
                          </Link>
                        </li>{" "}
                        <hr />
                        <li>
                          <Link to="#" onClick={logout}>
                            <i class="fa-solid fa-right-from-bracket pr-2"></i>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            {/* {loginCheck && loginCheck == true ? (
              <div className="notification_section  padingrespos ">
                <button
                  className="btn btn-primary-alta notification" >
                  <Link to="/notify">
                    <i class="bi bi-bell-fill"></i>
                  </Link>
                </button>
              </div>
            ) : ("")} */}

            {loginCheck && loginCheck == true ? (
              <div className="notification_section  padingrespos ">
                <button
                  className="btn btn-primary-alta notification"
                  data-toggle="collapse"
                  data-target="#notification"
                >
                  {notifications && notifications.length > 0 ? (
                    <i class="fa-solid fa-bell fa-shake"></i>
                  ) : (
                    <i class="fa-solid fa-bell-slash"></i>
                  )}
                </button>
                <div id="notification" class="collapse">
                  <div className="content_notification">
                    <h3>Notification</h3>

                    <div className="overflow_seee">
                      {notifications && notifications.length > 0 ? (
                        notifications.map((item, i) => {
                          return (
                            <>
                              <a href={item.link} className="msg-noti">
                                <small>
                                  {Moment(item.createdAt).fromNow()}
                                </small>
                                <p>{item.message}</p>
                              </a>
                            </>
                          );
                        })
                      ) : (
                        <p className="text-center mt-5">No Message</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
